// Core
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'react-grid-system';
import { withUI, withRoot } from 'hocs';
import { compose } from 'recompose';

// Context
import { UIContext } from 'contexts/UIContext';
import { DEFAULT_LANG } from 'contexts/LangContext';

// Utils
import { fm } from 'utils';
import { languageName } from './utils/languageName';
import { statusLocalization } from './utils/statusLocalization';

// UI
import { Loading } from 'components';
import { Button } from 'components/Buttons';
import { Label } from 'components/Labels';
import Field from './Field/Field';
import VerificationSection from './VerificationSection/VerificationSection';

// Types
import modalTypes from 'constants/modalTypes';

// Styles
import styles from './SignupData.module.css';

function SignupData(props) {
	const {
		rootContext: {
			getSignupsUserData,
			signupEmailVerification,
			signupSmsVerification,
			deleteSignupsUserData,
		},
	} = props;

	const { showModal } = useContext(UIContext);

	const [signupData, setSignupData] = useState({});
	const [loading, setLoading] = useState(true);
	const [isVerified, setIsVerified] = useState(false);
	const [emailVerificationCode, setEmailVerificationCode] = useState('');
	const [smsVerificationData, setSmsVerificationData] = useState('');
	const [isVerificationError, setIsVerificationError] = useState(false);

	const history = useHistory();

	useEffect(() => {
		setLoading(true);

		getSignupsUserData(props.id).then((res) => {
			if (!res.lang) {
				res.lang = DEFAULT_LANG;
			}

			setSignupData(res);
			setLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const verifySubmit = (status) => {
		setIsVerificationError(false);

		if (status === 'confirm_email') {
			signupEmailVerification(props.id)
				.then((res) => {
					setEmailVerificationCode(res.code);
					setIsVerified(true);
				})
				.catch((err) => {
					if (err.toString().indexOf('422') > 0) {
						setIsVerificationError(true);
					}
				});
		} else {
			signupSmsVerification(props.id)
				.then((res) => {
					setSmsVerificationData(res);
					setIsVerified(true);
				})
				.catch((err) => {
					if (err.toString().indexOf('422') > 0) {
						setIsVerificationError(true);
					}
				});
		}
	};

	const handleDeleteRegistration = () => {
		deleteSignupsUserData(props.id).then((res) => {
			history.push('/admin/v2/accounts');
			console.info('signup', res);
		});
	};

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<Container fluid className={styles.grid}>
					<Row>
						<Col sm={10} offset={{ sm: 1 }}>
							<div className={styles.Page}>
								<section className={styles.Page__title}>
									<Label
										text={signupData?.name ? signupData?.name : ''}
										font="--gilroy-Medium-40"
										className={styles.Title}
									/>

									<Label
										text={
											<>
												{fm(`rod.admin.account.status_title`)}:{' '}
												{signupData?.completion_step &&
													statusLocalization(signupData?.completion_step)}
											</>
										}
										font="--gilroy-Bold-24"
										className={styles.Title}
									/>
								</section>
								<Button
									primary
									text="удалить регистрацию"
									className={styles.deleteButton}
									onClick={showModal(
										{
											title: (
												<FormattedMessage id={'rod.admin.delete_rega.title'} />
											),
											text: (
												<FormattedMessage id={'rod.admin.delete_rega.text'} />
											),
											onAction: () => {
												handleDeleteRegistration();
											},
											confirmBtnTxt: (
												<FormattedMessage id={'rod.modal.yes_delete'} />
											),
											declineBtnTxt: (
												<FormattedMessage id={'rod.modal.cancel'} />
											),
										},
										modalTypes.FULL_MODAL
									)}
								/>

								<section>
									<div className={styles.HeaderLine}>
										<Label
											text={fm('rod.admin.user.title')}
											font="--gilroy-Medium-32"
										/>
									</div>
									<Row>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.personal_data.first_name')}
												value={signupData?.name}
											/>
											<Field
												title={fm('rod.field.admin.language')}
												value={fm(languageName(signupData?.lang))}
											/>
										</Col>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.user_data.email')}
												value={signupData?.email}
											/>
										</Col>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.user_data.phone')}
												value={signupData?.phone}
											/>
										</Col>
									</Row>
								</section>
								<section>
									<div className={styles.HeaderLine}>
										<Label
											text={fm('rod.field.admin.actions')}
											font="--gilroy-Medium-32"
										/>
									</div>
									{['confirm_phone', 'confirm_email'].includes(
										signupData?.completion_step
									) && (
										<VerificationSection
											status={signupData?.completion_step}
											isVerified={isVerified}
											verifySubmit={verifySubmit}
											emailVerificationCode={emailVerificationCode}
											smsVerificationData={smsVerificationData}
											isVerificationError={isVerificationError}
										/>
									)}
								</section>
							</div>
						</Col>
					</Row>
				</Container>
			)}
		</>
	);
}

export default compose(withUI, withRoot)(SignupData);
