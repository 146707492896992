// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';
import StatisticDownloadBtn from 'components/StatisticDownloadBtn/StatisticDownloadBtn';

// Styles
import styles from '../StatisticByDatesSection.module.css';

const DatesBlock = ({
	accountId,
	accountOutlets,
	isAdmin,
	changeField,
	statisticData,
	errors,
	trackSearchValue,
	tracksIds,
	isAllTracks,
	releaseId,
	lastStatDate,
}) => {
	const MIN_DATE = '2020-01-01';
	return (
		<>
			<div className={styles.inputStartDate}>
				<FormInput
					className={styles.date}
					type={'muiInputDate_v3'}
					defaultValue={true}
					name={'start_date'}
					onChange={changeField}
					minDate={Date.parse(MIN_DATE)}
					maxDate={Date.parse(statisticData.end_date)}
					errors={errors}
					data={statisticData}
					label={<FormattedMessage id={'rod.statistic.start-date'} />}
					disabled={releaseId && !lastStatDate}
				/>
				{Object.keys(errors).includes('start_date') && (
					<div className={styles.error}>
						<span className={styles.errorText}>
							{errors['start_date'] === 'invalid date' ? (
								<FormattedMessage id={'rod.admin.invalid-date'} />
							) : (
								<FormattedMessage id={'rod.error.statistic.minDate'} />
							)}
							{errors['start_date'] === 'minDate' && MIN_DATE}
						</span>
					</div>
				)}
			</div>
			<div className={styles.inputDate}>
				<FormInput
					className={styles.date}
					type={'muiInputDate_v3'}
					defaultValue={true}
					name={'end_date'}
					onChange={changeField}
					minDate={Date.parse(statisticData.start_date)}
					maxDate={Date.now()}
					errors={errors}
					data={statisticData}
					label={<FormattedMessage id={'rod.statistic.end-date'} />}
					disabled={releaseId && !lastStatDate}
				/>
				{Object.keys(errors).includes('end_date') && (
					<div className={styles.error}>
						<span className={styles.errorText}>
							{errors['end_date'] === 'invalid date' ? (
								<FormattedMessage id={'rod.admin.invalid-date'} />
							) : (
								''
							)}
						</span>
					</div>
				)}
			</div>
			{releaseId && (
				<StatisticDownloadBtn
					accountId={accountId}
					statisticData={statisticData}
					accountOutlets={accountOutlets}
					isAdmin={isAdmin}
					tracksIds={
						trackSearchValue ? tracksIds : isAllTracks ? [] : tracksIds
					}
					releaseId={releaseId}
				/>
			)}
		</>
	);
};

export default DatesBlock;
