// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import DOMPurify from 'dompurify';
import { getHelpMessage } from 'messages/helper';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { ReportsCheckLayout } from '../../../ReportsCheckLayout';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { FormInput } from 'components/Form/FormInput';

// Style
import styles from './Individual.module.css';

const Individual = ({ data, changeField, errors, ...props }) => {
	const { lang } = useContext(LangContext);
	const message = DOMPurify.sanitize(...getHelpMessage(lang, 'personal_info'));

	const [personal, setPersonal] = useState({});
	const [patronymic, setPatronymic] = useState(true);
	const [isChangedSmth, setIsChangedSmth] = useState(false);

	useEffect(() => {
		setPersonal(data);
	}, [data, lang]);

	const patronymicCheckboxHandler = () => {
		if (!isChangedSmth) {
			setIsChangedSmth(true);
		}

		if (patronymic) {
			setPersonal((prev) => ({ ...prev, patronymic: '' }));
		}

		setPatronymic(!patronymic);
	};

	return (
		<>
			{personal && (
				<ReportsCheckLayout
					message={message}
					title="rod.account.personal.title"
				>
					<div>
						<FormattedMessage id={'rod.field.lastname'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'last_name'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.firstname'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'first_name'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.patronymic'}>
							{(placeholder) => (
								<>
									<FormInput
										placeholder={placeholder}
										name={'patronymic'}
										onChange={changeField}
										errors={errors}
										data={personal}
										disabled={!patronymic}
										style={{
											pointerEvents: patronymic ? 'all' : 'none',
											opacity: patronymic ? '1' : '0.6',
										}}
									/>
									<Checkbox
										text={<FormattedMessage id={'rod.field.no_patronymic'} />}
										className={
											errors.patronymic
												? styles.checkboxWithError
												: styles.checkbox
										}
										checked={!patronymic}
										onChange={patronymicCheckboxHandler}
									/>
								</>
							)}
						</FormattedMessage>
					</div>
					<div>
						<FormattedMessage id={'rod.field.registration_address'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'address'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.tax_number'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'itin'}
									onChange={changeField}
									errors={errors}
									data={personal}
									required={
										window.location.href.includes('.ru') ||
										window.location.href.includes('broma16ru-stage')
											? true
											: false
									}
								/>
							)}
						</FormattedMessage>
					</div>
				</ReportsCheckLayout>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(Individual);
