// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { getHelpMessage } from 'messages/helper';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { InfoButton } from 'components/Buttons';
import { Label } from 'components/Labels/Label';
import { FormInput } from 'components/Form/FormInput';

// Styles
import styles from '../general.module.css';

const SoleProprietor = ({ data, changeField, errors, ...props }) => {
	const { lang } = useContext(LangContext);
	const { showModal } = useContext(UIContext);

	const [personal, setPersonal] = useState({});

	useEffect(() => {
		setPersonal(data);
	}, [data, lang]);

	const message = DOMPurify.sanitize(
		...getHelpMessage(lang, 'personal_info_PE')
	);

	return (
		<>
			{personal && (
				<div className={styles.form}>
					<Row>
						<Col sm={8} className={styles.header}>
							<InfoButton
								onClick={showModal({
									text: parse(message),
								})}
							>
								<Label
									className={styles.title}
									text={<FormattedMessage id={'rod.account.personal.title'} />}
									font="--gilroy-Medium-40"
									display="inline"
									black
								/>
							</InfoButton>
							<Label
								className={styles.subTitle}
								text={<FormattedMessage id={'rod.form.required_title'} />}
								font="--gilroy-Medium-18"
							/>
						</Col>
					</Row>
					<div
						className={styles.container}
						// style={{
						// 	pointerEvents: isEdit ? 'all' : 'none',
						// 	opacity: isEdit ? '1' : '0.6',
						// 	userSelect: isEdit ? 'default' : 'none',
						// }}
					>
						<div>
							<FormattedMessage id={'rod.field.personal_entrepreneur.name'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage
												id={'rod.field.personal_entrepreneur.name.helper'}
											/>
										}
										name={'title'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.personal_entrepreneur.signer'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage
												id={'rod.field.personal_entrepreneur.signer.helper'}
											/>
										}
										name={'behalf'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
										className={styles.widthCorrecting}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage
								id={'rod.field.personal_entrepreneur.signer_short_name'}
							>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage
												id={
													'rod.field.personal_entrepreneur.signer_short_name.helper'
												}
											/>
										}
										name={'behalf_short'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
										className={styles.widthCorrecting}
									/>
								)}
							</FormattedMessage>
						</div>
						<div>
							<FormattedMessage id={'rod.field.registration_address'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'address'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
										className={styles.livingPlace}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.tax_number'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'itin'}
										onChange={changeField}
										errors={errors}
										data={personal}
										required={
											window.location.href.includes('.ru') ||
											window.location.href.includes('broma16ru-stage')
												? true
												: false
										}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(SoleProprietor);
