// Core
import React from 'react';

// UI
import NewRecordingForm from '../NewRecordingForm/NewRecordingForm';

// Styles
import s from './RecordingsList.module.css';

const RecordingsList = ({
	recordings,
	onRemoveClick,
	newRecordings,
	recordingSave,
	data,
	errors,
	isEdit,
	disabledSaveBtn,
}) => {
	return (
		<div>
			<ul className={s.list_items}>
				{recordings &&
					recordings
						.sort((a, b) => a.id - b.id)
						.map((recording, index) => (
							<li key={recording.id} className={s.item}>
								<NewRecordingForm
									data={data}
									number={index + 1}
									recording={recording}
									onClickDeleteItem={() => onRemoveClick(recording.id)}
									recordingSave={recordingSave}
									isEdit={isEdit}
									addNewRecordings={newRecordings}
									errors={errors}
									disabledSaveBtn={disabledSaveBtn}
								/>
							</li>
						))}
			</ul>
		</div>
	);
};

export default RecordingsList;
