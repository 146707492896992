// Core
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';

// UI
import { Loading } from 'components';

// Styles
import styles from './ListTable.module.css';

const ListTable = ({
	loading,
	accountsData,
	url,
	getMark,
	showLastActiveTime,
}) => {
	return (
		<div className={styles.tableContainer}>
			<table className={styles.table}>
				{loading ? (
					<tr className={styles.loaderWrapper}>
						<td colspan="17">
							<Loading className={styles.loader} />
						</td>
					</tr>
				) : (
					<>
						<tr>
							<th rowSpan={2} className={styles.firstCol}>
								<FormattedMessage id={'rod.field.admin.title'} />
							</th>
							<th colSpan={3}>
								<FormattedMessage id={'rod.field.admin.user'} />
							</th>
							<th colSpan={7}>
								<FormattedMessage id={'rod.field.admin.account-creation'} />
							</th>
							<th colSpan={5}>
								<FormattedMessage id={'rod.field.admin.contract'} />
							</th>
							<th rowSpan={2} className="date">
								<FormattedMessage id={'rod.field.admin.last-active'} />
							</th>
						</tr>
						<tr className="second">
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.phone'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.email'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.acc-created'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.role-selection'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.personal-data'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.contact-data'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.documents'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.identifiers'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.payment-details'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.check-documents'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.conditions'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.revision'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.signing'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.contracts-revision'} />
								</div>
							</th>
							<th>
								<div className="rotate-270">
									<FormattedMessage id={'rod.field.admin.repertoire'} />
								</div>
							</th>
						</tr>
						{accountsData?.length === 0 && (
							<tr className={styles.noData}>
								<td colspan="17">
									<FormattedMessage id={'rod.admin.no-data'} />
								</td>
							</tr>
						)}
						{accountsData?.length > 0 &&
							accountsData.map((item, index) => (
								<tr key={index}>
									<td className={styles.firstCol}>
										{item.type === 'user' ? (
											item.signup_id ? (
												<div
													className={styles.signUpTitle}
													title={item.title || item.id}
												>
													<Link to={`${url}/${item.signup_id}/signup/general`}>
														{item.title || item.id}
													</Link>
												</div>
											) : (
												<div
													className={styles.overflow}
													title={item.title || item.id}
												>
													{item.title || item.id}
												</div>
											)
										) : (
											<div
												className={styles.signUpTitle}
												title={
													item.title &&
													item.title_account &&
													item.title !== item.title_account
														? item.title_account + `\n(` + item.title + `)`
														: item.title_account
												}
											>
												<Link to={`${url}/${item.account_id}/general`}>
													{item.title &&
													item.title_account &&
													item.title !== item.title_account
														? item.title_account + `\n(` + item.title + `)`
														: item.title_account}
												</Link>
											</div>
										)}
									</td>
									{getMark(item.step ? item.step : 'confirm_phone')}
									<td
										className="date"
										title={moment.utc(item.updated_at).local().format('LLL')}
									>
										{showLastActiveTime(item.updated_at)}
									</td>
								</tr>
							))}
					</>
				)}
			</table>
		</div>
	);
};

export default ListTable;
