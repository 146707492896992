/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 11/03/2021, 11:56
 */
import http from '../http';
import api from '../interceptor';

export default class Auth {
	static login = (email, password) => {
		return http
			.post('/auth/login', {
				email,
				password,
			})
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	static getUser(page = 1, search = '') {
		return api
			.get(`/user?search=${search}&page=${page}&limit=10`)
			.then((result) => {
				return result;
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	}

	static getAllUserAccounts(page = 1, search = '') {
		return api.get(`/user?search=${search}&page=${page}&limit=10000`);
	}

	static logout = () => {
		return api.post('/auth/logout', {}).catch(() => {
			throw new Error("Can't logout");
		});
	};

	static register = (data) => {
		return http.post(`/auth/registration`, {
			agreement: true,
			...data,
		});
	};

	static registerSpecial = (data) => {
		return http.post(`/support/registration/`, data);
	};

	static isEmailExist = (email) => {
		return http.get(`/auth.check_email?email=${email}`);
	};

	static sendSms = (number) => {
		let fd = new FormData();
		fd.append('phone', number);

		return http.post('/auth.send_auth_code', fd);
	};

	static checkSMSCode = ({ code }) => {
		return http
			.post('/auth/registration/confirmation/sms', { code })
			.then((data) => {
				return Promise.resolve(data);
			});
	};

	static resendSMSCode = ({ phone }) => {
		return http.post('/auth/registration/confirmation/sms/resend', { phone });
	};

	static noCode = (data) => {
		return http.post('/support/ticket', data);
	};

	static checkEmailCode = ({ code }) => {
		return api
			.post('/auth/registration/confirmation/email', { code })
			.then((data) => {
				return Promise.resolve(data);
			});
	};
	static resendEmailCode = ({ email }) => {
		return http.post('/auth/registration/confirmation/email/resend', { email });
	};

	static checkResetToken = (token) => {
		let fd = new FormData();
		fd.append('restore_code', token);

		return http.post('/auth.check_reset_token', fd).catch(() => {
			throw new Error('reset token is not valid');
		});
	};

	static restorePasswordRequest = ({
		email,
		password,
		password_confirmation,
	}) => {
		return http.post('/auth/password/reset', {
			email,
			password,
			password_confirmation,
		});
	};

	static resetPassword = ({ token }) => {
		return http.post(`/auth/password/reset/${token}`, {});
	};

	static resetPasswordRequest = ({ email, password }) => {
		return http.post('/auth/password-reset/request', {
			email,
			password,
		});
	};

	static changePasswordSuccess = (token) => {
		return http.get(`/auth/password-reset/apply/${token}`);
	};

	static getDomain = (accountId) => {
		return api.get(`/accounts/${accountId}/domain`);
	};
	static createTokenForRedirect = () => {
		return api.post(`/auth/token/create`);
	};
	static getTokensWithHandoff = (handoff) => {
		return api.get(`/auth/tokens/get?handoff_token=${handoff}`);
	};

	static getCountryCode = () => {
		return api.get(`/user/country/code`);
	};

	static getSmsRetryTime = (type) => {
		return api.get(`/user/sms/retry?type=${type}`);
	};
}
