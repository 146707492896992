/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/03/2021, 20:12
 */

import classnames from 'classnames';
import chevronDownImg from 'images/chevron_down.svg';
import chevronUpImg from 'images/chevron_up.svg';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './DropdownCombo.module.css';

class DropdownCombo extends PureComponent {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.list = React.createRef();
		this.selected = React.createRef();
		this.state = {
			highLightIndex: 0,
			filteredItems: props.items,
			isOpen: false,
			item: props.defaultEmpty ? null : props.items[props.defaultItemIndex],
			inputValue: '',
		};
		if (props.value) {
			this.state.item = props.items.find(
				(item) => item.keyword === props.value || item.id === props.value
			);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				item: this.props.items.find(
					(item) =>
						item.keyword === this.props.value || item.id === this.props.value
				),
			});
		}
		if (this.state.inputValue === '') {
			this.setState({ filteredItems: this.props.items });
		}
	}

	handleSelect = (item) => (e) => {
		// const data = this.filteredValues(this.state.inputValue.toString());

		this.setState({
			item,
			isOpen: false,
			inputValue: item.dialing_code,
		});
		this.props.onChange(this.props.getItemValue(item));
	};

	handleOpen = () => {
		this.setState({ isOpen: true });
	};

	handleOnBlur = () => {
		document.addEventListener('click', this.handleClose);
	};

	onChangeValue = (e) => {
		const { items } = this.props;
		let value = e.target.value;
		const filteredItems = this.filteredValues(value);

		if (!Number(value)) return this.setState({ inputValue: '' });
		this.setState({
			inputValue: value,
			highLightIndex: 0,
			filteredItems: filteredItems.length > 0 ? filteredItems : items,
		});
	};

	filteredValues = (value) => {
		const data = this.props.items.filter((item) => {
			return item.dialing_code.toString().indexOf(value.toString()) === 0;
		});
		return data;
	};

	handleClose = () => {
		this.setState({ isOpen: false });
		document.removeEventListener('click', this.handleClose);
	};

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClose);
	}

	showError = (error) => {
		if (typeof this.props.showError === 'function') {
			return this.props.showError(error);
		} else {
			if (this.props.name) {
				return (
					<FormattedMessage id={`rod.error.${this.props.name}_${error.rule}`} />
				);
			} else {
				return <FormattedMessage id={`rod.error.${error.rule}`} />;
			}
		}
	};
	scrollToSelected = () => {
		this.list?.current?.querySelector('.selected')?.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
			inline: 'nearest',
		});
	};

	handleKeyUp = (e) => {
		switch (e.key) {
			case 'ArrowUp':
				this.setState(
					{
						highLightIndex: Math.max(0, this.state.highLightIndex - 1),
					},
					this.scrollToSelected
				);
				break;
			case 'ArrowDown':
				this.setState(
					{
						highLightIndex: Math.min(
							this.state.highLightIndex + 1,
							this.state.filteredItems.length - 1
						),
					},

					this.scrollToSelected
				);
				break;
			case 'Enter':
				const item = this.state.filteredItems[this.state.highLightIndex];
				this.setState(
					{
						item,
						highLightIndex: -1,
						isOpen: false,
						inputValue: item.dialing_code,
					},
					() => {
						document.removeEventListener('click', this.handleClose, true);
						this.props.onChange(this.props.getItemValue(item));
					}
				);
				break;
			default:
				return;
		}
	};
	handleFocus = () => {
		if (!this.state.isOpen) {
			this.handleOpen();
		}
	};

	render() {
		const {
			items,
			helper,
			errors,
			className,
			getItem,
			getItemValue,
			getListItem,
			children,
			onChange,
			name,
			placeholder,
			showChevron,
			showRequired,
			defaultItemIndex,
			defaultEmpty,
			forwardRef,
			...props
		} = this.props;
		const { item, isOpen } = this.state;

		const classes = classnames(
			className,
			styles.Dropdown,
			errors.length > 0 && styles.Error
		);

		if (forwardRef) {
			this.ref = forwardRef;
		}

		const value = item ? getItem(item, isOpen) : null;
		const data = this.filteredValues(this.state.inputValue);

		return (
			<div className={classes} {...props}>
				{!isOpen ? (
					<div className={styles.Value} onClick={this.handleOpen}>
						{value}
					</div>
				) : (
					<div className={styles.inputWrapper}>
						<span className={styles.maskPlus}>+</span>
						<input
							type="text"
							className={styles.Value}
							onClick={this.handleOpen}
							onFocus={this.handleFocus}
							value={this.state.inputValue}
							onChange={this.onChangeValue}
							onKeyUp={this.handleKeyUp}
							onBlur={this.handleOnBlur}
							autoFocus
							style={{ 'padding-left': '20px' }}
							ref={this.input}
						/>
					</div>
				)}
				{showChevron && (
					<div className={styles.Chevron} onClick={this.handleOpen}>
						{isOpen ? (
							<img src={chevronUpImg} alt="" />
						) : (
							<img src={chevronDownImg} alt="" />
						)}
					</div>
				)}
				{isOpen && (
					<ul className={styles.List} ref={this.list}>
						{data.map((item, index) => (
							<li
								key={item.id}
								onClick={this.handleSelect(item)}
								data-index={index}
								ref={this.state.highLightIndex === index ? this.selected : null}
								className={classnames(styles.Item, {
									selected: this.state.highLightIndex === index,
								})}
							>
								{getListItem(item)}
							</li>
						))}
					</ul>
				)}
				{helper && errors.length === 0 && (
					<span className={styles.Helper}> {helper} </span>
				)}
				{errors.length > 0 && (
					<span className={styles.Helper}> {this.showError(errors[0])} </span>
				)}
				<input
					type="hidden"
					name={name}
					value={item ? getItemValue(item) : ''}
					ref={forwardRef}
				/>
			</div>
		);
	}
}

DropdownCombo.propTypes = {
	items: PropTypes.array.isRequired,
	defaultItemIndex: PropTypes.number,
	defaultEmpty: PropTypes.bool,
	getItem: PropTypes.func,
	getListItem: PropTypes.func,
	getItemValue: PropTypes.func,
	onChange: PropTypes.func,
	showError: PropTypes.func,
	showChevron: PropTypes.bool,
	showRequired: PropTypes.bool,
};

DropdownCombo.defaultProps = {
	errors: [],
	showChevron: true,
	defaultItemIndex: 0,
	getItem: (item) => item,
	getListItem: (item) => item,
	getItemValue: (item) => item,
	onChange: () => {},
};

export default DropdownCombo;
