// Utils
import { fm } from './fm';
import { statusLocalization } from './statusLocalization';

// Constants
import DOMAINS from '../constants/domains';

const getDomain = () => {
	switch (document.domain) {
		case DOMAINS.BROMA_COM_PROD:
			return DOMAINS.BROMA_RU_PROD;
		case DOMAINS.BROMA_RU_PROD:
			return DOMAINS.BROMA_COM_PROD;
		case DOMAINS.BROMA_RU_STAGE:
			return DOMAINS.BROMA_COM_STAGE_C;
		case DOMAINS.BROMA_COM_STAGE_C:
			return DOMAINS.BROMA_RU_STAGE;
		default:
			return DOMAINS.BROMA_RU_STAGE;
	}
};

export const getHistoryStatuses = (item) => {
	if (item.object_type === 'release' || item.object_type === 'composition') {
		if (item.step_from === 'moderation' || item.step_from === 'completed') {
			return (
				<>
					{fm('rod.admin.account.status.repertoire_apply')} &#10230;{' '}
					{item.object_type === 'release'
						? fm('rod.admin.account.status.repertoire.release.edit.confirm')
						: fm(
								'rod.admin.account.status.repertoire.composition.edit.confirm'
						  )}
				</>
			);
		}

		if (item.step_from === 'confirm') {
			return (
				<>
					{fm('rod.admin.account.status.repertoire_apply')} &#10230;{' '}
					{item.object_type === 'release'
						? fm('rod.admin.account.status.repertoire.release.save.confirm')
						: fm(
								'rod.admin.account.status.repertoire.composition.save.confirm'
						  )}
				</>
			);
		}
	}

	if (item.action === 'account.redirect') {
		return (
			<>
				{fm('rod.admin.account.status.account_redirect')} {document.domain}{' '}
				&#10230; {getDomain()}
			</>
		);
	}

	return (
		<>
			{item.step_from ? statusLocalization(item.step_from) : '(-)'} &#10230;{' '}
			{statusLocalization(item.step_to)}
		</>
	);
};
