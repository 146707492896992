import React from 'react';
import {
	TextFieldInput,
	AutocompleteInput,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';
import { Popper } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import isItemSelected from 'logic/multiselectHighlight.js';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				margin: 0,
				padding: 0,
				'& li': {
					backgroundColor: '#FFF',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px 8px',
					'&:hover': {
						backgroundColor: 'var(--color-light-grey) !important',
					},
				},
			},
			'& label': {
				backgroundColor: 'red',
				// '& input': {
				// 	color: 'red',
				// },
			},
		},
	})
);

const useStylesText = makeStyles((theme) =>
	createStyles({
		root: {
			padding: '12px 4px 10px !important',
			'& .MuiInputLabel-root': {
				padding: '12px 4px 10px !important',
			},
		},
	})
);

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement="bottom" />;
};
const CustomPopperText = function (props) {
	const classes = useStylesText();
	return <Popper {...props} className={classes.root} placement="bottom" />;
};

const MuiAutocomplete = ({ onChange, label, value, errors }) => {
	const roles = [
		{
			id: 1,
			item: <FormattedMessage id={'rod.muiAuthor.author_of_lyrics'} />,
			logo: 'A',
		},
		{
			id: 2,
			item: <FormattedMessage id={'rod.muiAuthor.composer_writer'} />,
			logo: 'C',
		},
		{
			id: 3,
			item: <FormattedMessage id={'rod.muiAuthor.composer_author'} />,
			logo: 'CA',
		},
		{
			id: 4,
			item: <FormattedMessage id={'rod.muiAuthor.arranger'} />,
			logo: 'AR',
		},
		{
			id: 5,
			item: <FormattedMessage id={'rod.muiAuthor.adaptor'} />,
			logo: 'AD',
		},
		{
			id: 6,
			item: <FormattedMessage id={'rod.muiAuthor.translator'} />,
			logo: 'TR',
		},
		// {
		// 	id: 7,
		// 	item: <FormattedMessage id={'rod.muiAuthor.participant'} />,
		// 	logo: 'PA',
		// },
	];
	for (let i = 0; i < value.length; i++) {
		for (let j = 0; j < roles.length; j++) {
			if (value[i] === roles[j].logo) {
				value[i] = {
					id: roles[j].id,
					logo: roles[j].logo,
					item: roles[j].item,
				};
			}
		}
	}

	return (
		<AutocompleteInput
			multiple
			size="small"
			limitTags={2}
			onChange={(_, obj) => onChange(obj)}
			options={roles}
			value={value}
			renderInput={(params) => (
				<TextFieldInput
					{...params}
					label={label}
					id={label}
					variant="standard"
					PopperComponent={CustomPopperText}
					error={errors && Object.keys(errors).length > 0}
				/>
			)}
			getOptionLabel={(option) => {
				return option.logo;
			}}
			renderOption={(option, item) => {
				return (
					<li
						key={item}
						{...option}
						style={{
							backgroundColor: isItemSelected(item, value)
								? 'var(--color-green)'
								: 'var(--color-white)',
							color: isItemSelected(item, value)
								? 'var(--color-white)'
								: 'unset',
						}}
					>
						{`${item.logo} - `} {item.item}
					</li>
				);
			}}
			isOptionEqualToValue={(option, value) => option.logo === value.logo}
			PopperComponent={CustomPopper}
		/>
	);
};

export default MuiAutocomplete;
