import React, { useState, useEffect } from 'react';
import { InfoButton } from 'components/Buttons';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { getHelpMessage } from 'messages/helper';
import { Label } from 'components/Labels/Label';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Loading } from 'components';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import plus_in_square from 'images/plus_in_square.svg';
import plus from 'images/plus.svg';
import { Col, Row } from 'react-grid-system';
import modalTypes from 'constants/modalTypes';

import styles from './SettingsPseudonymsSection.module.css';
import { useHistory } from 'react-router-dom';

const SettingsPseudonymsSection = (props) => {
	const [loading, setLoading] = useState(true);
	// const [errors, setErrors] = useState('');
	const [errors, setErrors] = useState({});
	const [personal, setPersonal] = useState({});
	const [data, setData] = useState([
		{
			title: 'Broma1',
			isMain: true,
			ipi: '123213',
			roles: [],
			outlets: [],
		},
		{
			title: 'Broma2',
			isMain: false,
			ipi: '1231243455',
			roles: ['role1', 'role2', 'role3'],
			outlets: [
				'outlet1',
				'outlet2',
				'outlet3',
				'outlet1',
				'outlet2',
				'outlet3',
				'outlet1',
				'outlet2',
				'outlet3',
			],
		},
	]);

	const {
		UIContext: { showModal },
	} = props;
	const lang = localStorage.getItem('lang');
	const message = DOMPurify.sanitize(...getHelpMessage(lang, 'pseudonyms'));

	const isAllRequiredFilled = (data) => {
		return true;
	};
	const history = useHistory();

	useEffect(() => {
		setLoading(false);
		setErrors({});
		const {
			// rootContext: { getAccountContactsData },
			data,
		} = props;
		// getAccountContactsData(accountId)
		// 	.then((res) => {
		// 		res = res.data.data;
		if (Array.isArray(props.data)) {
			setData(props.data.sort((a, b) => b.main - a.main));
		}
		props.isAllDone('pseudonyms')(isAllRequiredFilled(data));
		// setData(.....);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data]);

	useEffect(() => {
		setErrors(props.errors);
		if (!Object.keys(props.errors).length) {
			props.isAllDone('pseudonyms')(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.errors]);

	useEffect(() => {
		!isAllRequiredFilled(personal)
			? props.isAllDone('pseudonyms')(false)
			: props.isAllDone('pseudonyms')(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personal]);

	const showRoles = (data) => {
		return (
			<div className={styles.roleOutletLine}>
				{data.map((item, index) => {
					return index < 5 ? (
						<div>
							{item.code && lang === 'ru' ? item.title : item.code}
							{index !== 4 && index !== data.length - 1 && ','}&nbsp;
						</div>
					) : (
						''
					);
				})}
				{data.length > 5 && (
					<span style={{ marginLeft: '10px' }}>{`+${data.length - 5}`}</span>
				)}
			</div>
		);
	};

	const handleMakePseudonymMain = (isMain, accountId, pseudonymId) => {
		if (!isMain) {
			props.onChange('update', { accountId, pseudonymId, body: { main: '1' } });
		}
	};

	const handleDeletePseudonym = (accountId, pseudonymId) => {
		props.onChange('delete', { accountId, pseudonymId });
	};

	const handleEditPseudonym = (accountId, pseudonymId) => {
		history.push(
			`/profile/${accountId}/settings/pseudonyms/${pseudonymId}/edit`
			// (state: {})
		);
	};

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<div className={styles.Form}>
					<div className={styles.titleWrapper}>
						<InfoButton
							onClick={showModal({
								text: parse(message),
							})}
							className={styles.titleInfoBtn}
						>
							<Label
								className={styles.title}
								text={
									<FormattedMessage id={'rod.account.settings.pseudonyms'} />
								}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
						<div
							className={styles.button__addPublisher}
							onClick={() => {
								history.push('/profile/settings/pseudonyms/create');
							}}
						>
							<img src={plus} alt="" width="16px" />
							<FormattedHTMLMessage id={'rod.pseudonyms.add'} />
						</div>
						{/* <Label
							className={styles.subTitle}
							text={<FormattedMessage id={'rod.form.required_title'} />}
							font="--gilroy-Medium-18"
							black
						/> */}
					</div>
					{data.length > 0 &&
						data.map((item, index) => (
							<section className={styles.pseudonymWrapper}>
								<div className={styles.pseudonymTitleWrapper}>
									<div className={styles.titleBtnWrapper}>
										<div className={styles.title}>{item.title} </div>
										<div
											className={
												item.main
													? styles.mainPseudonymActive
													: styles.mainPseudonymToDo
											}
											onClick={() =>
												handleMakePseudonymMain(
													item.main,
													item.account_id,
													item.id
												)
											}
										>
											<FormattedMessage
												id={
													item.main
														? 'rod.pseudonyms.main'
														: 'rod.pseudonyms.todo_main'
												}
											/>
										</div>
									</div>
									<div className={styles.menuMoreWrapper}>
										<ul className={styles.menuMoreWrapper}>
											<li>
												<button
													onClick={() =>
														handleEditPseudonym(item.account_id, item.id)
													}
													className={styles.editBtn}
												>
													<svg
														width="25"
														height="25"
														viewBox="0 0 25 25"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M24.1089 22.793H0.891603C0.403488 22.793 0.0214844 23.1962 0.0214844 23.6631C0.0214844 24.13 0.42471 24.5332 0.891603 24.5332H24.1301C24.6183 24.5332 25.0003 24.13 25.0003 23.6631C25.0003 23.1962 24.597 22.793 24.1089 22.793Z"
															fill="black"
														/>
														<path
															d="M0.0212224 15.0891L0 19.2275C0 19.4609 0.0848897 19.6944 0.254669 19.8642C0.424448 20.034 0.636672 20.1188 0.870119 20.1188L4.98727 20.0976C5.22071 20.0976 5.43294 20.0127 5.60272 19.843L19.8217 5.62394C20.1613 5.28438 20.1613 4.7326 19.8217 4.37182L15.747 0.254669C15.4075 -0.0848896 14.8557 -0.0848896 14.4949 0.254669L11.6511 3.11969L0.275891 14.4737C0.127335 14.6435 0.0212224 14.8557 0.0212224 15.0891ZM15.1316 2.12224L17.9966 4.98727L16.3837 6.60017L13.5187 3.73514L15.1316 2.12224ZM1.78268 15.4711L12.2666 4.98727L15.1316 7.85229L4.64771 18.3149L1.76146 18.3362L1.78268 15.4711Z"
															fill="black"
														/>
													</svg>
												</button>
											</li>
											{/* {data.length > 1 && !item.main && (
												<li>
													<button
														onClick={showModal(
															{
																title: (
																	<FormattedMessage
																		id={'rod.pseudonyms.delete-confirm'}
																	/>
																),
																text: (
																	<FormattedMessage
																		id={'rod.pseudonyms.delete-help'}
																	/>
																),
																accountId: item.account_id,
																pseudonymId: item.id,
																onAction: handleDeletePseudonym,
																confirmBtnTxt: (
																	<FormattedMessage
																		id={'rod.modal.yes_delete'}
																	/>
																),
																declineBtnTxt: (
																	<FormattedMessage id={'rod.modal.cancel'} />
																),
															},
															modalTypes.FULL_MODAL
														)}
														className={styles.deleteBtn}
													>
														
														<svg
															width="25"
															height="25"
															viewBox="0 0 16 16"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M1 0L0 1L7.00002 8.00002L4.62683e-05 15L1.00005 16L8.00002 9.00002L15 16L16 15L9.00002 8.00002L16 1L15 0L8.00002 7.00002L1 0Z"
																fill="var(--color-dark-grey)"
															/>
														</svg>
													</button>
												</li>
											)} */}
										</ul>
									</div>
								</div>
								<div className={styles.pseudonymInfo}>
									<Row className={styles.ipiRow}>
										<Col sm={4}>
											<ul className={styles.ipiWrapper}>
												<li className={styles.ipiName}>
													<Label
														className={styles.ipi}
														text={'IPI'}
														// font="--gilroy-Medium-40"
														display="inline"
													/>
												</li>
												<li className={styles.ipiValue}>
													{item.ipi_name_number
														? item.ipi_name_number
														: '\u2212'}
												</li>
											</ul>
										</Col>
										<Col sm={4}>
											<ul className={styles.ipiWrapper}>
												<li className={styles.ipiName}>
													<Label
														className={styles.ipi}
														text={'IPN'}
														// font="--gilroy-Medium-40"
														display="inline"
													/>
												</li>
												<li className={styles.ipiValue}>
													{item.ipn ? item.ipn : '\u2212'}
												</li>
											</ul>
										</Col>
										<Col sm={4}>
											<ul className={styles.ipiWrapper}>
												<li className={styles.ipiName}>
													<Label
														className={styles.ipi}
														text={'ISNI'}
														// font="--gilroy-Medium-40"
														display="inline"
													/>
												</li>
												<li className={styles.ipiValue}>
													{item.isni ? item.isni : '\u2212'}
												</li>
											</ul>
										</Col>
									</Row>
								</div>
							</section>
						))}
				</div>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(SettingsPseudonymsSection);
