export const formatPhoneNumber = (phoneNumber) => {
	// Remove any non-digit characters
	const cleanedNumber = phoneNumber.replace(/\D/g, '');
	const code = localStorage.getItem('code');

	if (!cleanedNumber || !code) return phoneNumber;

	if (code && code.length === 1 && cleanedNumber.length === 11) {
		return `+${code} ${cleanedNumber.substr(1, 3)} ${cleanedNumber.substr(
			4,
			3
		)}-${cleanedNumber.substr(7, 2)}-${cleanedNumber.substr(9, 2)}`;
	} else if (code && code.length === 2 && cleanedNumber.length === 12) {
		return `+${code} ${cleanedNumber.substr(2, 3)} ${cleanedNumber.substr(
			5,
			3
		)}-${cleanedNumber.substr(8, 2)}-${cleanedNumber.substr(10, 2)}`;
	} else if (code && code.length === 3 && cleanedNumber.length === 13) {
		return `+${code} ${cleanedNumber.substr(3, 3)} ${cleanedNumber.substr(
			6,
			3
		)}-${cleanedNumber.substr(9, 2)}-${cleanedNumber.substr(11, 2)}`;
	} else if (code && code.length === 4 && cleanedNumber.length === 14) {
		return `+${code} ${cleanedNumber.substr(4, 3)} ${cleanedNumber.substr(
			7,
			3
		)}-${cleanedNumber.substr(10, 2)}-${cleanedNumber.substr(12, 2)}`;
	} else if (code && code.length === 4 && cleanedNumber.length === 15) {
		return `+${code} ${cleanedNumber.substr(4, 3)} ${cleanedNumber.substr(
			7,
			4
		)}-${cleanedNumber.substr(10, 2)}-${cleanedNumber.substr(12, 2)}`;
	} else {
		return `+${cleanedNumber}`;
	}
};
