// Core
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Services
import { getReleaseTypeById } from 'utils';

// Utils
import { formatListens } from './utils/formatListens';

// UI
import emptyImg from 'images/empty-img.svg';

// Styles
import styles from './TopReleaseCard.module.css';

const TopReleaseCard = ({ release }) => {
	return (
		<Link
			key={release.id}
			to={`/repertoire/releases/${release.id}/info`}
			className={styles.link}
		>
			<div className={styles.card}>
				<div className={styles.image}>
					{!release.cover && (
						<div className={styles.noCover}>
							<img src={emptyImg} className={styles.imgNoCover} alt="" />
							<span className={styles.noImgTitle}>
								<FormattedMessage id={'rod.release.info.no-cover'} />
							</span>
						</div>
					)}
					{release.cover && (
						<img className={styles.cover} src={release.cover} alt="" />
					)}
				</div>
				<div className={styles.album}>
					{release.release_type_id && (
						<span>{getReleaseTypeById(release.release_type_id)}</span>
					)}
					{release.moderation_status && (
						<span>
							<FormattedMessage
								id={`rod.repertoire.${release.moderation_status}`}
							/>
						</span>
					)}
				</div>
				<div className={styles.title} title={release.title}>
					{release.title ?? '-'}
				</div>
				<div
					className={styles.performer}
					title={
						release.performers &&
						release.performers.length &&
						release.performers.join(' ')
					}
				>
					{release.performers && release.performers.length
						? release.performers.join(' ')
						: '-'}
				</div>
				<div className={styles.streams}>
					<span className={styles.streams__text}>
						<FormattedMessage id={'rod.release.info.streams'} />:
					</span>{' '}
					<span>{formatListens(release.listens) ?? '-'}</span>
				</div>
			</div>
		</Link>
	);
};

export default TopReleaseCard;
