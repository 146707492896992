// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import numeral from 'numeral';
import { FormattedMessage } from 'react-intl';

// UI
import Dropzone from 'react-dropzone';
import attachmentImg from 'images/attachment.svg';
import picture from 'images/picture.svg';
import closeImg from 'images/close_black.svg';
import errorImg from 'images/error.svg';

// Types
import { ERROR_TYPES } from './DropZoneTypes';

// Styles
import styles from './DropZone.module.css';

class DropZone extends PureComponent {
	state = {
		loading: false,
		error: this.props.error,
		errorType: this.props.errorType,
		moderation: this.props.moderation,
	};

	componentDidUpdate() {
		if (this.props.error) {
			this.setState({ error: true, errorType: this.props.error.rule });
		}
	}

	reset = () => {
		const { cover } = this.props;

		if (cover) {
			this.props.setErrors({});
		}

		if (this.state.errorType === 'track_format') {
			this.props?.setFormatError({});
		}

		this.setState({
			loading: false,
			error: false,
			errorType: 'file',
			moderation: null,
		});
	};

	upload = (files) => {
		const { onUpload, maxSize, getDropFilesCount, cover } = this.props;

		if (cover) {
			this.props.setErrors({});
		}

		if (getDropFilesCount) {
			getDropFilesCount(files);
		}

		if (files.some((file) => file.size > maxSize)) {
			this.setState({
				error: true,
				errorType: ERROR_TYPES.SIZE,
			});
			return;
		}

		this.setState(
			{
				loading: true,
			},
			() => {
				const upload = onUpload(files);
				if (upload && upload instanceof Promise) {
					upload
						.then((res) => {
							this.setState({
								loading: false,
								error: false,
							});
						})
						.catch((err) => {
							this.setState({
								loading: false,
								error: true,
								errorType:
									err.data && err.data.errors.file[0].rule === 'max_size'
										? ERROR_TYPES.SIZE
										: ERROR_TYPES.FILE,
								errorSize: err.data && err.data.errors.file[0].value,
							});
						});
				} else {
					this.setState({
						loading: false,
						error: false,
					});
				}
			}
		);
	};

	remove = () => {
		const res = this.props.onRemove();
		if (res && res.hasOwnProperty('then')) {
			this.setState(
				{
					loading: true,
				},
				() => {
					res
						.then((res) => {
							this.setState({
								loading: false,
								error: false,
							});
						})
						.catch((res) => {
							this.setState({
								loading: false,
								error: true,
								errorType: ERROR_TYPES.FILE,
							});
						});
				}
			);
		}
	};

	imgSelect(imgType) {
		switch (imgType) {
			case 1:
				return attachmentImg;
			case 2:
				return picture;
			default:
				return;
		}
	}

	renderDropzone() {
		const {
			classStyles,
			dropzoneId = 'rod.field.upload',
			imgType = 1,
		} = this.props;

		return (
			<Dropzone onDrop={this.upload} dropFilesCount={this.state.dropFilesCount}>
				{({ getRootProps, getInputProps, isDragActive }) => (
					<section
						className={cn(
							styles.Dropzone,
							{ [styles.Active]: isDragActive },
							classStyles
						)}
					>
						<div {...getRootProps()}>
							<input {...getInputProps()} accept={this.props.accept} />
							<div className={styles.Area}>
								<img src={this.imgSelect(imgType)} alt="attachment" />
								<div className={styles.text}>
									<FormattedMessage
										id={dropzoneId}
										values={{
											a: (msg) => <span>{msg}</span>,
										}}
									/>
								</div>
							</div>
						</div>
					</section>
				)}
			</Dropzone>
		);
	}

	renderUploaded() {
		const { client_name, size, url } = this.props.value;

		return (
			<div className={styles.Uploaded}>
				<div className={styles.Line}>
					<img src={attachmentImg} alt="attachment" />
					<div className="filename">{client_name}</div>
					<div className={styles.Remove} onClick={this.remove}>
						<img src={closeImg} alt="" />
					</div>
				</div>
				<div className="size">
					{numeral(this.props.kb ? size : size * 1000).format('0b')}
				</div>
				{url && this.props.showUrl && (
					<a href={url} target="_blank" rel="noreferrer">
						{url}
					</a>
				)}
			</div>
		);
	}

	renderLoader() {
		return (
			<div className={styles.Loading}>
				<i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
			</div>
		);
	}

	renderError() {
		const { errorType, errorSize } = this.state;
		const { maxSize, cover } = this.props;

		return (
			<div
				className={styles.Error}
				style={errorType === 'track_format' ? { maxWidth: '100%' } : {}}
			>
				<div className="icon" style={cover ? { marginRight: '10px' } : {}}>
					<img src={errorImg} alt="err" />
				</div>
				<div className="content">
					<div className="header">
						<b>
							{errorType === 'track_format' ? (
								<FormattedMessage
									id={'rod.field.upload.error.track_format'}
									values={{
										name: this.props.error.name,
										a: (msg) => (
											<span onClick={this.reset} className="link">
												{msg}
											</span>
										),
									}}
								/>
							) : (
								<FormattedMessage id={'rod.field.upload.error.title'} />
							)}
						</b>
					</div>
					{errorType === 'track_format' ? (
						<FormattedMessage
							id={`rod.field.upload.error.file`}
							values={{
								a: (msg) => (
									<span onClick={this.reset} className="link">
										{msg}
									</span>
								),
							}}
						/>
					) : (
						<FormattedMessage
							id={
								this.props.isSignDocPage && errorType === 'file'
									? 'rod.field.upload.error.sign_doc.file_type'
									: `rod.field.upload.error.${errorType}`
							}
							values={{
								size:
									maxSize && maxSize === 104857600
										? '100MB'
										: maxSize && maxSize === 41943040
										? '40MB'
										: numeral(errorSize || maxSize).format('0b'),
								a: (msg) => (
									<span onClick={this.reset} className="link">
										{msg}
									</span>
								),
							}}
						/>
					)}
				</div>
			</div>
		);
	}

	renderModeration() {
		const { moderation } = this.props;

		return (
			<div className={styles.Error}>
				<div className="icon">
					<img src={errorImg} alt="err" />
				</div>
				<div className="content">
					{!moderation.valid && !moderation.quality ? (
						<div className="moderation-error-header">
							<b>
								<FormattedMessage
									id={'rod.field.upload.moderation.title.invalid_quality'}
								/>{' '}
							</b>
						</div>
					) : (
						<React.Fragment>
							{!moderation.valid && (
								<div className="moderation-error-header">
									<b>
										<FormattedMessage
											id={'rod.field.upload.moderation.title.invalid'}
										/>{' '}
									</b>
								</div>
							)}
							{!moderation.quality && (
								<div className="moderation-error-header">
									<b>
										<FormattedMessage
											id={'rod.field.upload.moderation.title.quality'}
										/>{' '}
									</b>
								</div>
							)}
						</React.Fragment>
					)}
					<FormattedMessage
						id={`rod.field.upload.moderation.text`}
						values={{
							a: (msg) => (
								<span onClick={this.reset} className="link">
									{msg}
								</span>
							),
						}}
					/>
				</div>
			</div>
		);
	}

	render() {
		const { moderation, loading, error } = this.state;
		const { value } = this.props;

		if (
			moderation &&
			!Object.keys(moderation)
				.map((key) => moderation[key])
				.reduce((acc, state) => acc && state)
		) {
			return this.renderModeration();
		} else if (value) {
			return this.renderUploaded();
		} else if (loading) {
			return this.renderLoader();
		} else if (error) {
			return this.renderError();
		} else {
			return this.renderDropzone();
		}
	}
}

DropZone.propTypes = {
	onUpload: PropTypes.func,
	onRemove: PropTypes.func,
	value: PropTypes.object,
	accept: PropTypes.string,
	showUrl: PropTypes.bool,
	maxSize: PropTypes.number,
	moderation: PropTypes.shape({
		valid: PropTypes.bool,
		quality: PropTypes.bool,
	}),
};

DropZone.defaultProps = {
	maxSize: 1024 * 1024 * 20,
	onUpload: () => {},
	onRemove: () => {},
	accept: 'image/png, image/jpeg, application/pdf',
	error: false,
	errorType: ERROR_TYPES.FILE,
};

export default DropZone;
