// Core
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { accounts } from 'services';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import { BottomNavi, InfoHelper } from 'components';
import { DropZone } from 'components/Upload/DropZone';

// Styles
import s from './Cover.module.css';

function Cover(props) {
	const [cover, setCover] = useState('');
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [uploadProgressPercents, setUploadProgressPercents] = useState({});

	const releaseId = localStorage.getItem('releaseId');
	const isH11Edit = window.location.href.includes('/edit/');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));
	const h11EditId = releaseEdit?.id;
	const history = useHistory();
	const { setIsBottomNaviShow } = useContext(UIContext);

	const handleOnLoad = () => {
		setLoading(false);
	};

	const nextHandler = () => {
		if (typeof props.onChange === 'function') {
			props.onChange();
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			if (h11EditId && isH11Edit) {
				history.push(`/release/${h11EditId}/edit/lyrics`);
			} else {
				history.push(`/release/${releaseId}/lyrics`);
			}
			props.handlePrevStep();
		}
	};

	const deleteCover = () => {
		accounts
			.deleteReleaseCover(h11EditId && isH11Edit ? h11EditId : releaseId)
			.then(() => {
				setCover('');
			})
			.catch((error) => {
				console.error('Error', error);
			});
	};

	const uploadImage = (file) => {
		const fd = new FormData();
		fd.append('file', file);
		const config = {
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);

				if (+progressEvent.loaded !== +progressEvent.total) {
					setUploadProgressPercents({ value: percentCompleted });
				}
			},
		};

		setLoading(true);
		accounts
			.setReleaseCover(
				h11EditId && isH11Edit ? h11EditId : releaseId,
				fd,
				config
			)
			.then((res) => {
				setCover(res.data.data.url);
				setUploadProgressPercents({});
				setTimeout(() => {
					setLoading(false);
				}, 1500);
			})
			.catch((err) => {
				if (err?.response.data.errors) {
					setErrors({ file: err.response.data.errors.file });
				}
				setLoading(false);
			});
		return Promise.resolve();
	};

	const handleFileUpload = (files) => {
		setErrors({});
		const file = files[0];

		if (
			file.type !== 'image/jpg"' &&
			file.type !== 'image/jpeg' &&
			file.type !== 'image/png'
		) {
			setErrors({ file: [{ rule: 'image_wrong_format' }] });
			return;
		}

		if (file.size > 1024 * 1024 * 40) {
			setErrors({ file: [{ rule: 'image_size_exceeded' }] });
			return;
		}

		const reader = new FileReader();
		reader.onload = (e) => {
			const img = new Image();
			img.src = e.target.result;
			img.onload = () => {
				const width = img.width;
				const height = img.height;
				if (width !== height) {
					setErrors({ file: [{ rule: 'image_aspect_ratio' }] });
					return;
				}
				// Proceed with uploading the image
				uploadImage(file);
			};
		};
		reader.readAsDataURL(file);
	};

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		accounts
			.getReleaseData(h11EditId && isH11Edit ? h11EditId : releaseId)
			.then((res) => {
				if (res.data.data.cover?.url) {
					setLoading(true);
					setCover(res.data.data.cover.url);
				} else setLoading(false);
			})
			.catch((error) => {
				console.error('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={s.page}>
				<div className={s.main}>
					<span className={s.title}>
						<FormattedMessage id={'rod.release.create.step.cover'} />
					</span>
					<div className={s.body}>
						<div className={s.DropZone__wrapper}>
							{cover || loading ? (
								<div className={s.Preview}>
									{loading && <div className={s.preLoader} />}
									<img
										style={{ display: loading ? 'none' : 'block' }}
										className={loading ? s.loader : null}
										src={cover}
										onLoad={handleOnLoad}
										alt=""
									/>
								</div>
							) : (
								!loading && (
									<>
										<DropZone
											cover
											multiple={false}
											accept=".jpg, .jpeg, .png"
											imgType={2}
											classStyles={s.DropZone}
											dropzoneId={'rod.release.create.step.cover.upload'}
											onUpload={handleFileUpload}
											setErrors={setErrors}
											kb
											maxSize={1024 * 1024 * 40}
										/>
										<div>
											{Object.keys(errors).includes('file') && (
												<div className={s.errorHelper}>
													{errors &&
														errors.file.map((err, index) => (
															<div key={index}>
																<FormattedMessage
																	id={
																		err.rule === 'aspect ratio error'
																			? 'rod.error.image_aspect_ratio'
																			: `rod.error.${err.rule}`
																	}
																	values={
																		err.value && { value: +err.value / 1000 }
																	}
																/>
																<br />
															</div>
														))}
												</div>
											)}
										</div>
									</>
								)
							)}
						</div>
						{cover.length > 0 && !loading && (
							<div className={s.delete} onClick={() => deleteCover()}>
								<FormattedMessage id={'rod.release.create.step.cover.delete'} />
							</div>
						)}
					</div>
				</div>
				<InfoHelper
					title="rod.release.create.step.cover.title"
					text="rod.release.create.step.cover.description"
				/>
			</div>
			<BottomNavi
				showPrevBtn
				disabled={!cover}
				back={backHandler}
				next={nextHandler}
			/>
		</>
	);
}

export default Cover;
