// Core
import { makeStyles } from '@mui/styles';

// UI
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from '@mui/material';
import CmoForm from './CmoForm/CmoForm';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import trash from 'images/trash.svg';

// Styles
import s from './CmoBlock.module.css';

const useStyles = makeStyles({
	removeBeforeElement: {
		'&.MuiAccordion-root:before': {
			display: 'none',
		},
	},
});

const CmoBlock = ({
	selectPersonId,
	cmoSocieties,
	setCmoSocieties,
	onDeleteCmoSociety,
	expanded,
	setExpanded,
	setDisabled,
	type = 'release',
}) => {
	const classes = useStyles();

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : null);
		if (
			isExpanded ||
			(cmoSocieties[panel] &&
				(!cmoSocieties[panel].name ||
					!cmoSocieties[panel].contract_date ||
					typeof cmoSocieties[panel].id === 'string'))
		) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	};

	return (
		<div className={s.cardsContainer}>
			{cmoSocieties.length > 0 &&
				cmoSocieties.map((cmoSociety, index) => (
					<Accordion
						key={index}
						expanded={expanded === index}
						onChange={handleChange(index)}
						className={classes.removeBeforeElement}
						sx={{
							border: '1px solid var(--color-light-grey)',
							boxShadow: 'none',
							margin: '0 !important',
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`${index}bh-content`}
							id={`${index}bh-header`}
							sx={{
								height: '48px',
								borderTopWidth: 0,
							}}
						>
							<Typography
								sx={{
									font: 'var(--gilroy-Medium-18-140) !important',
									fontWeight: '700 !important',
									color: 'var(--color-dark-grey) !important',
								}}
							>
								{cmoSociety.name}
							</Typography>
							<button
								className={s.deleteBtn}
								style={
									cmoSocieties.length === 1 && !cmoSociety.name
										? { display: 'none' }
										: {}
								}
								onClick={(e) => onDeleteCmoSociety(e, cmoSociety.id, index)}
							>
								<img src={trash} alt="trash" />
							</button>
						</AccordionSummary>
						<AccordionDetails>
							<CmoForm
								selectPersonId={selectPersonId}
								cmoSociety={cmoSociety}
								cmoSocieties={cmoSocieties}
								setCmoSocieties={setCmoSocieties}
								setExpanded={setExpanded}
								setDisabledAddBtn={setDisabled}
								type={type}
							/>
						</AccordionDetails>
					</Accordion>
				))}
		</div>
	);
};

export default CmoBlock;
