// Core
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// Constants
import errorRules from 'constants/errorRules';

export const showError = (field) => (error) => {
	if (typeof error === 'string') {
		switch (error) {
			case errorRules.EXISTS_IN_SIGNUP:
				return (
					<FormattedHTMLMessage id={`rod.error.exists_in_signup.${field}`} />
				);
			case errorRules.EXISTS_IN_USER:
				return (
					<FormattedHTMLMessage id={`rod.error.exists_in_user.${field}`} />
				);

			default:
				return <FormattedHTMLMessage id={`rod.error.${error}`} />;
		}
	} else {
		if (
			(field === 'bank_country' || field === 'swift_code') &&
			error &&
			error?.message &&
			error?.message ===
				'Bank data does not meet the requirements of the system'
		) {
			return (
				<FormattedHTMLMessage id={'rod.error.currency_country_not_match'} />
			);
		}
		switch (error.rule) {
			case errorRules.USER_EXIST:
				return <FormattedHTMLMessage id={`rod.error.${field}_exist`} />;
			case errorRules.MIN_LENGTH:
			case errorRules.MIN_AGE:
			case errorRules.MAX_LENGTH:
				if (error.value) {
					return (
						<FormattedHTMLMessage
							id={`rod.error.${error.rule}`}
							values={{ num: error.value ? +error.value : null }}
						/>
					);
				} else {
					return (
						<FormattedHTMLMessage id={`rod.error.${error.rule}_no_value`} />
					);
				}
			case errorRules.INVALID:
				if (field === 'password') {
					return (
						<FormattedHTMLMessage id={`rod.error.${error.rule}_${field}`} />
					);
				}

				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);
			case errorRules.VALIDATION_CYRILLIC:
			case errorRules.CYRILLIC_INVALID:
				if (field === 'password') {
					return (
						<FormattedHTMLMessage id={`rod.error.${error.rule}_${field}`} />
					);
				}

				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);
			case errorRules.REQUIRED:
			case errorRules.REQUIRED_UNLESS:
				if (field === 'society') {
					return <FormattedHTMLMessage id={`rod.error.${error.rule}`} />;
				}
				if (field === 'rod.contracts.add.conditions.header') {
					return (
						<FormattedHTMLMessage id={`rod.error.contracts.add.conditions`} />
					);
				}
				if (field === 'rod.contracts.add.conditions.header.lyric') {
					return (
						<FormattedHTMLMessage
							id={`rod.error.contracts.add.conditions_with_lyric`}
						/>
					);
				}
				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);
			case errorRules.SOMETHING_WENT_WRONG:
			case errorRules.WRONG_EMAIL_OR_PASSWORD:
				return (
					<FormattedHTMLMessage
						id={`rod.error.incorrect_username_or_password`}
					/>
				);
			case errorRules.UPC_FORMAT:
				return <FormattedHTMLMessage id={`rod.error.invalid_upc_format`} />;
			case errorRules.CODE_INVALID:
				return <FormattedHTMLMessage id={`rod.error.invalid_code_invalid`} />;

			default:
				return (
					<FormattedHTMLMessage
						id={`rod.error.${error.rule}`}
						values={{ num: error.value ? +error.value : null }}
					/>
				);
		}
	}
};
