// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DOMPurify from 'dompurify';
import { getHelpMessage } from 'messages/helper';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { FormInput } from 'components/Form/FormInput';
import { ReportsCheckLayout } from '../../../ReportsCheckLayout';

// Styles
// import styles from './Company.module.css';

const Company = ({ data, changeField, errors, ...props }) => {
	const { lang } = useContext(LangContext);

	const [personal, setPersonal] = useState({});

	useEffect(() => {
		setPersonal(data);
	}, [data, lang]);

	const message = DOMPurify.sanitize(
		...getHelpMessage(lang, 'personal_info_Company')
	);

	return (
		<>
			{personal && (
				<ReportsCheckLayout
					message={message}
					title="rod.account.company.title"
				>
					<div>
						<FormattedMessage id={'rod.field.company.name'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage id={'rod.field.company.name.helper'} />
									}
									name={'title'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.company.signer'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage id={'rod.field.company.signer.helper'} />
									}
									name={'behalf'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.company.signer_short_name'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage
											id={'rod.field.company.signer_short_name.helper'}
										/>
									}
									name={'behalf_short'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						{personal.acting_on === 'attorney' && (
							<FormattedMessage id={'rod.field.company.attorney'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'attorney'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
						)}
					</div>
					<div>
						<FormattedMessage id={'rod.field.registration_address'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'address'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						{/* <FormattedMessage id={'rod.field.company.registration_place'}>
								{(placeholder) => (
									<FormInput
										type="country"
										countries={countries}
										placeholder={placeholder}
										name={'country_id'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
										className={styles.country}
									/>
								)}
							</FormattedMessage> */}
						<FormattedMessage id={'rod.field.company.tax_number'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'itin'}
									onChange={changeField}
									errors={errors}
									data={personal}
									required={
										window.location.href.includes('.ru') ||
										window.location.href.includes('broma16ru-stage')
											? true
											: false
									}
								/>
							)}
						</FormattedMessage>
					</div>
				</ReportsCheckLayout>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(Company);
