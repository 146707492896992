// Core
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Styles
import styles from '../Menu.module.css';

const LogoutBtn = ({ hide }) => {
	return (
		<div className={styles.item_wrapper} onClick={hide}>
			<div className={styles.Item}>
				<Link to={'/logout'} onClick={hide}>
					<FormattedMessage id={'rod.menu.action.logout'} />
				</Link>
			</div>
		</div>
	);
};

export default LogoutBtn;
