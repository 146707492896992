// Core
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import api from 'services/interceptor';
import { withAuth, withRoot, withUI } from 'hocs';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

import { compose } from 'recompose';

// Constants
import STATUSES from './constants/statuses';
import STEPS from './constants/steps';

// Data
import { exceptFilters, filterSettings as filterList } from './filterSettings';

// Utils
import { getIcon } from './helpers/getIcon';

// UI
import ListTable from './components/ListTable/ListTable';
import { Pagination, Input } from 'material-design/components';
import { ReportsAdminModal } from 'material-design/modals/ReportsAdminModal';
import filter_svg from 'images/filter.svg';
import filter_dot_svg from 'images/filter_dot.svg';

// Styles
import styles from './List.module.css';
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

function List(props) {
	let { url } = useRouteMatch();
	const { isShipmentsTakedownsEnableFeature
	} = useContext(UIContext);
	const { getFlagsWithId } = useContext(RootContext);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [accountsData, setAccountsData] = useState([]);
	const [settings, setSettings] = useState({});
	const [modal, setModal] = useState(false);
	const [isFilterEnable, setIsFilterEnable] = useState(false);
	const [filterSettings, setFilterSettings] = useState(filterList);

	const textRef = useRef(null);
	textRef.current = settings;

	const lang = localStorage.getItem('lang');
	moment.locale(lang === 'ru' ? 'ru' : 'en-gb');

	useEffect(() => {
		const {
			authContext: { adminV2Settings },
		} = props;
		getFlagsWithId().then((data) => {
			if (data.account.length) {
				setFilterSettings([
					...filterSettings,
					{
						id: 2,
						title_ru: 'настройки привилегий',
						title_en: 'privilege settings',
						list: [
							...data.account
							.map((item) => {
								return {
									...item,
									name_ru: item['RU'].toLowerCase(),
									name_en: item['EN'].toLowerCase(),
								}
							})
						],
					},
				])
			}
		});

		setSettings(adminV2Settings);

		return () => {
			const {
				authContext: { setAdminV2Settings },
			} = props;

			setAdminV2Settings(textRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const makeURL = (
		currentPage = settings.page,
		selectedStatus = settings.filterStatus,
		selectedSearchValue = settings.searchValue
	) => {
		const obj = {
			page: currentPage && `&page=${currentPage}`,
			status: selectedStatus?.length ? `&status=[${selectedStatus}]` : '',
			searchValue: selectedSearchValue ? `&search=${selectedSearchValue}` : '',
		};

		let result = `/admin/users?limit=50${obj.page}${obj.status}${obj.searchValue}`;
		const flagFilterIds = filterSettings[1]?.list.find((filter) => filter.checked);
		if (flagFilterIds) {
			result = `${result}&flag=${flagFilterIds.id}`
		}
		return result;
	};

	useEffect(() => {
		if (
			settings?.searchValue?.length === 0 ||
			settings?.searchValue?.length >= 3
		) {
			setLoading(true);
			api.get(makeURL()).then((res) => {
				setAccountsData(res.data.data);
				setTotal(res.data.total);
				setLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings]);

	function getMark(step) {
		let marks = [];
		let index = STEPS[step];
		for (let i = 0; i < 15; i++) {
			marks.push(
				<td className="mark checked">
					{index > i
						? getIcon(STATUSES.CHECKED)
						: index === i
							? getIcon(step)
							: ''}
				</td>
			);
		}
		return marks;
	}

	const handleSearchInput = (value) => {
		setSettings((prev) => ({ ...prev, searchValue: value, page: 1 }));
	};

	const showLastActiveTime = (time) => {
		if (moment.utc().local() < moment.utc(time).local()) {
			return <FormattedMessage id={'rod.admin.time.just_now'} />;
		}

		if (moment.utc(time).local().fromNow() === 'Invalid date') {
			return <FormattedMessage id={'rod.admin.invalid-date'} />;
		} else return <>{moment.utc(time).local().fromNow()}</>;
	};

	const modalOpen = () => {
		setModal(!modal);
	};

	const handleCheckedFilter = (id) => {
		filterSettings[0].list.map((item) => {
			item.checked = item.id === id;
			if (item.id === id) {
				setIsFilterEnable(item.checked);
			}
			return item;
		});
		filterSettings[1]?.list.map((item) => {
			item.checked = item.id === id;
			if (item.id === id) {
				setIsFilterEnable(item.checked);
			}
			return item;
		});

		setFilterSettings([...filterSettings]);
	};

	const handleClearFilter = () => {
		filterSettings.forEach((setting) => {
			setting.list.forEach((item) => {
				if (item.checked) item.checked = false;
			});
		});
		setIsFilterEnable(false);
		setFilterSettings([...filterSettings]);
	};

	const getReportsWithFilterFromModal = (filter) => {
		if (
			filterSettings[0].list[0].checked &&
			!filterSettings[0].list[1].checked
		) {
			setSettings((prev) => ({
				...prev,
				filterStatus: ['pending', 'pending_contracts'],
				page: 1,
			}));
		}

		if (
			!filterSettings[0].list[0].checked &&
			filterSettings[0].list[1].checked
		) {
			setSettings((prev) => ({
				...prev,
				filterStatus: ['payment_moderation'],
				page: 1,
			}));
		}

		if (
			!filterSettings[0].list[0].checked &&
			!filterSettings[0].list[1].checked
		) {
			setSettings((prev) => ({ ...prev, filterStatus: [], page: 1 }));
		}

		modalOpen();
	};

	return (
		<>
			<div className={styles.page__searchAndFilterWrapper}>
				<div className={styles.inputWrapper}>
					<Input
						className={styles.page__input}
						handleOnChange={handleSearchInput}
						initialValue={settings?.searchValue}
						placeholder={'rod.admin.search.placeholder'}
					/>
				</div>
				<img
					src={
						(settings?.filterStatus && settings.filterStatus.length > 0 || filterSettings[1]?.list.some((item) => item.checked))
							? filter_dot_svg
							: filter_svg
					}
					alt="filter"
					className={styles.filterIcon}
					onClick={modalOpen}
				/>
			</div>
			<div className={styles.page}>
				<ListTable
					loading={loading}
					accountsData={accountsData}
					url={url}
					getMark={getMark}
					showLastActiveTime={showLastActiveTime}
				/>
				<ReportsAdminModal
					active={modal}
					setActive={modalOpen}
					filterData={filterSettings}
					handleCheckedFilter={handleCheckedFilter}
					submitFilter={getReportsWithFilterFromModal}
					isFilterEnable={isFilterEnable}
					handleClearFilter={handleClearFilter}
					moderationRequests
				/>

				{!loading && total > 50 && settings && (
					<Pagination
						page={settings.page}
						total={total}
						paginate={(pageNumber) =>
							setSettings((prev) => ({ ...prev, page: pageNumber }))
						}
						items_on_page={50}
					/>
				)}
			</div>
		</>
	);
}

export default compose(withRoot, withAuth, withUI, withRouter)(List);
