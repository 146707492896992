import React, { useContext, useState } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';
import { AtomicBlockUtils } from 'draft-js';

import image from 'images/image.svg';

import styles from './TextEditor.module.css';
import { accounts } from 'services';
import { AuthContext } from 'contexts/AuthContext';
import { FormattedHTMLMessage } from 'react-intl';

const DashboardTextEditor = ({
  setText,
  setImages,
  catalogData,
  errorFromBack,
  maxCountError = true,
  maxCharsCount = 10
}) => {
  const { accountId } = useContext(AuthContext);
  const [editorValue, setEditorValue] = useState(EditorValue.createEmpty());
  const [imageData, setImageData] = useState({});
  const [imageSizeError, setImageSizeError] = useState(false);

  const handleUploadImage = async (file) => {
    setImageSizeError(false);
    if (file.size > 5 * 1024 * 10241) {
      setImageSizeError(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    const imageUrl = URL.createObjectURL(file);
    const { data } = await accounts.uploadCatalogFile(accountId, formData);

    setImageData({
      ...imageData,
      [imageUrl]: data.data.id,
    });

    const contentState = editorValue.getEditorState().getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: imageUrl, });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorValue.getEditorState(),
      entityKey,
      ' '
    );

    setEditorValue(EditorValue.createFromState(newEditorState));
  };

  const openFileDialog = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = () => {
      if (input.files && input.files.length > 0) {
        handleUploadImage(input.files[0]);
      }
    };
    input.click();
  };

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],

    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ],
  };

  const blockRendererFn = (block) => {
    if (block.getType() === 'atomic') {
      const contentState = editorValue.getEditorState().getCurrentContent();
      const entity = contentState.getEntity(block.getEntityAt(0));
      const type = entity.getType();

      if (type === 'IMAGE') {
        return {
          component: ImageComponent,
          editable: false,
        };
      }
    }

    return null;
  };

  const handleBlur = () => {
    let updatedHtml = editorValue.toString('html');
    Object.keys(imageData).forEach((key) => {
      if (updatedHtml.includes(`src="${key}"`)) {
        // TODO: add to planfixurl to repo settings
        updatedHtml = updatedHtml.replace(`<figure><img src="${key}"/></figure>`, `<a href="https://broma.planfix.ru/file/${imageData[key]}">https://broma.planfix.ru/file/${imageData[key]}</a>`);
      }
    })
    setText(updatedHtml);
  };


  const ImageComponent = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src, dataId } = entity.getData();
    return <img src={src} className={dataId} alt="Uploaded" style={{ maxWidth: '100%' }} />;
  };

  const lang = localStorage.getItem('lang');


  return (
    <div>
      <RichTextEditor
        value={editorValue}
        className={`${styles.text_editor} text_editor-main`}
        onChange={setEditorValue}
        onBlur={handleBlur}
        placeholder={
          lang === 'ru'
            ? `дополнительная информация о репертуаре. Ограничение до ${maxCharsCount} символов`
            : `please leave your information about your repertoire here. ${maxCharsCount} symbols maximum`
        }
        toolbarConfig={toolbarConfig}
        blockRendererFn={blockRendererFn}
        customControls={[
          (setValue, getValue, editorState) => (
            <button
              key="custom-image-upload"
              onMouseDown={(e) => {
                e.preventDefault();
                openFileDialog();
              }}
              style={{ marginLeft: '10px', width: '30px', height: '30px' }}
            >

              <img src={image} width="24px" height="24px" alt="mts" />

            </button>
          ),
        ]}
      />
      {maxCountError && !Object.keys(errorFromBack).length && (
        <span className={styles.error}>
          {
            <FormattedHTMLMessage
              id={`rod.field.upload.textarea.error.max_count`}
              values={{
                value: maxCharsCount,
              }}
            />
          }
        </span>
      )}
      {imageSizeError && (
        <span className={styles.error}>
          {
            <FormattedHTMLMessage
              id={`rod.field.upload.error.size-mb`}
              values={{
                size: 5,
              }}
            />
          }
        </span>
      )}
    </div>
  );
};

export default DashboardTextEditor;
