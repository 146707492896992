// Core
import React from 'react';
import classNames from 'classnames';
import { withAuth, withUI } from 'hocs';
import { compose } from 'recompose';

// Styles
import styles from './Auth.module.css';

class Auth extends React.Component {
	static propTypes = {};

	static defaultProps = {};

	static displayName = 'Auth';

	render() {
		const {
			className,
			authContext: { user, isAccountLoading },
			UIContext: { isShowUserMenu },
			withFocusRef,
			...props
		} = this.props;
		const classes = classNames(className, styles['Auth'], {
			'menu-open': isShowUserMenu,
		});

		let name;
		try {
			name = user['title_en'].split(' ').map((name) => name[0]);
		} catch (e) {
			name = 'NA';
		}
		return (
			<div {...props} className={classes} disabled={isAccountLoading ? true : false}>
				<div className={classNames(styles.User)}>{name}</div>
				{isShowUserMenu ? (
					<i className="fa fa-caret-up" />
				) : (
					<i className="fa fa-caret-down" />
				)}
			</div>
		);
	}
}

export default compose(withAuth, withUI)(Auth);
