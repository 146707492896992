export const nextHandler = (props, errors, cloneDeep, personal) => {
	if (typeof props.onChange === 'function' && !Object.keys(errors).length) {
		const clone = cloneDeep(personal);
		const correctedTime = new Date(
			new Date(clone.created_date).getTime() -
				new Date().getTimezoneOffset() * 60000
		).toISOString();

		if (clone.created_date.includes('T')) {
			clone.created_date = correctedTime.slice(
				0,
				clone.created_date.indexOf('T')
			);
		}

		if (clone.genres) {
			const genres = clone.genres.map((item) => (item.code ? item.code : item));
			clone.genres = genres;
		}

		props.onChange(clone);
	}
};
