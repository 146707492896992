// Core
import { FormattedMessage } from 'react-intl';

export const radio1 = [
	{
		text: (
			<FormattedMessage id={'rod.release.create.step.distribution.asap-date'} />
		),
		value: '7-',
		disabled: false,
	},
	{
		text: (
			<FormattedMessage
				id={'rod.release.create.step.distribution.desired-date'}
			/>
		),
		value: '7+',
		disabled: false,
	},
];

export const radio2 = [
	{
		text: (
			<FormattedMessage
				id={'rod.release.create.step.distribution.input_radio3'}
			/>
		),
		value: 'all',
		disabled: false,
	},
	{
		text: (
			<FormattedMessage
				id={'rod.release.create.step.distribution.input_radio4'}
			/>
		),
		value: 'inc',
		disabled: false,
	},
];
