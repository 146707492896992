// Core
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

// UI
import { FormInput } from 'components/Form/FormInput';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import { Check, NoCheck } from 'material-design/svg';

// Utils
import { showError } from 'validators/showError';

// Styles
import s from './Ids.module.css';

const Ids = ({
	additionalRelease,
	changeField,
	changeMask,
	checkIsValidMask,
	errors,
	additionalErrors,
	handleSetId,
	transferRelease,
}) => {
	return (
		<div className={s.flex}>
			<div className={s.item}>
				<div className={s.input}>
					<FormInput
						type="muiInput"
						name={'ean'}
						onChange={() => changeField('ean', additionalRelease)}
						errors={
							additionalErrors.id === additionalRelease.id
								? additionalErrors
								: []
						}
						data={additionalRelease}
						label={'UPC'}
						disabled={additionalRelease.generate_ean ? true : false}
						required
					/>
					{additionalErrors.id === additionalRelease.id &&
						Object.keys(additionalErrors).includes('ean') && (
							<span className={s.Helper}>
								{showError(additionalErrors.ean[0].rule)(
									additionalErrors.ean[0]
								)}
							</span>
						)}
				</div>

				{!transferRelease && (
					<div className={s.auto__wrapper}>
						<div
							className={s.auto}
							onClick={() =>
								handleSetId(
									'generate_ean',
									additionalRelease?.generate_ean,
									additionalRelease
								)
							}
						>
							{additionalRelease?.generate_ean ? (
								<Check className={s.auto__check} />
							) : (
								<NoCheck className={s.auto__noCheck} />
							)}
							<span>
								<FormattedMessage
									id={
										'rod.release.create.step.tracks.additional_release.generate_automatically'
									}
								/>
							</span>
						</div>
					</div>
				)}
			</div>

			<div className={s.item}>
				<div className={s.input}>
					<InputMask
						mask={'**-*****-**********-*'}
						name={'grid'}
						onChange={(e) => changeMask(e, additionalRelease)}
						errors={errors}
						value={additionalRelease.grid ? additionalRelease.grid : ''}
						onBlur={(e) => checkIsValidMask(e, additionalRelease)}
						disabled={additionalRelease.generate_grid ? true : false}
					>
						{() => (
							<TextFieldInput
								variant="standard"
								type="muiInput"
								name={'grid'}
								error={Object.keys(errors).length > 0 && errors.grid}
								value={additionalRelease.grid ? additionalRelease.grid : ''}
								label={'GRID'}
								disabled={additionalRelease.generate_grid ? true : false}
							/>
						)}
					</InputMask>
					{Object.keys(errors).includes('grid') && (
						<span className={s.Helper}>
							{showError(errors.grid[0].rule)(errors.grid[0])}
						</span>
					)}
				</div>

				{!transferRelease && (
					<div className={s.auto__wrapper}>
						<div
							className={s.auto}
							onClick={() =>
								handleSetId(
									'generate_grid',
									additionalRelease?.generate_grid,
									additionalRelease
								)
							}
						>
							{additionalRelease?.generate_grid ? (
								<Check className={s.auto__check} />
							) : (
								<NoCheck className={s.auto__noCheck} />
							)}
							<span>
								<FormattedMessage
									id={
										'rod.release.create.step.tracks.additional_release.generate_automatically'
									}
								/>
							</span>
						</div>
					</div>
				)}
			</div>

			<div className={s.item}>
				<div className={s.input}>
					<FormInput
						type="muiInput"
						name={'catalog_number'}
						onChange={() => changeField('catalog_number', additionalRelease)}
						errors={
							additionalErrors.id === additionalRelease.id
								? additionalErrors
								: []
						}
						data={additionalRelease}
						required={!transferRelease}
						disabled={additionalRelease.generate_catalog_number ? true : false}
						label={
							<FormattedMessage
								id={'rod.release.create.step.release.label.catalog_number'}
							/>
						}
					/>
					{additionalErrors.id === additionalRelease.id &&
						Object.keys(additionalErrors).includes('catalog_number') && (
							<span className={s.Helper}>
								{showError(additionalErrors.catalog_number[0].rule)(
									additionalErrors.catalog_number[0]
								)}
							</span>
						)}
				</div>

				{!transferRelease && (
					<div className={s.auto__wrapper}>
						<div
							className={s.auto}
							onClick={() =>
								handleSetId(
									'generate_catalog_number',
									additionalRelease?.generate_catalog_number,
									additionalRelease
								)
							}
						>
							{additionalRelease.generate_catalog_number ? (
								<Check className={s.auto__check} />
							) : (
								<NoCheck className={s.auto__noCheck} />
							)}
							<span>
								<FormattedMessage
									id={
										'rod.release.create.step.tracks.additional_release.generate_automatically'
									}
								/>
							</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Ids;
