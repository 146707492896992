// Core
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// Utils
import { formatPhoneNumber } from './utils/formatPhoneNumber';

// UI
import { Button } from 'components/Buttons';
import { CodeInput } from 'components/Inputs/CodeInput';
import { Label } from 'components/Labels';

// Styles
import styles from 'forms/auth/Registration/SMSCheckForm/RegisterVerifySMS.module.css';

class RegisterVerifySMS extends PureComponent {
	static propTypes = {
		onSubmit: PropTypes.func,
		onResend: PropTypes.func,
	};

	form = React.createRef();
	code = React.createRef();

	handleResend = (event) => {
		if (this.props.onResend) {
			this.props.onResend();
		}
	};

	handleSubmit = (event) => {
		event.preventDefault();

		if (this.props.onSubmit) {
			this.props.onSubmit({
				code: this.code.current.value,
			});
		}
	};

	render() {
		return (
			<form
				className={styles.Form}
				ref={this.form}
				onSubmit={this.handleSubmit}
			>
				<div className={styles.Title}>
					<Label className={styles.verifySms__header}>
						<FormattedMessage id={'rod.verify_sms.header'} />
					</Label>
					<Label className={styles.verifySms__subheader}>
						<FormattedMessage id={'rod.verify_sms.subheader'} /> <br />{' '}
						{formatPhoneNumber(this.props.phone)}
					</Label>
				</div>

				<div className={styles.Fields}>
					<CodeInput
						register
						className={styles.Input}
						clearAllErrors={this.props.clearAllErrors}
						onResend={this.handleResend}
						forwardRef={this.code}
						errors={this.props.errors.code}
						remainSMSTime={this.props.remainSMSTime}
					/>
				</div>
				<div className={styles.Controls}>
					<Button
						className={styles.Button_send}
						text={<FormattedMessage id={'rod.action.confirm'} />}
						type={'submit'}
						disabled={!(this.code.current && this.code.current.value)}
						primary
					/>
					<Button
						className={styles.Button_resend}
						to={'/registration/sms/nocode'}
						text={<FormattedMessage id={'rod.verify_sms.no_code'} />}
						link
					/>
				</div>
			</form>
		);
	}
}

export default RegisterVerifySMS;
