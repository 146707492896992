// Core
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'recompose';

// Context
import { withUI, withAuth, withRoot } from 'hocs';
import { UIContext } from 'contexts/UIContext';

// Utils
import { setCache } from 'services/cacheHelper/setCache';
import { checkRangeInOneYear } from '../utils/checkRangeInOneYear';
import { getStartEndDatesForOneYear } from '../utils/getStartEndDatesForOneYear';
import { getDateInYYYYMMDD } from '../StatisticByDatesSection/utils/getDateInYYYYMMDD';

// Constants
import { TIME_CONSTANT } from 'constants/timeConstants';

// UI
import StatisticHeader from 'material-design/Statistic/StatisticHeader/StatisticHeader';
import { Pagination } from 'material-design/components';
import { Loading, TopTracksSkeleton } from 'components';
import { FormInput } from 'components/Form/FormInput';
import SortMenu from './SortMenu/SortMenu';
import StatisticByTerritoriesBar from './StatisticByTerritoriesBar/StatisticByTerritoriesBar';
import StatisticFilterModal from '../StatisticFilterModal/StatisticFilterModal';
import NoDataStat from 'components/NoDataStats/NoDataStats';
import { FullScreenModalConfirm } from 'containers/Modal';
import TopTracksTable from './TopTracksTable/TopTracksTable';
import DataLoadingError from '../DataLoadingError/DataLoadingError';

// Icons
import close from 'images/close_button.svg';
import filter_svg from 'images/filter_small.svg';
import filter_dot_svg from 'images/filter_small_dot.svg';

// Styles
import styles from './StatisticByTerritoriesSectionv2.module.css';

const StatisticByTerritoriesSectionv2 = (props) => {
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(
			new Date().getTime() - TIME_CONSTANT * 32
		).toISOString(),
		end_date: new Date(new Date().getTime() - TIME_CONSTANT * 2).toISOString(),
		tracksIds: [],
		outlets: [],
		countries: [],
		per_page: 10,
		sort: 'desc',
		page: 1,
		checkedAllTracks: false,
		trackSearchValue: '',
	});
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [page, setPage] = useState(1);
	const [pageLoading, setPageLoading] = useState(true);
	const [isPaginationPressed, setIsPaginationPressed] = useState(false);
	const [total, setTotal] = useState(0);
	const [helper, setHelper] = useState(false);
	const [isFilterModalActive, setIsFilterModalActive] = useState(false);
	const [mapTotalPages, setMapTotalPages] = useState(0);
	const [isInitialLoading, setIsInitialLoading] = useState(false);
	const chartRef = useRef(null);
	const [amountTitle, setAmountTitle] = useState('');
	const [topTracks, setTopTracks] = useState([]);
	const [outletCodes, setOutletCodes] = useState([]);
	const [countries, setCountries] = useState([]);
	const [initialOutlets, setInitialOutlets] = useState([]);
	const [checkedTopTrack, setCkeckedTopTrack] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState({});
	const [accountOutlets, setAccountOutlets] = useState([]);
	const [isAllTracks, setIsAllTracks] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [isReqLive, setIsReqLive] = useState(false);
	const [isInitialData, setIsInitialData] = useState(true);
	const [accountMap, setAccountMap] = useState([]);
	const [tracksIds, setTracksIds] = useState([]);
	const [mapDataError, setMapDataError] = useState(false);
	const [chartMode, setChartMode] = useState(props.chartMode ?? 'phono');

	const {
		rootContext: {
			getAccountStatisticOutlets,
			getAccountStatisticTopTracks,
			getAccountStatisticAuthorTopTracks,
			getAccountStatisticsMap,
			getAccountStatisticsAuthorMap,
		},
		adminAccountId,
	} = props;

	const { showModal, isCopyrightingStatisticsEnabled } = useContext(UIContext);

	const isAdmin = adminAccountId ? true : false;
	const accountId = adminAccountId
		? adminAccountId
		: props.authContext.accountId;

	const lang = localStorage.getItem('lang');

	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';

	const mapRequest =
		chartMode === 'compo'
			? getAccountStatisticsAuthorMap
			: getAccountStatisticsMap;

	const topTracksRequest =
		chartMode === 'compo'
			? getAccountStatisticAuthorTopTracks
			: getAccountStatisticTopTracks;

	useEffect(() => {
		const isShowedHelper = localStorage.getItem('helper');
		if (isShowedHelper === 'showed') {
			setHelper(false);
		} else {
			setHelper(true);
		}

		getAccountStatisticOutlets().then((res) => {
			res = res.data.data;
			setAccountOutlets(res);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			accountOutlets.length &&
			(tracksIds.length || statisticData.countries.length) &&
			!Object.keys(errors).length
		) {
			setPageLoading(true);
			setLoading(true);
			const outlets =
				statisticData['outlets']?.length > 0
					? statisticData['outlets']
					: accountOutlets;
			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
			const outletsCodes = outlets.map((outlet) => outlet.code);

			//Хешируем ключ - Аутлеты сортируем по алфавиту. Соеденяем масивы данных в строку через * начиная с аутлетов
			const sortOutlets = outletsCodes.sort();
			const topTracksHash = `${sortOutlets.join('*')}*${startEndDates.join(
				'*'
			)}*${isAllTracks ? 'all' : tracksIds.join('*')}*topTracks*${accountId}`;

			setPage(1);
			setIsPaginationPressed(false);
			const countriesList = statisticData.countries.map((item) => item.code);
			topTracksRequest(
				isAdmin,
				accountId,
				page,
				startEndDates[0],
				startEndDates[1],
				outletsCodes,
				10,
				statisticData.checkedAllTracks ? [] : statisticData.tracksIds,
				[],
				countriesList
			).then((res) => {
				res = res.data.data;

				if (isReqLive) {
					setTopTracks(res.recordings);
					setTotal(res.total);
					setPageLoading(false);
				} else {
					setCache('statisticTopTracksCache', topTracksHash, res);

					setTopTracks(res.recordings);
					setTotal(res.total);
					setPageLoading(false);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountOutlets, statisticData, tracksIds, chartMode]);

	useEffect(() => {
		setIsInitialLoading(true);
	}, []);

	useEffect(() => {
		const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
		mapRequest(
			accountId,
			statisticData.outlets.map((item) => item.code),
			startEndDates[0],
			startEndDates[1],
			statisticData.tracksIds,
			statisticData.per_page,
			statisticData.page,
			statisticData.sort,
			statisticData.countries.map((item) => item.code)
		)
			.then(({ data }) => {
				const responseData = data.data;
				setMapTotalPages(responseData.last_page * statisticData.per_page);
				setAccountMap(responseData.global);
				if (responseData.global?.length) {
					let maxAuditions = Number(responseData.global[0].stat_count);
					if (
						statisticData.sort !== 'desc' &&
						responseData.global[responseData.global.length - 1]
					) {
						maxAuditions = Number(
							responseData.global[responseData.global.length - 1].stat_count
						);
					}
					if (maxAuditions <= 10000) {
						setAmountTitle('rod.statistic.chart-amount-units');
					} else if (maxAuditions > 10000) {
						setAmountTitle('rod.statistic.chart-amount-thousands');
					}
				}
			})
			.catch((error) => {
				if (error.response.status === 422) {
					setMapDataError(true);
				}
				console.error(error.response);
			})
			.finally(() => {
				setIsInitialLoading(false);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		statisticData.start_date,
		statisticData.end_date,
		statisticData.tracksIds,
		statisticData.outlets,
		statisticData.countries,
		statisticData.per_page,
		statisticData.sort,
		statisticData.page,
		chartMode,
	]);

	useEffect(() => {
		if (
			accountOutlets.length &&
			isPaginationPressed &&
			statisticData !== null
		) {
			const outlets =
				statisticData['outlets'].length > 0
					? statisticData['outlets']
					: accountOutlets;

			const outletsCodes = outlets.map((outlet) => outlet.code);
			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);

			const countriesList = statisticData.countries.map((item) => item.code);

			getAccountStatisticTopTracks(
				isAdmin,
				accountId,
				page,
				startEndDates[0],
				startEndDates[1],
				outletsCodes,
				10,
				statisticData.checkedAllTracks ? [] : statisticData.tracksIds,
				statisticData.countries.map((item) => item.code),
				[],
				countriesList
			).then((res) => {
				res = res.data.data;

				if (isReqLive) {
					setTopTracks(res.recordings);
					setTotal(res.total);
					setPageLoading(false);
				} else {
					setTopTracks(res.recordings);
					setTotal(res.total);
					setPageLoading(false);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPaginationPressed, page]);

	const closeModal = () => {
		setShowRangeModal(false);
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'per_page': {
				statisticData[field] = e.id;
				statisticData.page = 1;
				setStatisticData({ ...statisticData });
				chartRef.current.scrollIntoView({ behavior: 'smooth' });
				break;
			}
			case 'start_date':
				statisticData.page = 1;
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (
						Date.parse(e) >= Date.parse(MIN_DATE) &&
						Date.parse(e) < Date.parse(statisticData['end_date'])
					) {
						checkRangeInOneYear(statisticData, setShowRangeModal, showModal, e);
						statisticData[field] = e;
						setStatisticData({ ...statisticData });
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - TIME_CONSTANT * 32)
					);
					setStatisticData({ ...statisticData });
				}

				break;
			case 'end_date':
				statisticData.page = 1;
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (
						Date.parse(e) > Date.parse(statisticData['start_date']) &&
						Date.parse(e) <= MAX_DATE
					) {
						checkRangeInOneYear(
							statisticData,
							setShowRangeModal,
							showModal,
							statisticData.start_date,
							e
						);
						statisticData[field] = e;
						setStatisticData({ ...statisticData });
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - TIME_CONSTANT * 2)
					);
					setStatisticData({ ...statisticData });
				}

				break;

			case 'tracks':
				statisticData[field] = e.map((item) => item.title);
				setStatisticData({ ...statisticData });
				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
		if (statisticData['outlets'].length > 0 && isInitialData) {
			setIsInitialData(false);
		} else if (statisticData['outlets'].length === 0 && !isInitialData) {
			setIsInitialData(true);
		}
	};

	const handleCheckedTracks = (tracks, isAll) => {
		if (
			(tracks.length !== tracksIds.length && tracks.length !== 0) ||
			tracks.length === 1
		) {
			setIsAllTracks(isAll);
			setTracksIds(tracks);
		}
	};

	const handleCheckedOutlets = (outlets, initial = false) => {
		const codes = outlets.map((item) => item.code);

		if (outlets.length !== outletCodes.length /* && tracks.length !== 0 */) {
			setOutletCodes(codes);
			statisticData['outlets'] = outlets;
			setStatisticData({ ...statisticData });
		}
		if (initial) {
			setInitialOutlets(outlets);
		}
	};

	const handleCheckedTopTrack = (track) => {
		if (track) {
			setCkeckedTopTrack(track);
			statisticData.tracksIds = [track.id];
			statisticData.checkedAllTracks = false;
			setStatisticData({ ...statisticData, page: 1 });
		} else {
			setCkeckedTopTrack(null);
			statisticData.tracksIds = [];
			setStatisticData({ ...statisticData });
		}
	};

	const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);

	const closeHelper = () => {
		setHelper(false);
		localStorage.setItem('helper', 'showed');
	};

	const perPageVariants = [
		{ id: 5, title: '5' },
		{ id: 10, title: '10' },
		{ id: 20, title: '20' },
		{ id: 40, title: '40' },
		{ id: 50, title: '50' },
	];

	const isFiltersActive = () => {
		if (
			statisticData.outlets.length !== outletCodes.length ||
			statisticData.tracksIds.length ||
			statisticData.countries.length
		) {
			return true;
		}
		return false;
	};

	return (
		<>
			{helper && (
				<div className={styles.helper}>
					<img onClick={closeHelper} src={close} alt="" />
					<span>
						<FormattedHTMLMessage id={'rod.statistic.notification'} />
					</span>
				</div>
			)}

			<StatisticHeader
				isCopyrightingStatisticsEnabled={isCopyrightingStatisticsEnabled}
				chartMode={chartMode}
				setChartMode={setChartMode}
				accountId={accountId}
				statisticData={statisticData}
				accountOutlets={accountOutlets}
				isAdmin={isAdmin}
				trackSearchValue={statisticData.trackSearchValue}
				tracksIds={statisticData.tracksIds}
				isAllTracks={statisticData.checkedAllTracks}
				v2
				statType='byterritory'
			/>

			<div className={styles.statisticByTerritories__mainContent}>
				<div className={styles.inputsWrapper}>
					<div>
						<div className={styles.inputStartDate}>
							<FormInput
								className={styles.date}
								type={'muiInputDate_v3'}
								defaultValue={true}
								name={'start_date'}
								onChange={changeField}
								minDate={Date.parse(MIN_DATE)}
								maxDate={Date.parse(statisticData.end_date)}
								errors={errors}
								data={statisticData}
								label={<FormattedMessage id={'rod.statistic.start-date'} />}
							/>
							{Object.keys(errors).includes('start_date') && (
								<div className={styles.error}>
									<span className={styles.errorText}>
										{errors['start_date'] === 'invalid date' ? (
											<FormattedMessage id={'rod.admin.invalid-date'} />
										) : (
											<FormattedMessage id={'rod.error.statistic.minDate'} />
										)}
										{errors['start_date'] === 'minDate' && MIN_DATE}
									</span>
								</div>
							)}
						</div>
						<div className={styles.inputDate}>
							<FormInput
								className={styles.date}
								type={'muiInputDate_v3'}
								defaultValue={true}
								name={'end_date'}
								onChange={changeField}
								minDate={Date.parse(statisticData.start_date)}
								maxDate={Date.now()}
								errors={errors}
								data={statisticData}
								label={<FormattedMessage id={'rod.statistic.end-date'} />}
							/>
							{Object.keys(errors).includes('end_date') && (
								<div className={styles.error}>
									<span className={styles.errorText}>
										{errors['end_date'] === 'invalid date' ? (
											<FormattedMessage id={'rod.admin.invalid-date'} />
										) : (
											''
										)}
									</span>
								</div>
							)}
						</div>
					</div>
					<div>
						<div
							onClick={() => setIsFilterModalActive(true)}
							className={styles.statistics_filter__item}
						>
							<img
								src={!isFiltersActive() ? filter_svg : filter_dot_svg}
								alt=""
							/>
							<FormattedMessage id={'rod.admin.reports.filters'} />
						</div>
						<SortMenu
							sort={statisticData.sort}
							handleSortChange={(sortValue) => {
								setStatisticData({
									...statisticData,
									sort: sortValue,
									page: 1,
								});
							}}
						/>
					</div>
				</div>

				{!isInitialLoading && mapDataError && <DataLoadingError />}

				{isInitialLoading ? (
					<Loading className={styles.loader} />
				) : accountMap.length === 0 ? (
					<NoDataStat />
				) : (
					accountMap &&
					!!countries.length && (
						<>
							{!mapDataError && (
								<div ref={chartRef} className={styles['statistics_bar-chart']}>
									<div className={styles.statistic__mainContentHeaderInfo}>
										<div
											className={styles.statistic__mainContentHeaderInfo_title}
										>
											<FormattedMessage id={'rod.statistic.chart-title'} />
										</div>
										<div
											className={styles.statistic__mainContentHeaderInfo_amount}
										>
											{amountTitle && (
												<FormattedMessage id={`${amountTitle}`} />
											)}
										</div>
									</div>
									<StatisticByTerritoriesBar
										data={accountMap}
										limit={statisticData.per_page}
										allCountries={countries}
										lang={lang}
										sort={statisticData.sort}
										handleSelectCountry={(country) => {
											statisticData.countries = [country];
											statisticData.page = 1;
											setStatisticData({ ...statisticData });
										}}
										dates={{
											startDate: startEndDates[0],
											endDate: startEndDates[1],
										}}
									/>
									<div className={styles['statistics_bar-chart__controls']}>
										{mapTotalPages > 10 && (
											<Pagination
												page={statisticData.page}
												total={mapTotalPages}
												paginate={(pageNumber) => {
													statisticData.page = pageNumber;
													setIsPaginationPressed(true);
													setStatisticData({ ...statisticData });
												}}
												items_on_page={statisticData.per_page}
											/>
										)}
										<div
											className={styles['statistics_bar-chart__controls-pages']}
										>
											<span>
												<FormattedHTMLMessage id={'rod.statistic.per-page'} />
											</span>
											<FormInput
												type={'MuiSelectCurrencies'}
												name={'per_page'}
												onChange={changeField}
												errors={errors}
												theme="grayscale"
												defaultValue={true}
												data={statisticData}
												label={''}
												items={perPageVariants}
											/>
										</div>
									</div>
								</div>
							)}

							<>
								<div className={styles.topTracksTitle}>
									<span>
										<FormattedHTMLMessage id={'rod.statistic.top_tracks'} />
									</span>
								</div>
								{pageLoading ? (
									<TopTracksSkeleton />
								) : (
									<>
										{topTracks && topTracks.length > 0 && (
											<>
												<TopTracksTable
													topTracks={topTracks}
													handleCheckedTopTrack={handleCheckedTopTrack}
													page={page}
													chartMode={chartMode}
													statisticData={statisticData}
													isAdmin={isAdmin}
													outlets={accountOutlets}
													accountId={accountId}
												/>
												{total > 10 && (
													<Pagination
														page={page}
														total={total}
														paginate={(pageNumber) => {
															setPage(pageNumber);
															setIsPaginationPressed(true);
															setPageLoading(true);
														}}
														items_on_page={10}
													/>
												)}
											</>
										)}
									</>
								)}
							</>
						</>
					)
				)}
				{showRangeModal && !props.main && (
					<FullScreenModalConfirm
						title={<FormattedMessage id={'rod.modal.statistic_title'} />}
						text={<FormattedMessage id={'rod.modal.statistic_text'} />}
						confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
						onClose={closeModal}
					/>
				)}
				<StatisticFilterModal
					lang={lang}
					isAdmin={isAdmin}
					accountId={accountId}
					statisticData={statisticData}
					errors={errors}
					startEndDates={startEndDates}
					active={isFilterModalActive}
					setLoading={setLoading}
					setActive={() => setIsFilterModalActive(false)}
					checkedTopTrack={checkedTopTrack}
					setInitialData={(tracks, outlets, countries) => {
						handleCheckedTracks(tracks, true);
						handleCheckedOutlets(outlets, true);
						setCountries(countries);
					}}
					handleSetFilters={(filtersData, isAllChecked, searchValue) => {
						Object.keys(filtersData).forEach((filterKey) => {
							if (filtersData[filterKey]) {
								statisticData[filterKey] = filtersData[filterKey];
							}
						});
						if (isAllChecked) {
							statisticData.tracksIds = [];
						}
						if (searchValue) {
							statisticData.trackSearchValue = searchValue;
						}

						statisticData.checkedAllTracks = isAllChecked;
						statisticData.page = 1;
						setStatisticData({ ...statisticData });
					}}
					handleClearAllFilters={() => {
						setStatisticData({
							...statisticData,
							outlets: initialOutlets,
							tracksIds: [],
							countries: [],
							page: 1,
						});
					}}
					chartMode={chartMode}
				/>
			</div>
		</>
	);
};

export default compose(
	withUI,
	withAuth,
	withRoot
)(StatisticByTerritoriesSectionv2);
