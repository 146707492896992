// Core
import React, { useRef } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// UI
import { Button } from 'components/Buttons';
import { Input } from 'components/Inputs';
import { Label } from 'components/Labels';
import { Form } from 'components/Form';

// Styles
import styles from './EmailCheckForm.module.css';

const EmailCheckForm = ({ onSubmit, clearAllErrors, errors }) => {
	const formRef = useRef(null);
	const codeRef = useRef(null);

	const handleSubmit = (event) => {
		event.preventDefault();

		if (onSubmit) {
			onSubmit({
				code: codeRef.current.value,
			});
		}
	};

	const showError = (error) => {
		switch (error.rule) {
			case 'invalid':
				return <FormattedHTMLMessage id={`rod.error.code_invalid`} />;

			default:
				return <FormattedHTMLMessage id={`rod.error.${error.rule}`} />;
		}
	};

	const onChange = (text) => {
		if (clearAllErrors) {
			clearAllErrors();
		}
	};

	return (
		<Form className={styles.Form} ref={formRef} onSubmit={handleSubmit}>
			<div className={styles.Title__wrapper}>
				<Label className={styles.Title__header}>
					<FormattedMessage id={'rod.verify_email.header'} />
				</Label>
				<Label className={styles.Title__subheader}>
					<FormattedHTMLMessage id={'rod.verify_email.subheader'} />
				</Label>
				<Label className={styles.Title__action}>
					<FormattedHTMLMessage id={'rod.verify_email.action'} />
				</Label>
			</div>

			<div className={styles.Fields}>
				<FormattedMessage id={'rod.field.verify_email_code'}>
					{(placeholder) => (
						<Input
							onChange={(s) => {
								onChange(s);
							}}
							className={styles.Input}
							showError={showError}
							errors={errors.code}
							clearInputIfHasError={true}
							forwardRef={codeRef}
							placeholder={placeholder}
						/>
					)}
				</FormattedMessage>
			</div>
			<div className={styles.Controls}>
				<Button
					className={styles.Button_send}
					text={<FormattedMessage id={'rod.action.confirm'} />}
					type={'submit'}
					disabled={!codeRef?.current?.value}
					primary
				/>
				<Button
					className={styles.Button_resend}
					to={'/registration/email/nocode'}
					text={<FormattedMessage id={'rod.verify_email.no_code'} />}
					link
				/>
			</div>
		</Form>
	);
};

EmailCheckForm.propTypes = {
	onSubmit: PropTypes.func,
	clearAllErrors: PropTypes.func,
	errors: PropTypes.object,
};

export default EmailCheckForm;
