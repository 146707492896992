// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { withAuth, withRoot, withUI } from 'hocs';
import moment from 'moment';
import { format } from 'date-fns';
import { compose } from 'recompose';

// Utils
import { getAuthor } from './utils/getAuthor';
import { getHistoryStatuses } from './utils/getHistoryStatuses';
import { typeLocalization } from './utils/typeLocalization';

// UI
import { Loading } from 'components';
import { Pagination } from 'material-design/components';
import Thead from './Thead/Thead';

// Styles
import styles from './StatusHistory.module.css';

function StatusHistory(props) {
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [historyData, setHistoryData] = useState([]);
	const [page, setPage] = useState(1);

	const lang = localStorage.getItem('lang');
	moment.locale(lang);

	const {
		rootContext: { getAdminSteps, getSignupsSteps },
		id,
		isSignup,
	} = props;

	useEffect(() => {
		switch (isSignup) {
			case true:
				getSignupsSteps(id, page)
					.then((res) => {
						setHistoryData(res.data);
						setTotal(res.total);
						setLoading(false);
					})
					.catch((err) => console.log.log(err));
				break;
			case false:
				getAdminSteps(id, page)
					.then((res) => {
						setHistoryData(res.data);
						setTotal(res.total);
						setLoading(false);
					})
					.catch((err) => console.log.log(err));
				break;
			default:
				break;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	return (
		<>
			<div className={styles.page}>
				<table className={styles.Table}>
					<Thead />
					{loading ? (
						<tr className={styles.loaderWrapper}>
							<td colspan="4">
								<Loading className={styles.loader} />
							</td>
						</tr>
					) : (
						<>
							{historyData?.length === 0 && (
								<>
									<tr>
										<td colspan="4" />
									</tr>
									<tr className={styles.noData}>
										<td colspan="4">
											<FormattedMessage id={'rod.admin.no-data'} />
										</td>
									</tr>
									<tr>
										<td colspan="4" />
									</tr>
								</>
							)}
							{historyData?.length > 0 &&
								historyData.map((item) => (
									<tr>
										<td className="firstCol">
											{(!isSignup ||
												item.action === 'signup.status_change') && (
												<>{getHistoryStatuses(item)}</>
											)}
											{isSignup &&
												[
													'signup.phone.manual_verification',
													'signup.email.manual_verification',
												].includes(item.action) && (
													<>
														<FormattedMessage id={`rod.admin.${item.action}`} />
													</>
												)}
										</td>
										<td>
											{format(new Date(item.updated_at), 'HH:mm dd.MM.yyyy')}
										</td>
										<td>{typeLocalization(item.type)}</td>
										<td className={styles.author}>{getAuthor(item)}</td>
									</tr>
								))}
						</>
					)}
				</table>
				{!loading && total > 20 && (
					<Pagination
						page={page}
						total={total}
						paginate={(pageNumber) => setPage(pageNumber)}
						items_on_page={20}
					/>
				)}
			</div>
		</>
	);
}

export default compose(withRoot, withAuth, withUI, withRouter)(StatusHistory);
