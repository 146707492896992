// Core
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import cloneDeep from 'lodash/cloneDeep';

// Components
import AuthorForm from './AuthorForm/AuthorForm';
import { BottomNavi } from 'components';

// Hooks
import { withUI, withRoot } from 'hocs';

// Services & Helpers
import { accounts } from 'services';
import changeField from 'material-design/helper/helper';

// Icons
import { ReactComponent as Selected } from 'images/selected.svg';
import play_button from 'images/play_button.svg';
import stop_button from 'images/stop_button.svg';
import close_darkgrey from 'images/close_darkgrey.svg';
import arrow_grey from 'images/arrow_grey.svg';

// Styles
import s from './Authors.module.css';

function Authors(props) {
	const [lastSavePerson, setLastSavePerson] = useState({});
	const [personal, setPersonal] = useState([]);
	const [selectPerson, setSelectPerson] = useState({});
	const [tmpInputValue, setTmpInputValue] = useState({});
	const [errors, setErrors] = useState({});
	const [toogleMenu, setToogleMenu] = useState('');
	const [takeByLastPerson, setTakeByLastPerson] = useState(false);
	const [disabledNextBtn, setDisabledNextBtn] = useState(false);
	const [modal, setModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [audio] = useState(new Audio());

	const releaseId = localStorage.getItem('releaseId');
	const isH11Edit = window.location.href.includes('/edit/');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));
	const h11EditId = releaseEdit?.id;
	const history = useHistory();

	const {
		UIContext: {
			play,
			setContributor,
			handlePlay,
			audioId,
			handlePlayStop,
			setIsBottomNaviShow,
		},
	} = props;

	const handleForm = (index) =>
		toogleMenu === index ? setToogleMenu('') : setToogleMenu(index);

	const handleOnChange = (index) => (field) => (e) => {
		setErrors({});

		const selectedData = changeField({
			index,
			field,
			e,
			selectPerson,
			tmpInputValue,
		});

		selectedData.contributors.forEach((item) => {
			if (item.ownership && item.ownership.toString().length > 4) {
				if (item.publisher_share && +item.publisher_share > 0) {
					item.ownership = Number(item.ownership).toFixed(2);
				} else {
					item.ownership = Number(item.ownership).toFixed(1);
				}
			}
			if (item.publisher_share && item.publisher_share.toString().length > 5) {
				item.publisher_share = Number(item.publisher_share).toFixed(2);
			}
		});

		const data = selectedData.contributors.map((item) => {
			if (typeof item.ownership === 'string' && item.ownership.length > 0) {
				item.ownership = Number(item.ownership);
			}
			if (
				typeof item.publisher_share === 'string' &&
				item.publisher_share.length > 0
			) {
				item.publisher_share = Number(item.publisher_share);
			}
			if (item.publisher_share < 0) {
				item.publisher_share = 0.01;
			}
			return item;
		});

		personal.contributors = data;
		selectedData.contributors = data;
		personal[toogleMenu].contributors = selectedData.contributors;
		setSelectPerson({ ...selectedData });
		setPersonal([...personal]);
	};

	const handleOnFocus = (index) => (field) => (e) => {
		const value = e.target.value;
		setTmpInputValue({ index, field, value });
	};

	const handleOnBlur = (contributorsIndex) => (field) => (e) => {
		const value = e.target.value;
		if (value === '') {
			selectPerson.contributors[contributorsIndex][field] = tmpInputValue.value;
			personal.contributors = selectPerson.contributors;
			setSelectPerson({ ...selectPerson });
			setPersonal(personal);
		}
		if (tmpInputValue.field === 'publisher_share' && value === '') {
			const contributors = selectPerson.contributors.filter(
				(item, index) => index !== contributorsIndex
			);

			const maxValue = contributors.reduce(
				(acc, item) => acc + item.ownership + +item.publisher_share,
				0
			);

			let data = 100 - maxValue - tmpInputValue.value;
			if (+data < 0) {
				data = 0.1;
			}

			selectPerson.contributors[contributorsIndex].ownership = +data.toFixed(2);
			setSelectPerson({ ...selectPerson });
			setPersonal(personal);
			setTmpInputValue({});
		}
		if (tmpInputValue.field === 'ownership' && value === '') {
			const contributors = selectPerson.contributors.filter(
				(item, index) => index !== contributorsIndex
			);

			const maxValue = contributors.reduce(
				(acc, item) => acc + item.ownership + item.publisher_share,
				0
			);

			const data = 100 - maxValue - tmpInputValue.value;

			selectPerson.contributors[
				contributorsIndex
			].publisher_share = +data.toFixed(2);
			setSelectPerson({ ...selectPerson });
			setPersonal(personal);
			setTmpInputValue({});
		}
	};

	const nextHandler = () => {
		if (typeof props.onChange === 'function') {
			audio.currentTime = 0;
			audio.pause();
			props.onChange(personal);
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			if (h11EditId && isH11Edit) {
				history.push(`/release/${h11EditId}/edit/tracks`);
			} else {
				history.push(`/release/${releaseId}/tracks`);
			}
			audio.currentTime = 0;
			audio.pause();
			props.handlePrevStep();
		}
	};

	const toogleLastPerson = (boolean) => {
		if (boolean) {
			const lastPerson = cloneDeep(personal[toogleMenu - 1].contributors);
			const RemovingPropertyPerson = lastPerson.map(person => {
				const newPerson = Object.assign({}, person);
				delete newPerson.id;
				delete newPerson.pivot;
				return newPerson;
			});

			setSelectPerson({ ...selectPerson, contributors: RemovingPropertyPerson });
		} else {
			setSelectPerson(personal[toogleMenu]);
		}
		setTakeByLastPerson(boolean);
	};

	const isValid = (toCheck, showErr = false) => {
		let errorCount = 0;
		const newError = {};
		newError.title = [];
		newError.roles = [];
		newError.ownership = [];
		newError.publisher = [];
		newError.publisher_share = [];
		let sum = 0;

		toCheck.forEach((item, index) => {
			if (!item.title) {
				newError.title.push({ type: 'required', key: index });
				errorCount++;
			}

			if (item.title.length > 190) {
				newError.title.push({ type: 'max_length', key: index, value: 190 });
				errorCount++;
			}

			if (!item.roles.length) {
				newError.roles.push(index);
				errorCount++;
			}

			if (item.roles.length) {
				const requiredRoles = toCheck.map((item) =>
					item.roles.filter((itemRole) => {
						switch (itemRole.logo ? itemRole.logo : itemRole) {
							case 'A':
								return itemRole;
							case 'C':
								return itemRole;
							case 'CA':
								return itemRole;
							default:
								break;
						}
						return false;
					})
				);
				if (!requiredRoles.flat().length) {
					newError.roles.push('required_roles');
					errorCount++;
				}
			}

			if (item.title && Number(item.ownership) === 0) {
				newError.ownership.push(index);
				errorCount++;
			}

			if (item.publisher_show && !item.publisher) {
				newError.publisher.push({ type: 'required', key: index });
				errorCount++;
			}

			if (item.publisher && item.publisher.length > 190) {
				newError.publisher.push({ type: 'max_length', key: index, value: 190 });
				errorCount++;
			}

			if (item.publisher_show && Number(item.publisher_share) === 0) {
				newError.publisher_share.push(index);
				errorCount++;
			}

			if (item.publisher_share > 0) {
				sum += +item.publisher_share;
			}

			if (item.ownership > 0) {
				sum += +item.ownership;
			}
		});

		if (!errorCount && sum >= 99.94 && sum <= 100.06) {
			setErrors({});
			return false;
		} else {
			if (showErr) {
				if (sum <= 99.93 || sum >= 100.07) {
					newError.sum = '';
				}
				setErrors(newError);
			}
			return true;
		}
	};

	const recordingSave = (withModal = false, type = 'author', idx) => {
		if (isValid(selectPerson.contributors, true)) return;
		const clone = cloneDeep(selectPerson.contributors);

		clone.forEach((item) => {
			if (item.roles.length > 0) {
				item.roles = item.roles.map((role) => role.logo);
			}
		});
		setIsLoading(true);
		accounts
			.uploadRecordingCompositionInfo(
				h11EditId && isH11Edit ? h11EditId : releaseId,
				selectPerson.id,
				{
					contributors: clone,
				}
			)
			.then((res) => {

				if (withModal) {
					setModal(true);

					const { contributors } = selectPerson;
					selectPerson.done = true;
					selectPerson.contributors = res.data.data.map((item) => {
						const existItem = contributors?.find((contributor) => contributor.title === item.title);
						if (existItem && existItem.publisher_show) {
							return {
								...item,
								publisher_show: true,
							}
						}
						return item;
					})

					setSelectPerson({
						...selectPerson,
						contributors: res.data.data.map((item) => {
							const existItem = contributors?.find((contributor) => contributor.title === item.title);
							if (existItem && existItem.publisher_show) {
								return {
									...item,
									publisher_show: true,
								}
							}
							return item;
						}),
					});

					const currentContributor = res.data.data[idx];
					setContributor({ ...currentContributor, type });
					setModal(true);
					setPersonal(personal);
				} else {
					const { contributors } = selectPerson;
					
					selectPerson.done = true;
					selectPerson.contributors = res.data.data;
					setSelectPerson({
						...selectPerson,
						contributors: res.data.data.map((item) => {
							const existItem = contributors?.find((contributor) => contributor.title === item.title);
							if (existItem && existItem.publisher_show) {
								return {
									...item,
									publisher_show: true,
								}
							}
							return item;
						}),
					});
					personal[toogleMenu] = {
						...selectPerson,
						contributors: res.data.data.map((item) => {
							const existItem = contributors?.find((contributor) => contributor.title === item.title);
							if (existItem && existItem.publisher_show) {
								return {
									...item,
									publisher_show: true,
								}
							}
							return item;
						}),
					};
					setPersonal(personal);
					if (personal[toogleMenu + 1]) {
						if (!isValid(personal[toogleMenu + 1].contributors)) {
							setToogleMenu('');
						} else {
							setToogleMenu(toogleMenu + 1);
						}
					} else {
						setToogleMenu('');
						setDisabledNextBtn(false);
					}
				}
			})
			.catch((error) => {
				console.error('Error', error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		accounts
			.getRecordingList(h11EditId && isH11Edit ? h11EditId : releaseId)
			.then((res) => {
				res = res.data.data;
				setPersonal(res);
				let first = true;
				let countValid = 0;
				for (let i = 0; i < res.length; i++) {
					if (!res[i]?.contributors) {
						res[i].contributors = [
							{
								title: '',
								society_id: null,
								roles: [],
								ownership: 100,
								publisher: '',
								publisher_share: 0,
								controlled_by_submitter: true,
							},
						];
					} else {
						res[i].contributors.forEach((item) => {
							if (item.publisher) {
								item.publisher_show = true;
							} else {
								item.publisher_show = false;
							}
						});
					}

					if (!isValid(res[i].contributors)) {
						res[i].done = true;
						countValid++;
					} else if (first) {
						setToogleMenu(i);
						first = false;
					}
					if (countValid === res.length) {
						setToogleMenu('');
					}
				}
				setPersonal([...res]);
			})
			.catch((error) => {
				console.info('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTakeByLastPerson(false);
		if (toogleMenu === '') {
			return;
		}
		if (toogleMenu - 1 >= 0) {
			if (!isValid(personal[toogleMenu - 1].contributors)) {
				setLastSavePerson(personal[toogleMenu - 1]);
			} else {
				setLastSavePerson({});
			}
		} else {
			setLastSavePerson({});
		}

		setSelectPerson(personal[toogleMenu]);
		setErrors({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toogleMenu]);

	useEffect(() => {
		let isValidPersonal = true;

		for (const item of personal) {
			if (!item.done || isValid(item.contributors, false)) {
				isValidPersonal = false;
				break;
			}
		}

		setDisabledNextBtn(!isValidPersonal);
	}, [personal, selectPerson, disabledNextBtn]);

	return (
		<>
			<div className={s.page}>
				<div className={s.main__wrapper}>
					<div
						className={
							toogleMenu !== '' ? s.main : `${s.main} ${s.main__notForm}`
						}
					>
						<span className={s.title}>
							<FormattedMessage id={'rod.release.create.step.author.title'} />
						</span>
						<ul className={s.table}>
							{personal.length !== 0 &&
								personal.map((item, index) => (
									<li
										key={index}
										className={
											index === toogleMenu
												? `${s.table__item} ${s.table__itemSel}`
												: s.table__item
										}
									>
										{item.done ? (
											<Selected className={s.tableItem__sel} />
										) : (
											<div
												className={`${s.tableItem__inselected} ${s.tableItem__sel}`}
											></div>
										)}
										<span>{index + 1}</span>
										<span>{item.title}</span>
										{audioId === item.file?.id && play ? (
											<img
												src={stop_button}
												className={s.play}
												onClick={handlePlayStop}
												alt=""
											/>
										) : (
											<img
												src={play_button}
												className={s.play}
												onClick={() =>
													handlePlay(
														index,
														item.file,
														item.title ?? item.file.client_name
													)
												}
												alt=""
											/>
										)}
										<img
											src={toogleMenu === index ? close_darkgrey : arrow_grey}
											className={s.toogleMenu}
											onClick={() => handleForm(index)}
											alt=""
										/>
										<span></span>
									</li>
								))}
						</ul>
					</div>

					{toogleMenu !== '' && (
						<AuthorForm
							lastSavePerson={lastSavePerson}
							errors={errors}
							handleOnChange={handleOnChange}
							handleOnFocus={handleOnFocus}
							handleOnBlur={handleOnBlur}
							tmpInputValue={tmpInputValue}
							handleForm={handleForm}
							toogleMenu={toogleMenu}
							selectPerson={selectPerson}
							setSelectPerson={setSelectPerson}
							recordingSave={recordingSave}
							takeByLastPerson={takeByLastPerson}
							setTakeByLastPerson={setTakeByLastPerson}
							toogleLastPerson={toogleLastPerson}
							modal={modal}
							setModal={setModal}
							onSave={(type, idx) => {
								recordingSave(true, type, idx);
							}}
							isLoading={isLoading}
						/>
					)}
				</div>
			</div>
			<BottomNavi
				showPrevBtn
				disabled={disabledNextBtn}
				back={backHandler}
				next={nextHandler}
			/>
		</>
	);
}
export default compose(withUI, withRoot)(Authors);
