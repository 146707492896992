// Core
import { getGlobalId } from 'App';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

// UI
import { withForwardRef } from 'hocs';

// Icons
import eyeImg from 'images/eye.svg';
import eyeInactiveImg from 'images/eye_inactive.svg';
import eyeStrikeImg from 'images/eye_strike.svg';
import eyeRedImg from 'images/eye_red.svg';
import eyeInactiveRedImg from 'images/eye_inactive_red.svg';
import eyeStrikeRedImg from 'images/eye_strike_red.svg';

// Styles
import styles from './Input.module.css';

class Input extends PureComponent {
	static propTypes = {
		focus: PropTypes.bool,
		type: PropTypes.string,
		errors: PropTypes.any,
		onChange: PropTypes.func,
		showError: PropTypes.func,
		name: PropTypes.string,
		showRequired: PropTypes.bool,
		numberNonNegative: PropTypes.bool,
		inputMaxLength: PropTypes.number,
	};

	static defaultProps = {
		type: 'text',
		numberNonNegative: false,
		errors: [],
		inputMaxLength: 0,
		onChange: () => {},
	};

	constructor(props) {
		super(props);
		this.id = getGlobalId('input');
		this.ref = React.createRef();
		this.state = {
			showPassword: false,
			isFocused: false,
			value: '',
		};
	}

	componentDidMount() {
		if (this.props.focus) {
			this.ref.current.focus();
		}

		if (this.ref.current && this.ref.current.type === 'number') {
			this.ref.current.addEventListener('wheel', this.handleWheel, {
				passive: false,
			});
		}
	}

	componentWillUnmount() {
		if (this.ref.current && this.ref.current.type === 'number') {
			this.ref.current.removeEventListener('wheel', this.handleWheel);
		}
	}

	handleShowPassword = (e) => {
		e.preventDefault();

		this.setState({ showPassword: !this.state.showPassword });
	};

	handleWheel = (e) => {
		e.preventDefault();
	};

	showError = (error) => {
		if (!error) {
			return;
		}
		if (this.props.clearInputIfHasError && this.ref.current) {
			this.ref.current.value = '';
		}
		if (typeof this.props.showError === 'function') {
			return this.props.showError(error);
		} else {
			if (this.props.name) {
				return (
					<span>
						<FormattedMessage
							id={`rod.error.${this.props.name}_${error.rule}`}
							values={{ num: error.value }}
						/>
					</span>
				);
			} else {
				return (
					<span>
						<FormattedMessage
							id={`rod.error.${error.rule}`}
							values={{ num: error.value }}
						/>
					</span>
				);
			}
		}
	};

	handleOnKeyDown = (e) => {
		const correctKeys = '1234567890';

		if (
			this.props.type === 'number' &&
			correctKeys.indexOf(e.key) === -1 &&
			e.key !== 'ArrowLeft' &&
			e.key !== 'ArrowRight' &&
			e.key !== 'Backspace' &&
			e.key !== 'Tab' &&
			e.key !== 'Control' &&
			e.key !== 'v' &&
			e.key !== 'м' &&
			e.key !== 'x' &&
			e.key !== 'ч' &&
			e.key !== 'c'
		) {
			e.preventDefault();
		}
	};

	handleChange = (e) => {
		this.setState({ value: e.target.value });

		if (this.props.inputMaxLength > 0) {
			if (this.ref.current.value.length > this.props.inputMaxLength) {
				this.ref.current.value = this.ref.current.value.substring(
					0,
					this.props.inputMaxLength
				);
			}
		}

		if (this.props.type === 'number' && this.props.numberNonNegative) {
			if (this.ref.current.value < 0) {
				this.ref.current.value = 0;
			}

			if (this.ref.current.value === '-0') {
				this.ref.current.value = 0;
			}
		}

		if (this.props.raw) {
			this.props.onChange(e);
		} else {
			this.props.onChange(this.ref.current.value);
		}
	};

	blur = () => {
		if (typeof this.props.onBlur === 'function') {
			this.props.onBlur();
		}

		this.setState({ isFocused: false });
	};

	focus = () => {
		if (typeof this.props.onFocus === 'function') {
			this.props.onFocus();
		}

		this.setState({ isFocused: true });
	};

	initialStage = (forwardRef) => {
		this.setState({ value: forwardRef.current.value });
	};

	render() {
		const {
			errors,
			placeholder,
			helper,
			label,
			className,
			forwardRef,
			tip,
			showError,
			showRequired,
			langContext,
			withFocusRef,
			register,
			disabled,
			...props
		} = this.props;

		const classes = classnames(
			className,
			errors.length > 0 && styles.Error,
			styles.Input
		);

		if (forwardRef) {
			this.ref = forwardRef;
		}

		return (
			<div className={classes}>
				<input
					{...props}
					id={this.id}
					disabled={disabled}
					required
					onChange={this.handleChange}
					onFocus={this.focus}
					onBlur={this.blur}
					onKeyDown={this.handleOnKeyDown}
					autoComplete="off"
					ref={this.ref}
					placeholder={`${placeholder}${
						showRequired && props.required ? '*' : ''
					}`}
					type={this.state.showPassword ? 'string' : props.type}
					className={
						props.type === 'password'
							? styles.inputPassword
							: styles.inputPlaceholder
					}
				/>
				<span className={styles.Highlight} />
				<span className={styles.Bar} />

				{props.type === 'password' && (
					<div
						className={styles.ShowPassword}
						onClick={
							this.state.value.length ? this.handleShowPassword : () => {}
						}
					>
						{!this.state.value.length && (
							<img
								src={errors.length > 0 ? eyeInactiveRedImg : eyeInactiveImg}
								alt=""
							/>
						)}
						{this.state.value.length && this.state.showPassword ? (
							<img
								src={errors.length > 0 ? eyeStrikeRedImg : eyeStrikeImg}
								alt=""
								style={{ cursor: 'pointer' }}
							/>
						) : (
							this.state.value.length > 0 && (
								<img
									src={errors.length > 0 ? eyeRedImg : eyeImg}
									alt=""
									style={{ cursor: 'pointer' }}
								/>
							)
						)}
					</div>
				)}
				{helper && errors.length === 0 && (
					<div className={styles.Helper}> {helper} </div>
				)}
				{errors.length > 0 && (
					<div className={styles.Error}>
						{typeof errors === 'string'
							? this.showError(errors)
							: typeof errors !== 'string' && register
							? errors.map((error, index) => (
									<span key={index}>{this.showError(error)}</span>
							  ))
							: this.showError(errors[0])}
					</div>
				)}

				{tip && this.state.isFocused && <div className={styles.Tip}>{tip}</div>}
			</div>
		);
	}
}

export default compose(withForwardRef)(Input);
