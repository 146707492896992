// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

// Utils
import { radioItems } from '../../utils/radioItems';
import { parseCodes } from '../utils/parseCodes';

// UI
import { FormInput } from 'components/Form/FormInput';
import { SocietiesAutosuggestInput } from 'components/Inputs/SocietiesAutosuggestInput';
import { Button } from 'components/Buttons';

// Styles
import s from './CmoForm.module.css';

const CmoForm = ({
	selectPersonId,
	cmoSociety,
	cmoSocieties,
	setCmoSocieties,
	setExpanded,
	setDisabledAddBtn,
	type = 'release',
}) => {
	const {
		territories,
		searchAuthorSocieties,
		createAuthorSociety,
		updateAuthorSociety,
		createCompositionAuthorSociety,
		updateCompositionAuthorSociety,
	} = useContext(RootContext);
	const { contributor } = useContext(UIContext);
	const accountId = localStorage.getItem('accountId');
	const releaseId = localStorage.getItem('releaseId');
	const compositionId = localStorage.getItem('compositionId');

	const [cmo, setCmo] = useState(cmoSociety);
	// eslint-disable-next-line no-unused-vars
	const [errors, setErrors] = useState({});
	const [selectedTerritories, setSelectedTerritories] = useState([]);
	const [exclusiveTerritories, setExclusiveTerritories] = useState([]);
	const [disabled, setDisabled] = useState(false);

	// eslint-disable-next-line no-unused-vars
	const [value, setValue] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [suggestions, setSuggestions] = useState([]);
	const [selectedSociety, setSelectedSociety] = useState({});

	const handleSocietyChange = (society) => {
		setSelectedSociety(society);
		let updatedCmo = {
			...cmo,
			name: society.title,
			external_society_id: society.id,
			territories:
				society.operation_territories &&
				society.operation_territories.length > 0
					? 'selected-territories'
					: '17',
		};

		if (updatedCmo.territories === 'selected-territories') {
			const operationTerritories = territories.filter((territory) =>
				society.operation_territories.includes(territory.id)
			);
			setSelectedTerritories(operationTerritories);
		}
		setCmo(updatedCmo);

		const updatedIndex = cmoSocieties.findIndex(
			(society) => society.id === updatedCmo.id
		);
		if (updatedIndex !== -1) {
			const updatedSocieties = [...cmoSocieties];
			updatedSocieties[updatedIndex] = updatedCmo;
			setCmoSocieties(updatedSocieties);
		}
	};

	const changeField = (field) => (e) => {
		cmo[field] = e;
		setCmo({ ...cmo });
	};

	const changeRadio = (field) => (value) => {
		cmo[field] = value;
		setCmo({ ...cmo });
	};

	const onSubmit = async () => {
		const payload = {
			id: typeof cmo.id === 'string' ? null : cmo.id,
			account_id: accountId,
			contributor_id: contributor.id,
			type: contributor.type,
			name: cmo.name,
			external_society_id: String(cmo.external_society_id),
			contract_date: cmo.contract_date,
			country_codes:
				cmo.territories === '17'
					? '17'
					: cmo.territories === 'selected-territories'
					? selectedTerritories.map((item) => item.id).join(';')
					: '',
			exclusive_territories:
				cmo.territories === 'exclusive-territories'
					? exclusiveTerritories.map((item) => item.id).join(';')
					: '',
		};
		setDisabled(true);
		try {
			if (typeof cmo.id === 'string') {
				await createSociety(payload);
			} else {
				await updateSociety(payload);
			}
			setDisabled(false);
		} catch (error) {
			console.error('Error while submit author society data', error);
			setDisabled(false);
		}
	};

	const createSociety = async (payload) => {
		try {
			const { data } =
				type === 'release'
					? await createAuthorSociety(
							releaseId,
							selectPersonId,
							contributor.id,
							payload
					  )
					: await createCompositionAuthorSociety(
							compositionId,
							contributor.id,
							payload
					  );

			cmoSocieties.pop();
			setCmo(data.data);
			setCmoSocieties([...cmoSocieties, data.data]);
			setExpanded(null);
			setDisabledAddBtn(false);
		} catch (error) {
			console.log(error);
		}
	};

	const updateSociety = async (payload) => {
		try {
			const { data } =
				type === 'release'
					? await updateAuthorSociety(
							releaseId,
							selectPersonId,
							contributor.id,
							payload
					  )
					: await updateCompositionAuthorSociety(
							compositionId,
							contributor.id,
							payload
					  );

			const updatedCmo = { ...data.data };
			// Find the index of the updated society in cmoSocieties array
			const updatedIndex = cmoSocieties.findIndex(
				(society) => society.id === updatedCmo.id
			);
			if (updatedIndex !== -1) {
				// Replace the old society object with the updated one
				const updatedSocieties = [...cmoSocieties];
				updatedSocieties[updatedIndex] = updatedCmo;
				// Update the state of societies
				setCmoSocieties(updatedSocieties);
			}
			setExpanded(null);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (cmo && typeof cmo.id === 'number') {
			searchAuthorSocieties(cmo.name)
				.then((res) => {
					if (res.data.data) {
						const selectedSociety = res.data.data[0];
						setSelectedSociety(selectedSociety);
						const operationTerritories = [...territories].filter((territory) =>
							selectedSociety.operation_territories.includes(territory.id)
						);
						setSelectedTerritories(operationTerritories);
						if (cmo['exclusive_territories']) {
							const exclusiveTerritoriesString = JSON.parse(
								cmo['exclusive_territories']
							);
							const exclusiveTerritoriesIds = parseCodes(
								exclusiveTerritoriesString
							);
							const exclusiveTerritories = [
								...territories,
							].filter((territory) =>
								exclusiveTerritoriesIds.includes(territory.id)
							);
							setExclusiveTerritories(exclusiveTerritories);
						}
					}
				})
				.catch((error) => console.error('Error:', error));

			if (cmo.country_codes && JSON.parse(cmo.country_codes)) {
				const formatCodes = JSON.parse(cmo.country_codes);
				if (formatCodes === '17') {
					setCmo({ ...cmo, territories: '17' });
				} else {
					setCmo({
						...cmo,
						territories: 'selected-territories',
					});
				}
			} else {
				setCmo({
					...cmo,
					territories: 'exclusive-territories',
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			!cmo.name ||
			!cmo.contract_date ||
			(cmo.territories === 'selected-territories' &&
				!selectedTerritories.length) ||
			(cmo.territories === 'exclusive-territories' &&
				!exclusiveTerritories.length)
		) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [
		disabled,
		cmo.contract_date,
		cmo.name,
		cmo.territories,
		exclusiveTerritories,
		selectedTerritories,
	]);

	useEffect(() => {
		if (value.length >= 2) {
			searchAuthorSocieties(value)
				.then((data) => setSuggestions(data))
				.catch((error) => console.error('Error:', error));
		} else {
			setSuggestions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<div className={s.formContainer}>
			<FormattedMessage id={'rod.release.cmo.settings.cmo.name'}>
				{(placeholder) => (
					<SocietiesAutosuggestInput
						placeholder={placeholder}
						value={selectedSociety}
						onChange={handleSocietyChange}
						getSuggestions={searchAuthorSocieties}
					/>
				)}
			</FormattedMessage>
			<div
				className={s.dateBlock}
				style={cmo.name ? { marginTop: 0 } : { marginTop: '16px' }}
			>
				<span className={s.title}>
					<FormattedMessage
						id={'rod.release.cmo.settings.contract_date.title'}
					/>
				</span>
				<FormattedMessage id={'rod.release.cmo.settings.cmo.contract_date'}>
					{(placeholder) => (
						<FormInput
							type={'datePicker'}
							placeholder={placeholder}
							name={'contract_date'}
							onChange={changeField}
							errors={errors}
							data={cmo}
							className={s.dateBlockInput}
						/>
					)}
				</FormattedMessage>
			</div>
			<span className={s.title}>
				<FormattedMessage
					id={'rod.release.cmo.settings.service_territories.title'}
				/>
			</span>
			<div className={s.radioGroup}>
				<FormInput
					type={'radio-territories'}
					name={'territories'}
					onChange={changeRadio}
					errors={errors}
					data={cmo}
					items={radioItems}
					selectedTerritories={selectedTerritories}
					exclusiveTerritories={exclusiveTerritories}
					onSelectTerritory={setSelectedTerritories}
					onExcluseTerritory={setExclusiveTerritories}
				/>
			</div>
			<Button
				className={s.saveBtn}
				variant={'primary'}
				onClick={onSubmit}
				disabled={disabled}
			>
				<FormattedMessage id={'rod.release.cmo.save'} />
			</Button>
		</div>
	);
};

export default CmoForm;
