// Core
import { isBefore, parseISO } from 'date-fns';

export const isDateInPast = (dateString) => {
	const providedDate = parseISO(dateString);
	const currentDate = new Date();

	if (isBefore(providedDate, currentDate)) {
		return true;
	}

	return false;
};
