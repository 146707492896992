// Core
import React, { useContext, useEffect, useState } from 'react';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { RegisterVerifySMS as VerifyForm } from 'forms/index';

// Styles
import styles from './SMSCheck.module.css';

const SMSCheck = () => {
	const {
		getSmsRetryTime,
		resendSMS,
		verifySMS,
		regData,
		user,
		errors,
		clearAllErrors,
	} = useContext(AuthContext);
	const { putResendSMSTime } = useContext(UIContext);

	const [remainSMSTime, setRemainSMSTime] = useState('');
	const [stateErrors, setStateErrors] = useState({});

	const setRemainTime = (res) => {
		const retryTime = res.data?.data?.retry;
		const lastTime = new Date(retryTime);
		const currTime = new Date();
		const remainMs = lastTime - currTime;
		if (remainMs > 0) {
			setRemainSMSTime(remainMs);
		}
	};

	useEffect(() => {
		getSmsRetryTime('register')
			.then((res) => {
				setRemainTime(res);
			})
			.catch((err) => {
				console.error('Error of get sms retry request', err);
				setStateErrors(err?.response?.data?.errors);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resendSmsRequest = () => {
		putResendSMSTime();
		clearAllErrors();
		resendSMS()
			.then((res) => {
				setRemainTime(res);
			})
			.catch((err) => {
				if (err.status && err.status === 'validation_error') {
					if (err?.errors?.code[0]?.rule === 'retry') {
						const lastTime = new Date(err.errors.code[0].value);
						const currTime = new Date();
						const remainMs = lastTime - currTime;
						if (remainMs > 0) {
							setRemainSMSTime(remainMs);
						}
					}
					setStateErrors(err.errors);
				}
			});
	};

	return (
		<div className={styles.page}>
			<VerifyForm
				onSubmit={verifySMS}
				clearAllErrors={clearAllErrors}
				onResend={resendSmsRequest}
				phone={regData.phone || user.phone}
				errors={{ ...errors, ...stateErrors }}
				remainSMSTime={remainSMSTime}
			/>
		</div>
	);
};

export default SMSCheck;
