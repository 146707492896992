// Core
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Utils
import { getLink } from './utils/getLink';
import { checkShowTab } from './utils/checkShowTab';

// UI
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import {
	RepertoireReleaseInfo,
	RepertoireReleaseNotices,
	RepertoireReleaseModeration,
	RepertoireReleaseShipments,
} from '../RepertoireReleaseTab';
import { StatisticByDatesSection } from 'material-design/Statistic/StatisticCategory';
import error from 'images/error-2.svg';

// Styles
import { HeaderButton } from '../HeaderButton.styled';
import styles from '../RepertoireRelease.module.css';

const Tabs = ({
	props,
	defaultTab,
	isReleaseReady,
	isReleaseInQueue,
	deliveryData,
	moderationStatus,
	tracks,
	getInfo,
	hasPriveleges,
}) => {
	const history = useHistory();
	const { id: releaseId } = useParams();
	const { isFeatureStatisticsReleaseTab } = useContext(UIContext);

	const recordingIds = tracks.map((track) => track.asset_id);

	return (
		<TabsUnstyled defaultValue={defaultTab} className={styles.repertoireSong}>
			<TabsListUnstyled className={styles.repertoireSong__header}>
				<HeaderButton
					onClick={() => getLink('info', history, releaseId)}
					className={styles.headerButton}
				>
					<FormattedMessage id={'rod.release.tab.main'} />
				</HeaderButton>

				{!isReleaseReady ? (
					<HeaderButton
						onClick={() => getLink('notices', history, releaseId)}
						className={styles.headerButton}
					>
						<FormattedMessage id={'rod.release.tab.not_ready'} />
					</HeaderButton>
				) : (
					checkShowTab(moderationStatus, isReleaseInQueue) &&
					hasPriveleges && (
						<HeaderButton
							onClick={() => getLink('moderation', history, releaseId)}
							className={styles.headerButton}
						>
							<div className={styles.tabContent}>
								{moderationStatus === 'rejected' && <img src={error} alt="" />}
								<FormattedMessage id={'rod.release.tab.moderation'} />
							</div>
						</HeaderButton>
					)
				)}

				{deliveryData && deliveryData.length > 0 && (
					<HeaderButton
						onClick={() => getLink('deliveries', history, releaseId)}
						className={styles.headerButton}
					>
						<FormattedMessage id={'rod.release.info.deliveries'} />
					</HeaderButton>
				)}

				{deliveryData &&
					deliveryData.length > 0 &&
					isFeatureStatisticsReleaseTab && (
						<HeaderButton
							onClick={() =>
								getLink(
									'stat',
									history,
									releaseId,
									isFeatureStatisticsReleaseTab
								)
							}
							className={styles.headerButton}
						>
							<FormattedMessage id={'rod.release.info.stat'} />
						</HeaderButton>
					)}
			</TabsListUnstyled>

			<div className={styles.repertoireSong__main}>
				<TabPanelUnstyled value={0}>
					<RepertoireReleaseInfo getInfo={getInfo} tracks={tracks} />
				</TabPanelUnstyled>

				{!isReleaseReady ? (
					<TabPanelUnstyled value={1}>
						<RepertoireReleaseNotices
							props={props}
							releaseId={releaseId}
							getInfo={getInfo}
						/>
					</TabPanelUnstyled>
				) : (
					checkShowTab(moderationStatus, isReleaseInQueue) &&
					hasPriveleges && (
						<TabPanelUnstyled value={1}>
							<RepertoireReleaseModeration
								props={props}
								releaseId={releaseId}
								getInfo={getInfo}
								isReleaseInQueue={isReleaseInQueue}
							/>
						</TabPanelUnstyled>
					)
				)}

				<TabPanelUnstyled
					value={
						!isReleaseReady || checkShowTab(moderationStatus, isReleaseInQueue)
							? 2
							: 1
					}
				>
					<RepertoireReleaseShipments
						props={props}
						releaseId={releaseId}
						getInfo={getInfo}
					/>
				</TabPanelUnstyled>

				{deliveryData &&
					deliveryData.length > 0 &&
					isFeatureStatisticsReleaseTab && (
						<TabPanelUnstyled
							value={
								!isReleaseReady ||
								checkShowTab(moderationStatus, isReleaseInQueue)
									? 3
									: 2
							}
						>
							<StatisticByDatesSection
								release
								releaseId={releaseId}
								recordingIds={recordingIds}
								releaseTracks={tracks}
							/>
						</TabPanelUnstyled>
					)}
			</div>
		</TabsUnstyled>
	);
};

export default Tabs;
