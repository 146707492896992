// Core
import api from '../interceptor';
import axios from 'axios';

export default class Admin {
	//++
	static setContractSigns = ({ accountId, contractType, payload }) =>
		api.post(`/mvp/users/${accountId}/contracts/${contractType}/sign`, payload);

	static listSignups = () => {
		return api.get(`/mvp/signups`);
	};

	static getSignup = (signupId) => {
		return api.get(`/mvp/signups/${signupId}`);
	};

	static extendSignupCode = (signupId) => {
		return api.post(`/mvp/signups/${signupId}/extend`);
	};

	static switchToPhysical = (accountId) => {
		return api.post(`/mvp/users/${accountId}/physical-sign`);
	};

	static getUsersAdminV2 = (accountId) => {
		// return api.get(`/test-rod-api.rod.broma16.com/api/admin/users`);
		return api.get(`/admin/users`);
	};
	static getAccountsAdminV2 = (searchText = '') => {
		// return api.get(`/test-rod-api.rod.broma16.com/api/admin/users`);
		return api.get(`/admin/accounts/all?search=${searchText}`);
	};

	static setAccountStep = (accountId, data) => {
		return api.post(`/qa/account/${accountId}/step`, data);
	};

	static getMvpUserData = (id) => {
		return api.get(`/mvp/users/${id}`);
	};

	static setAllowCreateAdditionalAccountsFlag = (id) => {
		return api.put(`/user/${id}/flag/additional_accounts`);
	};

	static deleteAllowCreateAdditionalAccountsFlag = (id) => {
		return api.delete(`/user/${id}/flag/additional_accounts`);
	};

	static getSignupsUserData = (signupId) => {
		return api.get(`/signups/${signupId}`);
	};

	static deleteSignupsUserData = (signupId) => {
		return api.delete(`/signups/${signupId}`);
	};

	static deleteNotApprovedAcc = (accountId) => {
		return api.delete(`/admin/accounts/${accountId}`);
	};

	static signupEmailVerification = (id) => {
		return api.post(`/signups/${id}/manual-email-verification`);
	};

	static signupSmsVerification = (id) => {
		return api.post(`/signups/${id}/manual-sms-verification`);
	};

	static getAdminSteps = (id, page) => {
		return api.get(`/admin/steps?account_id=${id}&page=${page}&limit=20`);
	};

	static getSignupsSteps = (id, page) => {
		return api.get(`/signups/${id}/log?page=${page}&limit=20`);
	};

	static getAdminStatisticService = (
		outlets = ['apple', 'spotify', 'ok', 'vk', 'zvuk', 'yandex'],
		dateFrom,
		dateTo,
		isReady,
		page
	) => {
		return api.get(
			`/stat/v1/admin/statistics/status?outlets=[${outlets}]&dateFrom=${dateFrom}&dateTo=${dateTo}&limit=50&ready=${isReady}&page=${page}`
		);
	};

	static getAdminAccountDrafts = (
		acccountId,
		search = '',
		step = '',
		page = 1
	) => {
		return api.get(
			`/admin/${acccountId}/assets/drafts/all?step=${step}&search=${search}&page=${page}&limit=10`
		);
	};

	static getUnprocessedDrafts = (page) =>
		api.get(`/admin/drafts/unprocessed?pages=${page}&limit=10`);

	static getAccountStatisticReports = (
		accountId,
		page = 1,
		search = '',
		step = ''
	) => {
		return api.get(
			`/stat/v1/statistics/accounts/${accountId}/reports?page=${page}`
		);
	};

	static getAdminStatisticReports = (
		accountId,
		page = 1,
		search = '',
		step = ''
	) => {
		return api.get(
			`/stat/v1/statistics/admin/reports?accountId=${accountId}&page=${page}`
		);
	};

	static getAdminNotifications = (page = 1, search = '', lang) => {
		return api.get(
			`/admin/notification?lang=${lang}&page=${page}&search=${search}&limit=15`
		);
	};
	static getAdminNotificationRead = (id) => {
		return api.get(`/admin/notification/${id}`);
	};

	static createAdminNotifications = (
		lang,
		heading,
		text,
		massMailing,
		recipients
	) => {
		const data = {
			language: lang,
			heading: heading,
			text: text,
			mass_mailing: massMailing,
			accounts_id: recipients.toString(),
			interface: 1,
			mail: 1,
		};

		return api.post(`/admin/notification`, data);
	};

	static getAdminArticles = (page = 1, search = '', lang) => {
		return api.get(
			`/admin/faq/articles?page=${page}&language=${lang}&search=${search}&limit=10`
		);
	};

	static getAdminArticlesBySlugLang = (lang, slug) => {
		return api.get(`/admin/faq/articles?slug=${slug}&language=${lang}`);
	};

	static getAdminArticle = (articleId) => {
		return api.get(`/admin/faq/articles/${articleId}`);
	};

	static updateAdminArticle = (articleId, file) => {
		return api.put(`/admin/faq/article/${articleId}`, file);
	};

	static uploadAdminArticlePicture = (/* articleId, */ filePic) => {
		// ${articleId}/
		return api.post(`/admin/faq/article/picture/`, filePic);
	};

	static createAdminArticle = (file) => {
		return api.post(`/admin/faq/article`, file);
	};

	static deleteAdminArticle = (articleId) => {
		return api.delete(`/admin/faq/article/${articleId}`);
	};

	static getAdminArticlesRubrics = (lang, page) => {
		return api.get(
			`/admin/faq/sections?language=${lang}&page=${page}&limit=10`
		);
	};

	static getAdminArticlesSectionsAll = () => {
		return api.get(`/admin/faq/sections`);
	};
	static getAdminArticlesSectionsTranslate = (slug, lang) => {
		return api.get(`/admin/faq/sections?language=${lang}&slug=${slug}`);
	};

	static postAdminRubricsList = (rubricsIDs) => {
		return api.post(`/admin/faq/sections/sort`, rubricsIDs);
	};

	static addAdminArticlesRubric = (rubric) => {
		return api.post(`/admin/faq/section`, rubric);
	};
	static updateAdminArticlesRubric = (sectionId, rubric) => {
		return api.put(`/admin/faq/section/${sectionId}`, rubric);
	};

	static deleteAdminArticlesRubric = (sectionId) => {
		return api.delete(`/admin/faq/section/${sectionId}`);
	};

	static deleteAdminArticlePicFile = (fileId) => {
		return api.delete(`/admin/faq/article/picture/${fileId}`);
	};

	static getAdminReports = (page) => {
		return api.get(`/admin/reports?page=${page}&limit=15`);
	};

	static getAllAdminReports = () => {
		return api.get(`/admin/reports`);
	};

	static getAdminReportsByFilter = (
		distributionId,
		page,
		filter = false,
		search = ''
	) => {
		return api.get(
			`/admin/reports/${distributionId}?page=${page}&limit=15&filter=${filter}&search=${search}`
		);
	};

	static getAdminReportsNextDate = () => {
		return api.get(`/report/date/next`);
	};

	static postAdminReportsNextDate = (date) => {
		return api.post(`/admin/report/date/next?date=${date}`);
	};

	static editAdminReportsNextDate = (id, date) => {
		return api.put(`/admin/report/date/next/${id}?date=${date}`);
	};

	static postReportArchiveFile = (file, reportId, config) => {
		return api.post(`/admin/report/file?reportId=${reportId}`, file, config);
	};

	static postReportXlsFile = (file) => {
		return api.post(`/admin/reports/excel`, file);
	};

	// ===1===
	static postReportArchiveRequest = (heading_en, heading, date_publication) => {
		return api.post(
			`/admin/report?heading_en=${heading_en}&heading=${heading}&date_publication=${date_publication}`
		);
	};

	//===2===
	static postReportArchiveFileS3 = (
		file_name_with_ext,
		file_size,
		reportId
	) => {
		return api.put(
			`/admin/report/file/s3/?client_name=${file_name_with_ext}&file_size=${file_size}&reportId=${reportId}`
		);
	};

	//===3===
	static putFileToS3 = async (url, file, config) => {
		const myHeaders = new Headers({
			'Content-Type': 'application/zip',
		});
		const apiToS3 = axios.create({
			headers: myHeaders,
			withCredentials: true,
		});

		return apiToS3.put(`${url}`, file, config);
	};

	static editReportArchiveFile = (report_file_id, file) => {
		return api.post(`/admin/report/${report_file_id}/file`, file);
	};
	static editReportArchiveRequest = (report_file_id, date_publication) => {
		return api.put(
			`/admin/report/${report_file_id}?date_publication=${date_publication}`
		);
	};
	static removeReport = (id) => {
		return api.delete(`/admin/report/${id}`);
	};

	static reloadReport = (identifier) => {
		return api.post(`/admin/report/reload/${identifier}`);
	};
	static editManualReportData = (id, data) => {
		return api.put(`/admin/report/${id}`, data);
	};
	static editManualReportFile = (id, file) => {
		return api.post(`/admin/report/${id}/file`, file);
	};
	static startReportParsing = () => {
		return api.post(`/admin/reports/parsing`);
	};
	static handleExportRelease = () => {
		return api.get(`/admin/export/s3`);
	};

	//=== admin distributions ====

	static getAdminDistributions = (page) => {
		return api.get(`/admin/distribution?page=${page}&limit=15`);
	};

	static getAllAdminDistributions = () => {
		return api.get(`/admin/distribution`);
	};

	static getAdminDistributionNextDate = (distributionId) => {
		return api.get(`/admin/distribution/${distributionId}/date/next`);
	};

	static putAdminDistributionNextDate = (distributionId, date) => {
		return api.put(
			`/admin/distribution/${distributionId}/date/next?next_date=${date}`
		);
	};

	static getAdminDistributionReports = (distributionId, page) => {
		return api.get(`/admin/reports/${distributionId}?page=${page}&limit=15`);
	};

	static getAllAdminDistributionReports = (distributionId) => {
		return api.get(`/admin/reports/${distributionId}`);
	};

	static getAdminDistributionReportsByFilter = (
		distributionId,
		page,
		filter
	) => {
		return api.get(
			`/admin/reports/${distributionId}?page=${page}&limit=15&filter=true&search=${filter}`
		);
	};

	//==distribution - update zip archive
	static putAdminDistributionReportArchive = (distributionId, data) => {
		return api.put(
			`/admin/distribution/${distributionId}`,
			data
			// `/admin/distribution/${distributionId}?heading_en=${data.heading_en}&heading=${data.heading}`
		);
	};
	//==distribution - upload xls file
	static postAdminDistributionReportFile = (id, data) => {
		return api.post(`/admin/report/${id}`, data);
	};

	//==distribution - request to edit archived xls file (Запрос на редактирование содержания отчета:)
	static putAdminDistributionEditContentRequest = (
		distributionId,
		report_files_id,
		date_publication,
		data
	) => {
		// /admin/report/{distributionId}/{report_files_id}?date_publication=2023-02-07%2008%3A35%3A01&account_id=28&royalties=15&currency=%E2%82%BD
		return api.put(
			`/admin/report/${distributionId}/${report_files_id}?date_publication=${date_publication}`,
			data
		);
	};

	//============================
	static getAdminReportsDistributions = (page = 1, search = '') => {
		return api.get(
			`/admin/distribution?page=${page}&search=${search}&limit=15`
		);
	};
	static createAdminReportsDistributions = (data, date_publication) => {
		return api.post(
			`/admin/distribution?heading_en=${data.heading_en}&heading=${data.heading}&date_publication=${date_publication}`
		);
	};
	static removeAdminReportsDistributions = (distributionId) => {
		return api.delete(`/admin/distribution/${distributionId}`);
	};
	static isAdminRedirectLocale = (id, isRedirectLocale) => {
		return api.post(
			`/admin/account/${id}/redirect?redirect=${isRedirectLocale}`
		);
	};


	static getFlagsWithId = (user_id = null, account_id = null) => {
		return api.get(`admin/flags`, {
			params: {
				user_id,
				account_id,
			}
		})
	}

	static addFlag = (typeId, flagId, type) => {
		if (type === 'user') {
			return api.put(`user/${typeId}/flag/${flagId}`)
		}
		return api.post(`/admin/accounts/${typeId}/flag/${flagId}`);
	}

	static removeFlag = (typeId, flagId, type) => {
		if (type === 'user') {
			return api.delete(`user/${typeId}/flag/${flagId}`)
		}
		return api.delete(`/admin/accounts/${typeId}/flag/${flagId}`)
	}
}
