export const getLink = (
	name,
	history,
	releaseId,
	isFeatureStatisticsReleaseTab
) => {
	switch (name) {
		case 'info':
			return history.push(`/repertoire/releases/${releaseId}/info`);
		case 'notices':
			return history.push(`/repertoire/releases/${releaseId}/notices`);
		case 'moderation':
			return history.push(`/repertoire/releases/${releaseId}/moderation`);
		case 'deliveries':
			return history.push(`/repertoire/releases/${releaseId}/deliveries`);
		case 'stat':
			if (!isFeatureStatisticsReleaseTab) {
				return history.push(`/repertoire/releases/${releaseId}/info`);
			}
			return history.push(`/repertoire/releases/${releaseId}/statistics`);

		default:
			history.push(`/repertoire/releases/${releaseId}/info`);
			break;
	}
};
