// Core
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

// UI
import { FormInput } from 'components/Form/FormInput';

// Icons
import close_grey from 'images/close_grey.svg';
import plus_in_square from 'images/plus_in_square.svg';

// Styles
import s from './AuthorsShares.module.css';
import CmoBtn from 'material-design/ReleaseCreate/steps/Authors/AuthorForm/CmoBtn/CmoBtn';

const AuthorForm = ({
	errors,
	handleOnChange,
	handleOnFocus,
	handleOnBlur,
	tmpInputValue,
	selectPerson,
	setSelectPerson,
	checkControlledBySubmitter,
	setModal,
	onSave,
	isLoading,
}) => {
	const { contributors } = selectPerson;

	const addAuthor = () => {
		const newContributors = [
			...contributors,
			{
				title: '',
				society_id: null,
				roles: [],
				ownership: 0.1,
				publisher: '',
				publisher_share: 0,
				controlled_by_submitter: true,
			},
		];

		const controlledBySubmitterAuthors = contributors.filter(
			(item) => item.controlled_by_submitter === true
		);
		checkControlledBySubmitter(controlledBySubmitterAuthors);
		setSelectPerson({ ...selectPerson, contributors: newContributors });
	};

	const deleteAuthor = (id) => {
		const newContributors = contributors.filter(
			(contributor, index) => index !== id
		);
		setSelectPerson({ ...selectPerson, contributors: newContributors });
		
	};

	const addPublisher = (index) => {
		contributors[index].publisher_show = true;
		const newNumber = (contributors[index].ownership / 2).toFixed(2);

		contributors[index].publisher_share = newNumber;
		contributors[index].ownership = newNumber;
		setSelectPerson({ ...selectPerson });
	};

	const deletePublisher = (index) => {
		contributors[index].publisher_show = false;
		var newNumber = (
			Number(contributors[index].publisher_share) +
			Number(contributors[index].ownership)
		).toFixed(2);
		contributors[index].publisher = '';
		contributors[index].publisher_share = 0;

		newNumber = newNumber > 100 ? 100 : newNumber;
		contributors[index].ownership = newNumber;
		setSelectPerson({
			...selectPerson,
		});
	};

	return (
		<div className={s.form}>
			<div className={s.authorsAndShares}>
				{selectPerson?.contributors &&
					selectPerson?.contributors.map((contributor, index) => (
						<div key={index} className={s.author__item}>
							<div className={s.grid}>
								<div className={s.input}>
									<FormInput
										type="muiInput"
										name={'title'}
										onChange={handleOnChange(index)}
										errors={errors}
										data={contributors[index]}
										label={
											<FormattedMessage
												id={
													'rod.release.create.step.recording_info.label.author'
												}
												values={{ id: index + 1 }}
											/>
										}
										required={index === 0}
									/>
									{Object.keys(errors).includes('title') &&
										errors['title'].map(
											(item) =>
												item.key === index &&
												(item.type === 'max_length' ? (
													<span className={s.Helper}>
														<FormattedMessage
															id={`rod.error.max_length`}
															values={{
																num: item.value,
															}}
														/>
													</span>
												) : (
													<span className={s.Helper}>
														<FormattedMessage
															id={`rod.release_create.error.required`}
														/>
													</span>
												))
										)}
								</div>

								<div className={s.input}>
									<FormInput
										type={'muiAutocompleteMultiple'}
										name={'roles'}
										onChange={handleOnChange(index)}
										errors={errors}
										data={contributors[index]}
										label={
											<FormattedMessage
												id={
													'rod.release.create.step.recording_info.label.author_role'
												}
												values={{ id: index + 1 }}
											/>
										}
									/>

									{Object.keys(errors).includes('roles') &&
										errors['roles'].includes(index) && (
											<span className={s.Helper}>
												{
													<FormattedMessage
														id={`rod.release_create.error.required`}
													/>
												}
											</span>
										)}
								</div>

								<div className={`${s.info} ${s.title__info} ${s.authorData}`}>
									<FormInput
										type={'muiSlider'}
										name={'ownership'}
										onChange={handleOnChange(index)}
										onFocus={handleOnFocus(index)}
										onBlur={handleOnBlur(index)}
										tmpInputValue={tmpInputValue}
										errors={errors}
										data={contributors[index]}
										labelSlider={
											<FormattedMessage
												id={
													'rod.release.create.step.recording_info.label.author_share'
												}
												values={{ id: index + 1 }}
											/>
										}
										width={92}
										required
										bind={selectPerson?.contributors[index]?.publisher_show}
									/>
									{Object.keys(errors).includes('ownership') &&
										errors['ownership'].includes(index) && (
											<span className={s.Helper}>
												{
													<FormattedMessage
														id={`rod.release_create.error.required`}
													/>
												}
											</span>
										)}

								</div>
								<CmoBtn
									selectPerson={selectPerson}
									index={index}
									onSave={onSave}
									deleteContributor={deleteAuthor}
									setModal={setModal}
									isLoading={isLoading}
									type="author"
								/>
							</div>
							{contributors[index]?.publisher_show && (
								<div className={s.publisher_item}>
									<div className={s.publisher_author}>
										<FormInput
											type="muiInput"
											name={`publisher`}
											onChange={handleOnChange(index)}
											errors={errors}
											data={contributors[index]}
											label={
												<FormattedMessage
													id={'rod.authors_shares.author_publisher_id'}
													values={{ id: index + 1 }}
												/>
											}
										/>
										{Object.keys(errors).includes('publisher') &&
											errors['publisher'].map(
												(item) =>
													item.key === index &&
													(item.type === 'max_length' ? (
														<span className={s.Helper}>
															<FormattedMessage
																id={`rod.error.max_length`}
																values={{
																	num: item.value,
																}}
															/>
														</span>
													) : (
														<span className={s.Helper}>
															<FormattedMessage
																id={`rod.release_create.error.required`}
															/>
														</span>
													))
											)}
									</div>
									<div className={s.input}>
										<FormInput
											type={'muiSlider'}
											name={`publisher_share`}
											onChange={handleOnChange(index)}
											onFocus={handleOnFocus(index)}
											onBlur={handleOnBlur(index)}
											tmpInputValue={tmpInputValue}
											errors={errors}
											data={contributors[index]}
											labelSlider={
												<FormattedMessage
													id={'rod.authors_shares.publisher_share_id'}
													values={{ id: index + 1 }}
												/>
											}
											width={128}
											bind={selectPerson?.contributors[index]?.publisher_show}
										/>
										{Object.keys(errors).includes('publisher_share') &&
											errors['publisher_share'].includes(index) && (
												<span className={s.Helper}>
													{
														<FormattedMessage
															id={`rod.release_create.error.required`}
														/>
													}
												</span>
											)}
									</div>
									<CmoBtn
										selectPerson={selectPerson}
										index={index}
										onSave={onSave}
										deleteContributor={deletePublisher}
										setModal={setModal}
										isLoading={isLoading}
										type="publisher"
									/>
								</div>
							)}
							<div className={s.addPublisher}>
								{!contributors[index]?.publisher_show && (
									<div
										className={s.button__addPublisher}
										onClick={() => addPublisher(index)}
									>
										<img src={plus_in_square} alt="" />
										<FormattedHTMLMessage
											id={
												'rod.release.create.step.recording_info.label.add_publisher'
											}
										/>
									</div>
								)}
								<FormInput
									checked={contributors[index]}
									className={s.author__checkbox}
									type={'muiCheckbox'}
									name={'controlled_by_submitter'}
									onChange={handleOnChange(index)}
									errors={errors}
									data={contributors[index]}
									label={
										<FormattedMessage id={'rod.authors_shares.my_shares'} />
									}
								/>
							</div>
						</div>
					))}
			</div>

			<div className={s.form__buttons}>
				<div className={s.form__buttonWrapper} onClick={addAuthor}>
					<img src={plus_in_square} alt="" />
					<FormattedHTMLMessage
						id={'rod.release.create.step.recording_info.label.author_add'}
					/>
				</div>
			</div>
		</div>
	);
};

export default AuthorForm;
