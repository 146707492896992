// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DOMPurify from 'dompurify';
import { getHelpMessage } from 'messages/helper';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// Dropdown data
import { dropdownItems } from './dropdownItems';

// UI
import { Button } from 'components/Buttons/Button';
import { FormInput } from 'components/Form/FormInput';
import { SettingsLayout } from 'pages/accountSettings/SettingsCategory/SettingsLayout';

// Styles
import styles from './Company.module.css';

const Company = (props) => {
	const lang = localStorage.getItem('lang');

	const [errors, setErrors] = useState({});
	const [personal, setPersonal] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const [isChangedSmth, setIsChangedSmth] = useState(false);

	useEffect(() => {}, [lang]);
	useEffect(() => {
		setPersonal({ sex: 'male', ...props.data });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		rootContext: { countries },
		UIContext: { showModal },
	} = props;

	const message = DOMPurify.sanitize(
		...getHelpMessage(lang, 'personal_info_Company')
	);

	useEffect(() => {
		setErrors(props.errors);
		setIsEdit(Object.keys(props.errors).length > 0);
		setIsChangedSmth(Object.keys(props.errors).length > 0);
	}, [props.errors]);

	const changeField = (field) => (value) => {
		if (!isChangedSmth) {
			setIsChangedSmth(true);
		}

		setPersonal((prev) => ({ ...prev, [field]: value }));
	};

	const saveHandler = (e) => {
		e.preventDefault();

		setIsChangedSmth(false);
		props.saveHandler(personal);
	};

	const handleEditToggle = () => {
		setIsEdit(!isEdit);
	};

	return (
		<>
			{personal && (
				<SettingsLayout
					isEdit={isEdit}
					handleEditToggle={handleEditToggle}
					showModal={showModal}
					message={message}
					title="rod.account.company.title"
				>
					<div>
						<FormattedMessage id={'rod.field.company.name'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage id={'rod.field.company.name.helper'} />
									}
									name={'title'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.company.signer'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage id={'rod.field.company.signer.helper'} />
									}
									name={'behalf'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.company.signer_short_name'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage
											id={'rod.field.company.signer_short_name.helper'}
										/>
									}
									name={'behalf_short'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.company.signer_position'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'signature_position'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.company.signer_rights'}>
							{(placeholder) => (
								<FormInput
									type={'dropdown'}
									placeholder={placeholder}
									name={'acting_on'}
									defaultEmpty
									onChange={changeField}
									errors={errors}
									required
									data={personal}
									items={dropdownItems}
									getItem={(item) => <FormattedMessage id={item.lexeme} />}
									getListItem={(item) => <FormattedMessage id={item.lexeme} />}
									getItemValue={(item) => item.keyword}
								/>
							)}
						</FormattedMessage>
						{personal.acting_on === 'attorney' && (
							<FormattedMessage id={'rod.field.company.attorney'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'attorney'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
						)}

						{isEdit && (
							<div className={styles.save_btn}>
								<Button
									text={<FormattedMessage id={'rod.action.save'} />}
									variant={'primary'}
									onClick={saveHandler}
									disabled={!isEdit || !isChangedSmth}
									style={{
										pointerEvents: isChangedSmth ? 'all' : 'none',
									}}
								/>
							</div>
						)}
					</div>
					<div>
						<FormattedMessage id={'rod.field.company.registration_date'}>
							{(placeholder) => (
								<FormInput
									type="date"
									name="company_registration_date"
									placeholder={placeholder}
									onChange={changeField}
									errors={errors}
									data={personal}
									isEdit={isEdit}
									hideCross={true}
									required
								/>
							)}
						</FormattedMessage>
						<div>
							<FormattedMessage id={'rod.field.company.registration_place'}>
								{(placeholder) => (
									<FormInput
										type="country"
										countries={countries}
										placeholder={placeholder}
										name={'country_id'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
										className={styles.country}
									/>
								)}
							</FormattedMessage>
						</div>

						<FormattedMessage
							id={'rod.admin.account.personal_data.company_reg_number'}
						>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'company_registration_number'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.company.tax_number'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'itin'}
									onChange={changeField}
									errors={errors}
									data={personal}
									required={
										window.location.href.includes('.ru') ||
										window.location.href.includes('broma16ru-stage')
											? true
											: false
									}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.company.tax_number2'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'company_vat_number'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
					</div>
				</SettingsLayout>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(Company);
