// Core
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import lscache from 'lscache';

// Services
import { withUI, withRoot } from 'hocs';
import { compose } from 'recompose';
import { setCache } from 'services/cacheHelper/setCache';
import { accounts } from 'services';

// UI
import Header from './Header/Header';
import { BottomNavi } from 'components';
import { AddRoleContractsBanner } from 'material-design/components/Banners/AddRoleContractsBanner';
import Information from './Information/Information';
import Recordings from './Recordings/Recordings';

// Styles
import styles from './Review.module.css';

const Review = (props) => {
	const accountId = localStorage.getItem('accountId');
	const lang = localStorage.getItem('lang');

	const [data, setData] = useState([]);
	const [moderationContract, setModerationContract] = useState(true);
	const [contractType, setContractType] = useState([]);
	const [genresArr, setGenresArr] = useState([]);

	const match = useRouteMatch();
	const compositionId = match.params.id;

	const {
		UIContext: {
			showBackNavi,
			hideBackNavi,
			isShowServicesRepertoireBanner,
			setIsBottomNaviShow,
		},
		rootContext: { getCompositionData, postAdditionalServices },
	} = props;

	// puplishing - издательский, sub-puplishing - субиздательский

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		showBackNavi('/repertoire/drafts', null, 'rod.action.back_to_list');

		if (compositionId) {
			getCompositionData(compositionId).then((res) => {
				if (res) {
					setModerationContract(res.moderation_contract);
					setContractType(res.type_contract);
					setData(res.data);
				}
			});
		}
		return function cleanup() {
			hideBackNavi();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let genresCache = lscache.get('genresCache');

		if (genresCache) {
			if (lang === 'ru') {
				genresCache = genresCache.sort((a, b) =>
					a.title_ru > b.title_ru ? 1 : -1
				);
			} else {
				genresCache = genresCache.sort((a, b) =>
					a.title_en > b.title_en ? 1 : -1
				);
			}
			setGenresArr(genresCache);
		} else {
			accounts
				.getGenres()
				.then((res) => {
					res = res.data.data;
					setCache('genres', 'genresCache', res);
					if (lang === 'ru') {
						res = res.sort((a, b) => (a.title_ru > b.title_ru ? 1 : -1));
					} else {
						res = res.sort((a, b) => (a.title_en > b.title_en ? 1 : -1));
					}
					setGenresArr(res);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			props.handlePrevStep();
		}
	};

	const nextHandler = () => {
		if (typeof props.onChange === 'function') {
			props.onChange();
		}
	};

	return (
		<>
			<div className={styles.Review__Wrapper}>
				<Header title={data.title} />
				<div className={styles.Review__Main_wrapper}>
					{isShowServicesRepertoireBanner &&
						contractType &&
						((!moderationContract && contractType.length > 0) ||
							contractType.length > 0) && (
							<div className={styles.addRoleContractsBanner}>
								<AddRoleContractsBanner
									data={{
										title: (
											<FormattedMessage
												id={
													'rod.account.add_role_banner_composition_not_available_title'
												}
											/>
										),
										content: (
											<FormattedMessage
												id={
													!moderationContract
														? 'rod.account.add_role_banner_composition_not_available_moderation'
														: 'rod.account.add_role_banner_composition_has_not_contracts'
												}
											/>
										),
										btnText: (
											<FormattedMessage
												id={
													!moderationContract
														? 'rod.account.save_to_draft_btn'
														: contractType.length > 1
														? 'rod.account.choose_few_services_btn'
														: 'rod.account.choose_service_btn'
												}
											/>
										),

										data: ['lyrics', 'author'],
										postAdditionalServices,
										id: accountId,
										action: !moderationContract ? 'to draft' : 'sign services',
									}}
								/>
							</div>
						)}

					<Information data={data} lang={lang} genresArr={genresArr} />
					{data && data.recordings && data.recordings.length > 0 && (
						<Recordings data={data} lang={lang} genresArr={genresArr} />
					)}
				</div>
			</div>
			<div className={styles.bottomContainer}>
				{Object.keys(props.errors).includes('code') && (
					<span className={styles.Helper}>
						{<FormattedMessage id={`rod.composition.moderation.error`} />}
					</span>
				)}
				<BottomNavi
					showPrevBtn
					disabled={
						isShowServicesRepertoireBanner &&
						contractType &&
						((!moderationContract && contractType?.length > 0) ||
							contractType?.length > 0)
					}
					back={backHandler}
					next={nextHandler}
					nextText="rod.release.create.step.submit.submit"
				/>
			</div>
		</>
	);
};

export default compose(withRoot, withUI)(Review);
