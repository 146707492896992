// Core
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

export const getBannerData = () => {
	let data = {};

	process.env.REACT_APP_CLIENT === 'hitmaker' ||
	process.env.REACT_APP_CLIENT === 'mts'
		? (data = {
				title: <FormattedMessage id={'rod.repertoire.welcome.title'} />,
				text: (
					<FormattedHTMLMessage
						id={
							process.env.REACT_APP_CLIENT === 'dgtal'
								? 'rod.main_page.greetings.body.dgtal'
								: 'rod.main_page.greetings.body'
						}
					/>
				),
		  })
		: (data = {
				title: <FormattedMessage id={'rod.repertoire.welcome.title'} />,
				text: (
					<FormattedHTMLMessage
						id={
							process.env.REACT_APP_CLIENT === 'dgtal'
								? 'rod.main_page.greetings.body.dgtal'
								: 'rod.main_page.greetings.body'
						}
					/>
				),
		  });

	return data;
};
