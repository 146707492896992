// Core
import React, { useEffect, useState, useContext } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import InputMask from 'react-input-mask';

// Utils
import { setCache } from 'services/cacheHelper/setCache';
import { accounts, dictionaries } from 'services';
import { dateFormat } from 'logic';
import { showError } from 'validators/showError';
import { getCountries } from './utils/getCountries';
import { getReleaseTypes } from './utils/getReleaseTypes';
import { nextHandler } from './utils/nextHandler';
import {
	handleSetGRID,
	handleSetUPC,
	handleSetCatalogNumber,
} from './utils/handleSetIds';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import { InfoButton } from 'components/Buttons';
import { FormInput } from 'components/Form/FormInput';
import { BottomNavi, InfoHelper } from 'components';
import { Check, NoCheck } from 'material-design/svg';

// Styles
import s from './Release.module.css';

function ReleaseInfo(props) {
	const accountId = localStorage.getItem('accountId');
	const { lang } = useContext(LangContext);
	const { showModal, isTransferRelease, setIsBottomNaviShow } = useContext(
		UIContext
	);

	const [personal, setPersonal] = useState({
		created_date: new Date().toISOString(),
	});
	const [errors, setErrors] = useState({});
	const [countries, setCountries] = useState([]);
	const [releaseTypes, setReleaseTypes] = useState([]);
	const [isReqLive] = useState(false);
	const [isShowHelpBtn, setIsShowHelpBtn] = useState(true);
	const [transferRelease, setTransferRelease] = useState(isTransferRelease);
	const [isH11Edit, setIsH11Edit] = useState(
		window.location.href.includes('/edit/')
	);
	const [countryId, setCountryId] = useState(null);

	const releaseId = localStorage.getItem('releaseId');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));
	const h11EditId = releaseEdit?.id;

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const transferRelease = localStorage.getItem('isTransferRelease');
		if (transferRelease) setTransferRelease(true);
	}, []);

	useEffect(() => {
		accounts
			.getAccountContactsData(accountId)
			.then((res) => {
				res = res.data.data;
				if (res.country_id) {
					setCountryId(res.country_id);
				}
			})
			.catch((error) => {
				console.error('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const changeMask = (e) => {
		setErrors({});
		personal.grid = e.target.value;
	};

	const checkIsValidMask = (e) => {
		if (!personal.generate_grid && personal.grid.includes('_')) {
			setErrors({ grid: [{ rule: 'wrong_format' }] });
		}
	};

	const handleOnBlurDateInput = (err, value) => {
		setErrors({});
		props.checkDateErrors(err);

		if (err) {
			setErrors({ created_date: [{ rule: 'wrong_format' }] });
		}
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'created_date':
				if (e && e.getFullYear().toString().length === 4) {
					e = dateFormat(e);
				}
				personal[field] = e;
				break;
			case 'genres':
				const newArr = e.map((item) => (item.code ? item.code : item));
				personal[field] = newArr;
				break;
			case 'release_type_id':
				personal[field] = e.id;
				break;
			case 'created_country_id':
				personal[field] = e;
				break;

			default:
				personal[field] = e.target.value;
				break;
		}
		setPersonal({ ...personal });
	};

	const handleFocus = (field) => (e) => {
		switch (field) {
			case 'label':
				setIsShowHelpBtn(false);
				break;

			default:
				break;
		}
	};

	const handleBlur = (field) => (e) => {
		switch (field) {
			case 'label':
				setIsShowHelpBtn(true);
				break;

			default:
				break;
		}
	};

	const disabledNextBtn =
		!personal.title ||
		!personal.release_type_id ||
		!personal.genres ||
		!personal.genres.length ||
		!personal.performers ||
		!personal.label ||
		!personal.created_date ||
		(!isH11Edit && !personal.generate_ean && !personal.ean) ||
		Object.keys(errors).includes('created_date') ||
		(!transferRelease &&
			!isH11Edit &&
			!personal.generate_catalog_number &&
			!personal.catalog_number);

	const getClassnameForInfoBtn = () => {
		if (isShowHelpBtn && !personal.label) {
			if (process.env.REACT_APP_CLIENT === 'mts') {
				return s.infoMts;
			} else {
				return s.info;
			}
		} else {
			if (process.env.REACT_APP_CLIENT === 'mts') {
				return s.infoActiveMts;
			} else {
				return s.infoActive;
			}
		}
	};

	useEffect(() => {
		getCountries(isReqLive, setCountries, dictionaries, setCache);

		if (
			!releaseId &&
			accountId &&
			personal &&
			!personal.created_country_id &&
			!isH11Edit
		) {
			setPersonal({ ...personal, created_country_id: countryId });
		}

		if (releaseId && !isH11Edit) {
			accounts
				.getReleaseData(releaseId)
				.then((res) => {
					if (res.data.data.grid) {
						res.data.data.grid.replace(
							/(\d{2})\.(\d{5})\.(\d{10})\.(\d{1})/,
							'$1-$2-$3-$4'
						);
					}
					res.data.data['created_date'] = res.data.data['created_date'].match(
						/(\d{4})-(\d{2})-(\d{2})/gm
					)[0];

					const step = res.data.data?.step;
					setIsH11Edit(step.includes('h11'));
					if (step.includes('h11')) {
						const releaseEdit = { id: h11EditId, step: 'release' };
						localStorage.setItem('releaseEdit', JSON.stringify(releaseEdit));
					}
					setPersonal(res.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		if (isH11Edit) {
			accounts
				.getReleaseData(h11EditId)
				.then((res) => {
					if (res.data.data.grid) {
						res.data.data.grid.replace(
							/(\d{2})\.(\d{5})\.(\d{10})\.(\d{1})/,
							'$1-$2-$3-$4'
						);
					}
					res.data.data['created_date'] = res.data.data['created_date'].match(
						/(\d{4})-(\d{2})-(\d{2})/gm
					)[0];

					res.data.data.created_country_id = countryId;

					const step = res.data.data?.step;
					setIsH11Edit(step.includes('h11'));
					if (step.includes('h11')) {
						const releaseEdit = { id: h11EditId, step: 'release' };
						localStorage.setItem('releaseEdit', JSON.stringify(releaseEdit));
					}
					setPersonal(res.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		// eslint-disable-next-line
	}, [countryId]);

	useEffect(() => {
		getReleaseTypes(
			personal,
			isReqLive,
			lang,
			setReleaseTypes,
			accounts,
			setCache
		);

		// eslint-disable-next-line
	}, [lang, personal]);

	return (
		<>
			<div className={s.page}>
				<div className={s.main}>
					<span className={s.title}>
						<FormattedMessage id={'rod.add_composition_page.step1'} />
					</span>
					<div className={s.table}>
						<div className={s.input}>
							<FormInput
								type="muiInput"
								name={'title'}
								onChange={changeField}
								errors={props.errors}
								data={personal}
								required
								label={
									<FormattedMessage
										id={'rod.release.create.step.release.label.name'}
									/>
								}
							/>
							{Object.keys(props.errors).includes('title') && (
								<span className={s.Helper}>
									{showError(props.errors.title[0].rule)(props.errors.title[0])}
								</span>
							)}
						</div>
						<div className={s.input}>
							<FormInput
								type="muiInput"
								name={'subtitle'}
								onChange={changeField}
								errors={props.errors}
								data={personal}
								label={
									<FormattedMessage
										id={'rod.release.create.step.release.label.subtitle'}
									/>
								}
							/>
							{Object.keys(props.errors).includes('subtitle') && (
								<span className={s.Helper}>
									{showError(props.errors.subtitle[0].rule)(
										props.errors.subtitle[0]
									)}
								</span>
							)}
						</div>
						<div className={s.input}>
							<FormInput
								type={'MuiSelectReleaseType'}
								name={'release_type_id'}
								onChange={changeField}
								errors={props.errors}
								data={personal}
								className={s.select}
								required
								label={
									<FormattedMessage
										id={'rod.release.create.step.release.label.relise_type'}
									/>
								}
								items={releaseTypes}
								disabled={
									isH11Edit &&
									(personal.release_type_id === 42 ||
										personal.release_type_id === 43 ||
										personal.release_type_id === 70)
								}
							/>
							{Object.keys(props.errors).includes('release_type_id') && (
								<span className={s.Helper}>
									{showError(props.errors.release_type_id[0].rule)(
										props.errors.release_type_id[0]
									)}
								</span>
							)}
						</div>
						<div className={s.inputGenres}>
							<FormInput
								type={'muiAutocompleteMultipleGenres'}
								name={'genres'}
								onChange={changeField}
								errors={props.errors}
								className={s.select}
								data={personal}
								required
								label={
									<FormattedMessage
										id={'rod.release.create.step.release.label.genre'}
									/>
								}
							/>
							{Object.keys(props.errors).includes('genres') && (
								<>
									{props.errors.genres[0].rule !== 'max_size' ? (
										<span className={s.Helper}>
											{showError(props.errors.genres[0].rule)(
												props.errors.genres[0]
											)}
										</span>
									) : (
										<span className={s.Helper}>
											{props.errors.genres[0] && (
												<FormattedMessage
													id={'rod.error.max_size.genres'}
													values={{ value: props.errors.genres[0]?.value }}
												/>
											)}
										</span>
									)}
								</>
							)}
						</div>
						<div className={s.input}>
							<FormInput
								type="muiInput"
								name={'performers'}
								onChange={changeField}
								errors={props.errors}
								data={personal}
								required
								label={
									<FormattedMessage
										id={'rod.release.create.step.release.label.main_executor'}
									/>
								}
							/>
							{Object.keys(props.errors).includes('performers') && (
								<span className={s.Helper}>
									{showError(props.errors.performers[0].rule)(
										props.errors.performers[0]
									)}
								</span>
							)}
						</div>
						<div className={s.input}>
							<FormInput
								type="muiInput"
								name={'label'}
								onChange={changeField}
								onFocus={handleFocus}
								onBlur={handleBlur}
								errors={props.errors}
								data={personal}
								required
								label={
									<FormattedMessage
										id={
											'rod.release.create.step.recording_info.label.p-line/c-line'
										}
									/>
								}
							/>
							<InfoButton
								className={getClassnameForInfoBtn()}
								onClick={showModal({
									text: (
										<FormattedHTMLMessage
											id={'rod.release.create.help.c-line/p-line'}
										/>
									),
								})}
							/>
							{Object.keys(props.errors).includes('label') && (
								<span className={s.Helper}>
									{showError(props.errors.label[0].rule)(props.errors.label[0])}
								</span>
							)}
						</div>
						<div className={s.input}>
							<FormInput
								type={'muiInputDate_v2'}
								readOnly={false}
								name={'created_date'}
								onChange={changeField}
								handleOnBlurDateInput={handleOnBlurDateInput}
								maxDate={true}
								errors={
									Object.keys(props.errors).includes('created_date')
										? props.errors
										: {}
								}
								data={personal}
								required
								label={
									<FormattedMessage
										id={'rod.release.create.step.release.label.create_date'}
									/>
								}
							/>
							{Object.keys(props.errors).includes('created_date') && (
								<span className={s.Helper}>
									{showError(props.errors.created_date[0].rule)(
										props.errors.created_date[0]
									)}
								</span>
							)}
							{Object.keys(errors).includes('created_date') && (
								<span className={s.Helper}>
									{
										<FormattedMessage
											id={`rod.error.${errors.created_date[0].rule}`}
										/>
									}
								</span>
							)}
						</div>
						<div className={s.input}>
							<FormattedMessage
								id={
									'rod.release.create.step.recording_info.label.create_country'
								}
							>
								{(placeholder) => (
									<FormInput
										type="country"
										name="created_country_id"
										onChange={changeField}
										errors={props.errors}
										data={personal}
										className={s.select}
										placeholder={placeholder}
										items={countries}
										countries={countries}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>
					<span className={`${s.title} ${s.title__id}`}>
						<FormattedMessage
							id={'rod.release.create.step.release.label.identifiers'}
						></FormattedMessage>
					</span>
					<div className={s.flex}>
						<div className={s.item}>
							<div
								className={
									personal.generate_ean || personal?.step?.includes('h11')
										? `${s.input} ${s.disabled}`
										: s.input
								}
							>
								<FormInput
									type="muiInput"
									name={'ean'}
									onChange={changeField}
									errors={props.errors}
									data={personal}
									label={'UPC'}
									disabled={
										personal.generate_ean || personal.step?.includes('h11')
									}
									required={!personal?.step?.includes('h11')}
								/>
								{Object.keys(props.errors).includes('ean') && (
									<span className={s.Helper}>
										{showError(props.errors.ean[0].rule)(props.errors.ean[0])}
									</span>
								)}
							</div>

							{!transferRelease && !personal?.step?.includes('h11') && (
								<div className={s.auto__wrapper}>
									<div
										className={s.auto}
										onClick={() =>
											handleSetUPC(
												personal?.generate_ean,
												personal,
												setPersonal
											)
										}
									>
										{personal?.generate_ean ? (
											<Check className={s.auto__check} />
										) : (
											<NoCheck className={s.auto__noCheck} />
										)}
										<span>
											<FormattedMessage
												id={'rod.release.create.step.release.label.auto'}
											/>
										</span>
									</div>
								</div>
							)}
						</div>

						<div className={s.item}>
							<div
								className={
									personal.generate_grid || personal?.step?.includes('h11')
										? `${s.input} ${s.disabled}`
										: s.input
								}
							>
								<InputMask
									mask={'**-*****-**********-*'}
									name={'grid'}
									onChange={changeMask}
									errors={errors}
									value={personal.grid ? personal.grid : ''}
									onBlur={checkIsValidMask}
									disabled={
										personal.generate_grid || personal?.step?.includes('h11')
									}
								>
									{() => (
										<TextFieldInput
											variant="standard"
											type="muiInput"
											name={'grid'}
											error={Object.keys(errors).length > 0 && errors.grid}
											value={personal.grid ? personal.grid : ''}
											label={'GRID'}
											disabled={
												personal.generate_grid ||
												personal?.step?.includes('h11')
											}
										/>
									)}
								</InputMask>
								{Object.keys(errors).includes('grid') && (
									<span className={s.Helper}>
										{showError(errors.grid[0].rule)(errors.grid[0])}
									</span>
								)}
							</div>

							{!transferRelease && !personal?.step?.includes('h11') && (
								<div className={s.auto__wrapper}>
									<div
										className={s.auto}
										onClick={() => {
											setErrors({});
											handleSetGRID(
												personal?.generate_grid,
												personal,
												setPersonal
											);
										}}
									>
										{personal?.generate_grid ? (
											<Check className={s.auto__check} />
										) : (
											<NoCheck className={s.auto__noCheck} />
										)}
										<span>
											<FormattedMessage
												id={'rod.release.create.step.release.label.auto'}
											/>
										</span>
									</div>
								</div>
							)}
						</div>

						<div className={s.item}>
							<div
								className={
									personal.generate_catalog_number ||
									personal?.step?.includes('h11')
										? `${s.input} ${s.disabled}`
										: s.input
								}
							>
								<FormInput
									type="muiInput"
									name={'catalog_number'}
									onChange={changeField}
									errors={props.errors}
									data={personal}
									required={
										!transferRelease && !personal?.step?.includes('h11')
									}
									disabled={
										personal.generate_catalog_number ||
										personal?.step?.includes('h11')
									}
									label={
										<FormattedMessage
											id={
												'rod.release.create.step.release.label.catalog_number'
											}
										/>
									}
								/>
								{Object.keys(props.errors).includes('catalog_number') && (
									<span className={s.Helper}>
										{showError(props.errors.catalog_number[0].rule)(
											props.errors.catalog_number[0]
										)}
									</span>
								)}
							</div>

							{!transferRelease && !personal?.step?.includes('h11') && (
								<div className={s.auto__wrapper}>
									<div
										className={s.auto}
										onClick={() =>
											handleSetCatalogNumber(
												personal.generate_catalog_number,
												personal,
												setPersonal
											)
										}
									>
										{personal.generate_catalog_number ? (
											<Check className={s.auto__check} />
										) : (
											<NoCheck className={s.auto__noCheck} />
										)}
										<span>
											<FormattedMessage
												id={'rod.release.create.step.release.label.auto'}
											/>
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<InfoHelper text="rod.release.create.step.release.description" />
			</div>
			<BottomNavi
				showPrevBtn={false}
				disabled={disabledNextBtn}
				next={() => nextHandler(props, errors, cloneDeep, personal)}
			/>
		</>
	);
}

export default ReleaseInfo;
