/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 15/02/2021, 10:53
 */

import { getGlobalId } from 'App';

import classNames from 'classnames';
import { withForwardRef } from 'hocs';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import styles from './RadioButton.module.css';

class RadioButton extends React.Component {
	static propTypes = {
		/**
		 * One or more button variant combinations
		 *
		 * buttons may be one of a variety of visual variants such as:
		 *
		 * `'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link'`
		 *
		 * as well as "outline" versions (prefixed by 'outline-*')
		 *
		 * `'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning', 'outline-info', 'outline-dark', 'outline-light'`
		 */
		variant: PropTypes.string,

		/** Manually set the visual state of the button to `:active` */
		active: PropTypes.bool,

		/**
		 * Disables the Button, preventing mouse events,
		 * even if the underlying component is an `<a>` element
		 */
		disabled: PropTypes.bool,

		/**
		 * Defines HTML button type attribute.
		 *
		 * @default 'button'
		 */
		type: PropTypes.oneOf(['button', 'reset', 'submit', null]),

		/**
		 * Defines HTML button content.
		 */
		text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	};

	static defaultProps = {
		active: false,
		disabled: false,
	};

	static displayName = 'RadioButton';

	render() {
		const {
			className,
			disabled,
			forwardRef,
			children,
			text,
			name,
			value,
			withFocusRef,
			active,
			itemChildren,
			...props
		} = this.props;
		const classes = classNames(
			className,
			styles['RadioButton'],
			disabled && 'disabled'
		);

		const id = getGlobalId('radioButton');

		return (
			<div className={classes}>
				<input
					{...props}
					ref={forwardRef}
					id={id}
					type="radio"
					disabled={disabled}
					name={name}
					value={value}
				/>
				{itemChildren && <label htmlFor={id}>{itemChildren}</label>}
				{!itemChildren && <label htmlFor={id}>{children || text || ''}</label>}
			</div>
		);
	}
}

export default compose(withForwardRef)(RadioButton);
