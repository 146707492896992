// Core
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// Icons
import plus_in_square from 'images/plus_in_square.svg';

// Styles
import s from '../../Authors.module.css';

const Controls = ({
	addAuthor,
	recordingSave,
	errors,
	ckeckErrorsBeforeSave,
}) => {
	return (
		<>
			<div className={s.form__buttons}>
				<div className={s.form__buttonWrapper} onClick={addAuthor}>
					<img src={plus_in_square} alt="" />
					<FormattedHTMLMessage
						id={'rod.release.create.step.recording_info.label.author_add'}
					/>
				</div>
			</div>
			<div className={s.form__submitWrapper}>
				<button className={s.form__submit} onClick={() => recordingSave()}>
					<FormattedMessage
						id={'rod.release.create.step.recording_info.form_save'}
					/>
				</button>
				{Object.keys(errors).includes('roles' || 'sum') && (
					<span className={s.submit_helper}>{ckeckErrorsBeforeSave()}</span>
				)}
			</div>
		</>
	);
};

export default Controls;
