/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 04/03/2021, 18:59
 */

import modalTypes from 'constants/modalTypes';
import toastTypes from 'constants/toastTypes';
import { ContractSMSSignModal } from 'containers/ContractSMSSignModal';
import {
	FullScreenModal,
	FullScreenModalConfirm,
	RemoveModal,
	NotifyScreenModal,
	NotifyAdminReadModal,
	AdminRubricModal,
	ReportsModal,
	ReportsManualModal,
	ReportsManualEditModal,
	NextReportDateModal,
	ReportsCreateDistributionModal,
	AddServiceModal,
	InvoiceNumberModal,
} from 'containers/Modal';
import { InfoToast } from 'containers/Toasts';
import AdaptiveModal from 'containers/Modal/AdaptiveModal';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { withRouter } from 'react-router-dom';
import http from 'services/http';
import { getHelpMessage } from 'messages/helperErrors';
import { INPUT_TIME_COUNTER } from 'constants/timeConstants';

const UIContext = React.createContext();

class Provider extends React.Component {
	state = {
		isShowBackNavi: false,
		isShowUserMenu: false,
		isShowUpload: false,
		backTitle: 'rod.action.back',
		backTargetUrl: '/',
		modal: modalTypes.NONE,
		modalProps: {},
		toast: toastTypes.NONE,
		toastProps: {},
		isTitle: '',
		isAccountFeatureRecordingView: false,
		isAccountFeatureCompositionView: false,
		isAllowAccountCreate: false,
		isAdditionalAccountCreationEnabled: false,
		isAllowSignup: false,
		isShowContracts: false,
		isCompositionCreateDisabled: false,
		isReportsEnable: false,
		isFaqEnabled: false,
		isPseudonymsEnable: false,
		isDisplayingStatistics: true,
		isReleaseCreateDisabled: false,
		isManualReports: false,
		isManagementDisable: false,
		isPaymentsEnabled: false,
		isHideBankSettings: false,
		isHideCountersNotreadyDisputes: false,
		isStatisticsColumnarCountries: false,
		isHideAdminStat: false,
		allowAdditionalContractSigning: false,
		isDisplayingContracts: false,
		isShowAddServices: false,
		isShowServicesRepertoireBanner: false,
		isPrivatePolicyShow: false,
		isTransferRelease: false,
		notTransferReleaseFlag: false,
		materialsAvailableEditing: true,
		isShipmentsTakedownsEnableFeature: false,
		releaseCompletionStep: '',
		disableRecordingBtns: false,
		reloadWhenCreateRelease: false,
		reloadWhenCreateComposition: false,
		isExpiredContract: false,
		contributor: null,

		upload: {
			drafts: true,
			releases: true,
			compositions: true,
			recordings: false,
		},

		selectRepertoire: undefined,
		selectStatistic: undefined,
		isShowNavAndButtons: true,
		resendSMSTime: 300000,
		isReleaseNotReady: true,
		song: {},
		play: false,
		audioId: '',
		audioInPlay: new Audio(),
		isPlayerShow: false,
		isBottomNaviShow: false,
		prevRepertoireParams: { page: 1 },
		selectSettingsStep: null,
		isAdminLayoutShow: false,
		adminV2Settings: { page: 1, filterStatus: [], searchValue: '' },
		parentFaq: '',
		faqSearchResult: { text: '', data: [] },
		notificationsSent: [],
		isPlayerAlreadyShowed: false,
		currentCompositionStatus: '',
		currentReleaseStatus: '',
		currentDraftCount: null,
	};

	selectorRef = React.createRef(false);

	componentDidMount() {
		const api = http.getInstace();
		api.showError = this.showError;
	}

	setIsExpiredContract = (value) => {
		this.setState({ isExpiredContract: value });
	};

	setContributor = (contributor) => {
		this.setState({ contributor: contributor });
	};

	showError = (res, resolve, reject) => {
		let error;
		switch (res.status) {
			case 400:
				error = getHelpMessage('ru', 'error400');
				NotificationManager.error(error, null, 10000);
				return reject();
			case 403:
				if (res.url.includes('password-reset')) {
					return resolve();
				}
				this.props.history.push('/denied');
				return reject();
			case 404:
				if (res.url.includes('password-reset')) {
					return resolve();
				}
				this.props.history.push('/notfound');
				return reject();
			case 409:
				error = getHelpMessage('ru', 'error409');
				NotificationManager.error(error, null, 10000);
				return reject();
			case 422:
				return resolve();
			case 500:
				this.props.history.push('/unavailable');
				return reject();
			case 503:
				error = getHelpMessage('ru', 'error503');
				NotificationManager.error(error, null, 10000);
				return reject();
			default:
				return reject();
		}
	};

	showErrorInterceptor = (error, resolve, reject) => {
		switch (error.response.status) {
			case 400:
				error = getHelpMessage('ru', 'error400');
				NotificationManager.error(error, null, 10000);
				throw error;
			case 403:
				this.props.history.push('/denied');
				throw error;
			case 404:
				if (error.response.config.url.includes('remove')) throw error;
				this.props.history.push('/notfound');
				throw error;
			case 409:
				error = getHelpMessage('ru', 'error409');
				NotificationManager.error(error, null, 10000);
				throw error;
			case 429:
				throw error;
			case 500:
				this.props.history.push('/unavailable');
				throw error;
			case 503:
				error = getHelpMessage('ru', 'error503');
				NotificationManager.error(error, null, 10000);
				throw error;
			default:
				throw error;
		}
	};

	showBackNaviHandler = (
		backTargetUrl = this.state.backTargetUrl,
		backButtonCallback,
		id = ['/repertoire/drafts', '/treaties'].includes(
			backTargetUrl || this.state.backTargetUrl
		)
			? 'rod.action.back_to_list'
			: 'rod.action.back'
	) => {
		this.setState({
			isShowBackNavi: true,
			backTargetUrl,
			backButtonCallback,
			backTitle: id,
		});
	};

	setReloadWhenCreateRelease = (value) => {
		this.setState({ reloadWhenCreateRelease: value });
	};

	setReloadWhenCreateComposition = (value) => {
		this.setState({ reloadWhenCreateComposition: value });
	};

	hideBackNaviHandler = () => {
		this.setState({
			isShowBackNavi: false,
			backTargetUrl: '/',
			backButtonCallback: null,
		});
	};

	setCurrentDraftCount = (value) => {
		this.setState({ currentDraftCount: value });
	};

	removeOneDraft = () => {
		const newCount = this.state.currentDraftCount - 1;
		this.setState({ currentDraftCount: newCount });
	};

	changeBackTargetUrl = (url) => {
		this.setState({ backTargetUrl: url });
	};

	showUserMenuHandler = () => {
		this.setState({ isShowUserMenu: true });
	};

	hideUserMenuHandler = () => {
		this.setState({ isShowUserMenu: false });
	};

	showUploadHandler = () => {
		this.setState({ isShowUpload: true });
	};

	hideUploadHandler = () => {
		this.setState({ isShowUpload: false });
	};

	showNavAndButtons = () => {
		this.setState({ isShowNavAndButtons: true });
	};

	hideNavAndButtons = () => {
		this.setState({ isShowNavAndButtons: false });
	};

	setIsTransferRelease = (value) => {
		this.setState({ isTransferRelease: value });
	};

	setCompletionStep = (value) => {
		this.setState({ releaseCompletionStep: value });
	};

	setDisableRecordingBtns = (value) => {
		this.setState({ disableRecordingBtns: value });
	};

	setCurrentReleaseStatus = (status) => {
		this.setState({ currentReleaseStatus: status });
	};

	setCurrentCompositionStatus = (status) => {
		this.setState({ currentCompositionStatus: status });
	};

	setIsAdminLayoutShow = () => {
		this.setState({
			isAdminLayoutShow: this.state.isAdminLayoutShow ? false : true,
		});
	};

	showTitle = (title) => {
		this.setState({ isTitle: title });
	};

	hideTitle = () => {
		this.setState({ isTitle: '' });
	};

	changeBackTitle = (id) => {
		this.setState({ backTitle: id });
	};

	showModal = (props, modal = modalTypes.INFO) => () => {
		this.setState({
			modal,
			modalProps: props,
		});
	};

	hideModal = () => {
		this.setState({
			modal: modalTypes.NONE,
			modalProps: {},
		});
	};

	showToast = ({ title = '', text = '', type = toastTypes.INFO }) => {
		this.setState({
			toast: type,
			toastProps: { title, text },
		});
	};

	hideToast = () => {
		this.setState({
			toast: toastTypes.NONE,
			toastProps: {},
		});
	};

	getFeaturesFlags = (
		accountFeatureRecordingView = true,
		accountFeatureCompositionView = true,
		allowAccountCreate = true,
		additionalAccountCreationEnabled = true,
		allowSignup = true,
		showContracts = true,
		isCompositionCreateDisabled = false,
		reportsEnable = false,
		FaqEnabled = true,
		isPseudonymsEnable = false,
		isDisplayingStatistics = true,
		isReleaseCreateDisabled = false,
		isManualReports = false,
		isManagementDisable = false,
		allowAdditionalContractSigning = true,
		isDisplayingContracts = false,
		isShowAddServices = false,
		isShowServicesRepertoireBanner = false,
		isPrivatePolicyShow = false,
		notTransferReleaseFlag = false,
		materialsAvailableEditing = true,
		isShipmentsTakedownsEnableFeature = false,
		isPaymentsEnabled = false,
		isHideBankSettings = false,
		isHideCountersNotreadyDisputes = false,
		isStatisticsColumnarCountries = false,
		isHideAdminStat = false,
		isFeatureStatisticsReleaseTab = true,
		isForbiddenOnboarding = false,
		isEditingReleasesAfterShipment = false,
		isCopyrightingStatisticsEnabled = false
	) => {
		this.setState({
			isAccountFeatureRecordingView: accountFeatureRecordingView,
			isAccountFeatureCompositionView: accountFeatureCompositionView,
			isAllowAccountCreate: allowAccountCreate,
			isAdditionalAccountCreationEnabled: additionalAccountCreationEnabled,
			isAllowSignup: allowSignup,
			isShowContracts: showContracts,
			isCompositionCreateDisabled: isCompositionCreateDisabled,
			isReportsEnable: reportsEnable,
			isFaqEnabled: FaqEnabled,
			isPseudonymsEnable: isPseudonymsEnable,
			isDisplayingStatistics: isDisplayingStatistics,
			isReleaseCreateDisabled: isReleaseCreateDisabled,
			isManualReports: isManualReports,
			isManagementDisable: isManagementDisable,
			allowAdditionalContractSigning:
				process.env.REACT_APP_CLIENT === 'dgtal'
					? allowAdditionalContractSigning
					: true,
			isDisplayingContracts: isDisplayingContracts,
			isShowAddServices: isShowAddServices,
			isShowServicesRepertoireBanner: isShowServicesRepertoireBanner,
			isPrivatePolicyShow: isPrivatePolicyShow,
			notTransferReleaseFlag: notTransferReleaseFlag,
			materialsAvailableEditing: materialsAvailableEditing,
			isShipmentsTakedownsEnableFeature: isShipmentsTakedownsEnableFeature,
			isPaymentsEnabled: isPaymentsEnabled,
			isHideBankSettings: isHideBankSettings,
			isHideCountersNotreadyDisputes: isHideCountersNotreadyDisputes,
			isStatisticsColumnarCountries: isStatisticsColumnarCountries,
			isHideAdminStat: isHideAdminStat,
			isFeatureStatisticsReleaseTab: isFeatureStatisticsReleaseTab,
			isForbiddenOnboarding: isForbiddenOnboarding,
			isEditingReleasesAfterShipment: isEditingReleasesAfterShipment,
			isCopyrightingStatisticsEnabled: isCopyrightingStatisticsEnabled,
		});
	};

	toggleBurgerModal = () => {
		this.state.isShowBurgerModal
			? this.setState({ isShowBurgerModal: false })
			: this.setState({ isShowBurgerModal: true });
	};

	showBurgerModal = (props) => {
		this.setState({
			burgerModalProps: props,
		});
	};

	selectRepertoirePage = (props) => {
		this.setState({
			selectRepertoire: props,
		});
	};

	selectStatisticPage = (props) => {
		this.setState({
			selectStatistic: props,
		});
	};

	putResendSMSTime = (startTime = INPUT_TIME_COUNTER) => {
		this.setState({ resendSMSTime: startTime });
	};

	handleSetPlay = (bool) => {
		this.setState({ play: bool });
	};

	setIsPlayerShow = (bool) => {
		this.setState({ isPlayerShow: bool });
	};

	setIsBottomNaviShow = (bool) => {
		this.setState({ isBottomNaviShow: bool });
	};

	setAudioId = (id) => {
		this.setState({ audioId: id });
	};

	setSong = (songParams) => {
		this.setState({ song: songParams });
	};

	setAudioInPlay = (audio) => {
		this.setState({ audioInPlay: audio });
	};

	handlePlay = (index, item, title, performers = '') => {
		if (!this.selectorRef.current) {
			this.selectorRef.current = true;
			if (!item.id) {
				item[`id`] = index;
			}
			if (!item.performers) {
				item[`performers`] = ' ';
			}

			if (!this.state.play) {
				this.setAudioId(item.id);
				this.setSong({
					url: item.preview || item.url,
					client_name: title,
					performers: performers || '',
				});

				this.handleSetPlay(true);
				this.setIsPlayerShow(true);
			} else if (this.state.play && item.id !== this.state.audioId) {
				this.setAudioId(item.id);

				this.setSong({
					url: item.preview || item.url,
					client_name: title,
					performers: performers || '',
				});

				this.handleSetPlay(true);
				this.setIsPlayerShow(true);
			} else {
				if (!this.state.audioInPlay.readyState) return;
				this.setAudioId('');
				this.handleSetPlay(false);
				this.setIsPlayerShow(false);
				this.state.audioInPlay.pause();
			}

			setTimeout(() => {
				this.selectorRef.current = false;
			}, 500);
		}
	};

	handlePlayStop = () => {
		this.setAudioId('');
		this.handleSetPlay(false);
		this.setIsPlayerShow(false);
		this.state.audioInPlay.pause();
	};

	handleIsPlayerAlreadyShowed = () => {
		this.setState({ isPlayerAlreadyShowed: !this.state.isPlayerAlreadyShowed });
	};

	setSelectedRepertoire = (repertoire) => {
		this.setState({ selectRepertoire: repertoire });
	};

	setSelectedStatistic = (statistic) => {
		this.setState({ selectStatistic: statistic });
	};
	setSelectedSettingsStep = (step) => {
		this.setState({ selectSettingsStep: step });
	};

	setPrevRepertoireParams = (params) => {
		this.setState({ prevRepertoireParams: { page: params.page } });
	};

	setAdminV2Settings = (params) => {
		this.setState({
			adminV2Settings: {
				page: params.page,
				filterStatus: params.filterStatus,
				searchValue: params.searchValue,
			},
		});
	};

	setParentFaq = (params) => {
		this.setState({
			parentFaq: params,
		});
	};

	setFaqSearchResult = (searchData) => {
		this.setState({
			faqSearchResult: searchData,
		});
	};

	setNotificationsSent = (ids) => {
		this.setState({
			notificationsSent: ids,
		});
	};
	render() {
		return (
			<UIContext.Provider
				value={{
					...this.state,
					showBackNavi: this.showBackNaviHandler,
					hideBackNavi: this.hideBackNaviHandler,
					showUserMenu: this.showUserMenuHandler,
					hideUserMenu: this.hideUserMenuHandler,
					showUploadHandler: this.showUploadHandler,
					hideUploadHandler: this.hideUploadHandler,
					getFeaturesFlags: this.getFeaturesFlags,
					showModal: this.showModal,
					hideModal: this.hideModal,
					showToast: this.showToast,
					hideToast: this.hideToast,
					showTitle: this.showTitle,
					hideTitle: this.hideTitle,
					toggleBurgerModal: this.toggleBurgerModal,
					showBurgerModal: this.showBurgerModal,
					selectRepertoirePage: this.selectRepertoirePage,
					showNavAndButtons: this.showNavAndButtons,
					hideNavAndButtons: this.hideNavAndButtons,
					setIsTransferRelease: this.setIsTransferRelease,
					setCompletionStep: this.setCompletionStep,
					setDisableRecordingBtns: this.setDisableRecordingBtns,
					setCurrentReleaseStatus: this.setCurrentReleaseStatus,
					setCurrentCompositionStatus: this.setCurrentCompositionStatus,
					putResendSMSTime: this.putResendSMSTime,
					showErrorInterceptor: this.showErrorInterceptor,
					handleSetPlay: this.handleSetPlay,
					setSelectedRepertoire: this.setSelectedRepertoire,
					setPrevRepertoireParams: this.setPrevRepertoireParams,
					setIsPlayerShow: this.setIsPlayerShow,
					setAudioInPlay: this.setAudioInPlay,
					handlePlay: this.handlePlay,
					setIsBottomNaviShow: this.setIsBottomNaviShow,
					setSelectedStatistic: this.setSelectedStatistic,
					selectStatisticPage: this.selectStatisticPage,
					changeBackTargetUrl: this.changeBackTargetUrl,
					setSelectedSettingsStep: this.setSelectedSettingsStep,
					setIsAdminLayoutShow: this.setIsAdminLayoutShow,
					setAdminV2Settings: this.setAdminV2Settings,
					setParentFaq: this.setParentFaq,
					setFaqSearchResult: this.setFaqSearchResult,
					setNotificationsSent: this.setNotificationsSent,
					handlePlayStop: this.handlePlayStop,
					setSong: this.setSong,
					handleIsPlayerAlreadyShowed: this.handleIsPlayerAlreadyShowed,
					setCurrentDraftCount: this.setCurrentDraftCount,
					removeOneDraft: this.removeOneDraft,
					setReloadWhenCreateRelease: this.setReloadWhenCreateRelease,
					setReloadWhenCreateComposition: this.setReloadWhenCreateComposition,
					setIsExpiredContract: this.setIsExpiredContract,
					setContributor: this.setContributor,
				}}
			>
				{this.props.children}

				{this.state.modal === modalTypes.FULL_MODAL && (
					<FullScreenModalConfirm
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.INFO && (
					<FullScreenModal
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.NOTIFICATION && (
					<NotifyScreenModal
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.REPORTS && (
					<ReportsModal {...this.state.modalProps} onClose={this.hideModal} />
				)}

				{this.state.modal === modalTypes.REPORTS_MANUAL && (
					<ReportsManualModal
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.ADD_SERVICE_MODAL && (
					<AddServiceModal
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.CREATE_DISTRIBUTION && (
					<ReportsCreateDistributionModal
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.REPORTS_MANUAL_EDIT && (
					<ReportsManualEditModal
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.NEXT_REPORT_DATE && (
					<NextReportDateModal
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.NOTIFICATION_ADMIN_READ && (
					<NotifyAdminReadModal
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.RUBRIC_MODAL && (
					<AdminRubricModal
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.INVOICE_NUMBER_MODAL && (
					<InvoiceNumberModal
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.REMOVE_MODAL && (
					<RemoveModal {...this.state.modalProps} onClose={this.hideModal} />
				)}

				{this.state.modal === modalTypes.CONTRACT_SMS_SIGN && (
					<ContractSMSSignModal
						{...this.state.modalProps}
						onClose={this.hideModal}
					/>
				)}

				{this.state.modal === modalTypes.BURGER_MODAL && (
					<AdaptiveModal {...this.state.modalProps} onClose={this.hideModal} />
				)}

				{this.state.toast === toastTypes.INFO && (
					<InfoToast
						title={this.state.toastProps.title}
						text={this.state.toastProps.text}
						onClose={this.hideToast}
					/>
				)}
			</UIContext.Provider>
		);
	}
}

const UIConsumer = UIContext.Consumer;
const UIProvider = withRouter(Provider);

export { UIProvider, UIConsumer, UIContext };
